import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import { Link } from "react-router-dom";

import rightbackg from "./Assets/Signup/studentbackg.png";
import leftbackg from "./Assets/Signup/teacherbackg.png";

import Navbar from "./Navigation/Navigation";
import NavNormalTheme from "./Navigation/ThemeCSS/NavNormalTheme";
import "./Signup.css";

// Sliding animation settings
const variants = {
    enter: (direction) => ({ x: direction === 1 ? "15%" : "-15%", opacity: "0%" }),
    center: { x: "0%", opacity: "100%" },
    exit: (direction) => ({ x: direction === 1 ? "-15%" : "15%", opacity: "0%" }),
};

export default function Signup(props) {
    // User data states
    const [ClassOfStudy, setClassOfStudy] = useState("");
    const [Location, setLocation] = useState("");
    const [MobileNumber, setMobileNumber] = useState("");
    const [PrimaryLanguage, setPrimaryLanguage] = useState("");
    const [SchoolName, setSchoolName] = useState("");
    const [ReferralCode, setReferralCode] = useState("");
    // profile pic to be added in the future
    const [Age, setAge] = useState("");
    const [Name, setName] = useState("");
    const [Username, setUsername] = useState("");
    const [PrimarilyTeachesAt, setPrimarilyTeachesAt] = useState("");
    const [profilePic, setProfilePic]=useState(null);

    // Global context
    const { Profession,setProfession,Email } = useGlobal();

    // Authentication states
    const { logout, getCSRFtoken, FID, UID } = useGlobal();

    // Animation states
    const [active, setActive] = useState(1);
    const [direction, setDirection] = useState(1);

    // Redirect function to redirect the user
    const redirect = useNavigate();

    // Signup pages questions
    const signupQuestions = ["Signup as", "Is someone referring you ?", "Select your Language", "Tell me your name", "What should I call you ?", "Which country are you from ?", "How old are you ?", "Your phone Number", "School ?", "In which class do you study ?"];
    // Signup data required / "Not required"
    const signupStatesStudent = [Location, ReferralCode, ClassOfStudy, Age, Name, Username,  SchoolName];
    const signupStatesTeacher = [Location, ReferralCode, ClassOfStudy, Age, Name, SchoolName, Username];



    const nextSlide = (e) => {
        e.preventDefault();
        setDirection(1);
        // Input Validation
        if (Profession=== "student"){
        if (signupStatesStudent.slice(0, 4).some(state => state === "")) {
            alert("Please fill all your details.");
        } else {
            // setNextDisabled(true);
            if (active <= 4) {
                setActive(0);
                setTimeout(() => {
                    setActive(active + 1);
                }, 100);
            }
        }}
        else if(Profession === "teacher"){
            if (signupStatesTeacher.slice(0, 4).some(state => state === "")) {
                alert("Please fill all your details.");
            } else {
                // setNextDisabled(true);
                if (active <= 4) {
                    setActive(0);
                    setTimeout(() => {
                        setActive(active + 1);
                    }, 100);
                }
            }
        }
    };

    const prevSlide = (e) => {
        e.preventDefault();
        setDirection(-1);
        if (active >= 2 && active <= 10) {
            setActive(0);
            setTimeout(() => {
                setActive(active - 1);
            }, 100);
        }
    };

   
    const handlePfp= (e) =>{
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
        
                reader.onload = (event) => {
                    const base64String = event.target.result.split(",")[1];
                    setProfilePic(base64String); // Assuming you want to store the base64 string in the state.
                };
        
                reader.readAsDataURL(file);
            }
    };

    const handleSignup = (e) => {
        e.preventDefault();
        if(Profession === "student"){
        // Input Validation
        if (signupStatesStudent[active - 1] === "") {
            alert("Please fill all your details.");
        } else {
            const data = {
                class_of_study: ClassOfStudy,
                location: Location.trim(),
                mobile_no: MobileNumber.trim(),
                // primary_lang: PrimaryLanguage.trim(),
                school_name: SchoolName.trim(),
                is_teacher: "False",
                email: Email,
                referral_code: ReferralCode,
                age: Age,
                name: Name.trim(),
                username: Username.trim(),
                primarily_teaches_at: PrimarilyTeachesAt,
                profile_pic: profilePic,
            };
            postSignup(data);
        }
    }
    else if(Profession === "teacher"){
        if (signupStatesTeacher[active - 1] === "") {
            alert("Please fill all your details.");
        } else {
            const data = {
                class_of_study: ClassOfStudy,
                location: Location.trim(),
                mobile_no: MobileNumber.trim(),
                // primary_lang: PrimaryLanguage.trim(),
                school_name: SchoolName.trim(),
                is_teacher: "True",
                email: Email,
                referral_code: ReferralCode,
                age: Age,
                name: Name.trim(),
                username: Username.trim(),
                primarily_teaches_at: PrimarilyTeachesAt,
                profile_pic: profilePic,
            };
            postSignup(data);
        }
    }
    };

    const postSignup = async (data) => {
        // data = {                             Template for create_user POST request
        //     class_of_study: "8",
        //     location: "testjerry",
        //     mobile_no: "9879879877",
        //     primary_lang: "English",
        //     school_name: "Schooltest1",
        //     is_teacher: "0",
        //     email: "jerrywonka@gmail.com",
        //     referral_code: "Jerr2ADN",
        //     age: "13",
        //     name: "Jerry Wonka",
        //     username: "Jerry4",
        //     primarily_teaches_at: "",
        //     profile_pic: "",
        // };
        // console.log(data);
        let form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        const csrfToken = await getCSRFtoken();
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/api/create_user`;
        try {
            const response = await fetch(url, {
                mode: "cors",
                method: "POST",
                body: form_data,
                headers: { "X-CSRFToken": `${csrfToken}`, "FIREBASE-ID": FID, Authorization: `Bearer ${UID}` },
                credentials: "include",
                SameSite: "None",
            });
            const responseJson = await response.json();
            // console.log(responseJson);
            if (responseJson.status) {
                alert(responseJson.message);
                
                redirect("/login");
            } else if (!responseJson.status) {
                alert(responseJson.Reason);
            }
            logout();
        } catch (error) {
            alert("Couldn't reach the server!");
            // console.error(error);
        }
    };

    // This page is only accessible from login page.
    useEffect(() => {
        if (Email === "") {
            redirect("/login");
            // ^ ENABLE THIS IN PROD
        }
    }, []);

    return (
        <>
            <NavNormalTheme />
            <Navbar navdata={props.navdata} currentPage={"signup"} class={"navbar-transp"} />
            <div className="main-wrap-signup" style={Profession === "student" ? {background:"#fffff"} : {backgroundColor:"#290c49"}}>
                <div className={`leftSection ${Profession === "teacher" ? 'teacher' : ''}`} style={Profession === "teacher" ? {borderRadius:"0rem 5rem 5rem 0rem"}:{borderRadius:""}}>                
                    {/* <Link to="/" className="logoSignup" ><img src={logo}/></Link> */}
                    {Profession === "" && (<button
                        id="studentbtn"
                        type="button"
                        className={`profession ${Profession === "student" ? "active" : ""}`}
                        onClick={(e) => {
                            setProfession("student");
                        }}>
                        Student
                    </button>)}
                    {Profession === "student" && (
                        <div id="studentsignup" className="signupContainer">
                            {active === 1 ? (
                            <>
                                <div className="signupTitle">Enter your country </div>

                                <input
                                type="text"
                                name="location"
                                id="location"
                                value={Location}
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                }}
                                placeholder="Country"
                                />
                                <div className="signupTitle">Enter your referral code</div>
                                <input
                                type="text"
                                name="referralcode"
                                id="referralcode"
                                value={ReferralCode}
                                onChange={(e) => {
                                    setReferralCode(e.target.value);
                                }}
                                placeholder="Referral Code"
                                pattern="^[A-Za-z0-9]*$"
                                />
                                <div className="signupTitle">Enter your class</div>

                                <div className="slide" id="class-of-study">
                                <button
                                type="button"
                                className={ClassOfStudy === "6" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("6");
                                }}
                                >
                                6th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "7" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("7");
                                }}
                                >
                                7th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "8" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("8");
                                }}
                                >
                                8th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "9" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("9");
                                }}
                                >
                                9th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "10" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("10");
                                }}
                                >
                                10th
                                </button>
                            </div>
                            <div className="signupTitle">Enter your Age</div>
                            <div className="slide" id="age">
                                <button
                                type="button"
                                className={Age === "9" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setAge("9");
                                }}
                                >
                                9
                                </button>
                                <button
                                type="button"
                                className={Age === "10" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setAge("10");
                                }}
                                >
                                10
                                </button>
                                <button
                                type="button"
                                className={Age === "11" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setAge("11");
                                }}
                                >
                                11
                                </button>
                                <button
                                type="button"
                                className={Age === "12" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setAge("12");
                                }}
                                >
                                12
                                </button>
                                <button
                                type="button"
                                className={Age === "13" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setAge("13");
                                }}
                                >
                                13
                                </button>
                                <button
                                type="button"
                                className={Age === "14" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setAge("14");
                                }}
                                >
                                14
                                </button>
                            </div>
                                
                            </>
                            ) : active === 2 ? (
                                <>
                            <div className="slide">
                                <div className="signupTitle">Enter your Name </div>
                                <input
                                type="text"
                                name="name"
                                id="name"
                                value={Name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                placeholder="Your name*"
                                pattern="^[A-Za-z\s]*$"
                                />
                                </div>
                            <div className="slide">
                            <div className="signupTitle">Enter your Profile Name</div>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                value={Username}
                                onChange={(e) => {
                                setUsername(e.target.value);
                                }}
                                placeholder="Profile Name*"
                                pattern="^[A-Za-z0-9_-]{5,16}$"
                            />
                            </div>
                            <div className="slide">
                            <div className="signupTitle">Upload your profile picture&nbsp; <span className="optional-txt"> (optional)</span></div>
                            <input
                                type="file"
                                name="myfile"
                                id="pfpUpload"
                                accept=".png, .jpg, .jpeg"
                                
                                onChange={(e) => handlePfp(e)}
                                />
                                <label htmlFor="pfpUpload" type="submit" name="submit" id="upload-btn-signup">
                                        Upload
                            </label>
                            </div>
                            </>
                            ) : null}
                            <div className="btnContainer">
                            {active > 1 ? (
                                <button className="prevBtn" onClick={prevSlide}>Prev</button>
                            ):null}
                            <button className="nextBtn" onClick={active === 1 ? nextSlide : active === 2 ? handleSignup : null}>
                                {active ===1 ? "Next" : "Enter"}
                            </button>
                            </div>
                        </div>
  
                    )}
                    {Profession === "teacher" && (
                        <img src={leftbackg}/>)}
                    </div>
                <div className={`rightSection ${Profession === "student" ? 'student' :''}`} style={Profession === "student" ? {borderRadius:"5rem 0rem 0rem 5rem"}:{borderRadius:""}}>
                    {/* <img src={teacher} className="buttonTopIcon" /> */}
                    {Profession === "" && (
                    <button
                        id="teacherbtn"
                        type="button"
                        className="profession"
                        onClick={(e) => {
                            setProfession("teacher");
                        }}>
                        Teacher
                    </button>)}
                    {Profession === "student" && (<>
                        <img src={rightbackg}/>
                        {active ===1 ? (<div className="rightCaption">
                        Enter correct 
                        <b>Age</b> and 
                        <b>Class</b>
                        for the best results and rewards!
                        </div>): null}
                        </>)}

                    {Profession === "teacher" && (
                            <div id="teachersignup" className="signupContainer">
                            {active === 1 ? (
                            <>
                                <div className="signupTitle">Enter your country </div>

                                <input
                                type="text"
                                name="location"
                                id="location"
                                value={Location}
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                }}
                                placeholder="Country"
                                />
                                <div className="signupTitle">Enter your referral code</div>
                                <input
                                type="text"
                                name="referralcode"
                                id="referralcode"
                                value={ReferralCode}
                                onChange={(e) => {
                                    setReferralCode(e.target.value);
                                }}
                                placeholder="Referral Code"
                                pattern="^[A-Za-z0-9]*$"
                                />
                                <div className="signupTitle">What class do you teach?</div>

                                <div className="slide" id="class-of-study">
                                <button
                                type="button"
                                className={ClassOfStudy === "6" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("6");
                                }}
                                >
                                6th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "7" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("7");
                                }}
                                >
                                7th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "8" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("8");
                                }}
                                >
                                8th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "9" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("9");
                                }}
                                >
                                9th
                                </button>
                                <button
                                type="button"
                                className={ClassOfStudy === "10" ? "btn-sel-a" : "btn-sel"}
                                onClick={(e) => {
                                    setClassOfStudy("10");
                                }}
                                >
                                10th
                                </button>
                            </div> 
                            <div className="signupTitle">Enter your Age </div>

                                <input
                                type="text"
                                name="age"
                                id="country"
                                value={Age}
                                pattern="^\d{1,2}$"
                                onChange={(e) => {
                                    setAge(e.target.value);
                                }}
                                placeholder="Age"
                                />  
                            </>
                            ) : active === 2 ? (
                                <>
                            <div className="slide">
                                <div className="signupTitle">Enter your Name </div>
                                <input
                                type="text"
                                name="name"
                                id="name"
                                value={Name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                placeholder="Your name*"
                                pattern="^[A-Za-z\s]*$"
                                />
                                </div>
                            <div className="slide">
                            <div className="signupTitle">Enter your Profile Name</div>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                value={Username}
                                onChange={(e) => {
                                setUsername(e.target.value);
                                }}
                                placeholder="Profile Name*"
                                pattern="^[A-Za-z0-9_-]{5,16}$"
                            />
                            </div>
                            <div className="slide">
                            <div className="signupTitle">Upload your profile picture&nbsp; <span className="optional-txt"> (optional)</span></div>
                            <input
                                type="file"
                                name="myfile"
                                id="pfpUpload"
                                accept=".png, .jpg, .jpeg"
                                
                                onChange={(e) => handlePfp(e)}
                                />
                                <label htmlFor="pfpUpload" type="submit" name="submit" id="upload-btn-signup">
                                        Upload
                            </label>
                            </div>
                            <div className="slide">
                            <div className="signupTitle">Enter School Name&nbsp; <span className="optional-txt"> (optional)</span></div>
                            <input
                                type="text"
                                name="schoolName"
                                id="schoolName"
                                value={SchoolName}
                                onChange={(e) => {
                                setSchoolName(e.target.value);
                                }}
                                placeholder="School Name"
                                pattern="^[A-Za-z\s]+$"
                            />
                            </div>
                            </>
                            ) : null}
                            <div className="btnContainer">
                            {active > 1 ? (
                                <button className="prevBtn" onClick={prevSlide}>Prev</button>
                            ):null}
                            <button className="nextBtn" onClick={active === 1 ? nextSlide : active === 2 ? handleSignup : null}>
                                {active ===1 ? "Next" : "Enter"}
                            </button>
                            </div>
                            </div>)}
                </div>
            </div>
        </>
    );
}
