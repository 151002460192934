import React from "react";
import Navbar from "./Navigation/Navigation";

export default function About() {
    return (
        <>
            <Navbar currentPage={"about"} class={"navbar"} />
            <div>hello</div>
        </>
    );
}
