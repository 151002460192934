import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Carousel, CarouselItem, Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home.css";
import Navbar from "./Navigation/Navigation";
import NavNormalTheme from "./Navigation/ThemeCSS/NavNormalTheme";
import { motion } from "framer-motion";

// Images and logos
import yozu_logo from "./Assets/LogoWhiteBg.png";
import encrypted from "./Assets/Home/encrypted.png";
import tempSocialLinks from "./Assets/Home/tempSocialLinks.png";
import contactus from "./Assets/Home/contactus.png";

import stcomp_icon from "./Assets/Home/student_companion_icon.png"
import teachassisst_icon from "./Assets/Home/teacher_assistant_icon.png"
import data_analytics_icon from "./Assets/Home/data_analytics_icon.png"
import seaminteg_icon from "./Assets/Home/seamless_integration_icon.png"
import savings_icon from "./Assets/Home/cost_savings_icon.png"

import HomeStarter from "./HomeHeading";

import studentGIF from "./Assets/Home/DemoGIFS/studentDemo.gif"             //student demo gif
import studentDemoTN from "./Assets/Home/DemoGIFS/studentDemoThumbnail.png" //student demo thumbnail
import teacherGIF from "./Assets/Home/DemoGIFS/teacherDemo.gif"             //teacher demo gif
import teacherDemoTN from "./Assets/Home/DemoGIFS/teacherDemoThumbnail.png" // teacher demo thumbnail

import Sandeep from "./Assets/Home/Team Photos/Sandeep.jpg"
import Sathya from "./Assets/Home/Team Photos/Sathya.jpeg"
import Rahul from "./Assets/Home/Team Photos/Rahul.jpg"
import Vignesh from "./Assets/Home/Team Photos/Vignesh.jpg"


export default function Home() {

    const [didScroll, setDidScroll] = useState(false);

    //Hover variable to control video when hovered over
    const [hover,setHover]=useState(null);


    // Carousel charts Import
    function importAll(r){
        return r.keys().map(r);
    }
    const charts = importAll(
        require.context('./Assets/Home/Charts', false, /\.(jpg|jpeg|png)$/)
        );

    // Function to handle scroll event and update the didScroll state
    const changeNavbar = () => {
        if (window.scrollY >= 300) {
            setDidScroll(true);
        } else {
            setDidScroll(false);
        }
    };
    window.addEventListener("scroll", changeNavbar);

    // Function to scroll to the top of the page
    const handleToTop = () => {
        setTimeout(() => {
            document.querySelector("#root").scrollIntoView();
        }, 100);
    };

    // Clear session storage on load to prevent email clash
    useEffect(() => {
        sessionStorage.clear();
    }, []);

    return (
        <>
            {/* Navigation */}
            <NavNormalTheme />
            <Navbar currentPage={"home"} class="noscrollnavbar navbar-dark" noLogo={true}/>
            {/* <motion.div initial={{ opacity: "0%" }} animate={{ opacity: "100%" }} transition={{ ease: "easeIn", duration: "0.5" }} exit={{ opacity: "0%" }}> */}
            {didScroll ? <Navbar currentPage={"home"} class="scrollnav navbar-light" /> : ""}
            {/* </motion.div> */}

            {/* Landing Section */}
            <section className="m-0 p-0 mw-100" id="hero-page">
                <HomeStarter />
            </section>

            {/* Features Section */}
            <section className="flex-column" id="feature-section">
                <h2 id="feature-title">We provide</h2>
                <Container id="feature-cards" className="d-flex justify-content-between align-items-center flex-xl-row flex-column gap-5 gap-xl-0">
                    <div className="d-flex justify-content-start align-items-center feature-card flex-column">
                        <Image src={encrypted} style={{ width: "5.563rem", aspectRatio: "1/1",marginBottom:'2rem' }} draggable="false"></Image>
                        <h3 className="feature-card-title text-center">Data Security</h3>
                        <p className="feature-card-text">Privacy & Customization that suits your needs</p>
                    </div>
                    <div className="d-flex justify-content-start align-items-center feature-card flex-column">
                        <Image src={seaminteg_icon} style={{ width: "5.563rem", aspectRatio: "1/1",marginBottom:'2rem' }} draggable="false"></Image>
                        <h3 className="feature-card-title text-center">Seamless Integration</h3>
                        <p className="feature-card-text">With your Content / LMS without sharing data</p>
                    </div>
                    <div className="d-flex justify-content-start align-items-center feature-card flex-column">
                        <Image src={savings_icon} style={{ width: "5.763rem", aspectRatio: "1/1",marginBottom:'2rem' }} draggable="false"></Image>
                        <h3 className="feature-card-title text-center">Highly Affordable</h3>
                        <p className="feature-card-text">1/20th the price of GPT-4</p>
                    </div>
                </Container>
            </section>

            {/* Products Section */}
            <section className="flex-column" id="product-section">
                {/* <div id="feature-backdrop"></div> */}
                <h2 id="feature-title">Products</h2>
                <Container id="feature-cards" className="d-flex justify-content-between align-items-center flex-xl-column flex-column gap-5 gap-xl-0">
                    <div className="d-flex align-items-center product-card flex-xl-row flex-column">
                        <div className="d-flex flex-column align-items-center">
                            <Image src={stcomp_icon} style={{ width: "5.563rem", aspectRatio: "1/1",marginBottom:'2rem' }} draggable="false"></Image>
                            <h3 className="feature-card-title text-center">Multilingual <br/> Student Companion</h3>
                        </div>
                        {/* GIF CONTENT */}
                        <div className="video-content" onMouseEnter={()=>setHover(1)} onMouseLeave={()=>setHover(null)}>

                          <img src={studentGIF} style={{display: hover === 1 ? "inline":"none"}}alt="demo-gif-student"/>
                          <img src={studentDemoTN} style={{display: hover === 1 ? "none":"inline"}}alt="demo-thumbnail-student"/>

                        </div>
                    </div>

                    <div className="d-flex align-items-center product-card flex-xl-row flex-column">
                        <div className="d-flex flex-column align-items-center w-20">
                            <Image src={teachassisst_icon} style={{ width: "5.563rem", aspectRatio: "1/1",marginBottom:'2rem' }} draggable="false"></Image>
                            <h3 className="feature-card-title text-center">Multilingual<br/>Teacher Assistant</h3>
                        </div>
                        
                        {/* GIF CONTENT */}
                        <div className="video-content" onMouseEnter={()=>setHover(3)} onMouseLeave={()=>setHover(null)}>

                            <img src={teacherDemoTN} style={{display: hover ===3 ? "none":"inline" }}alt="demo-gif-teacher"/>
                            <img src={teacherGIF} style={{display: hover ===3 ? "inline":"none" }}alt="demo-gif-teacher"/>

                        </div>
                    </div>

                    <div className="d-flex align-items-center product-card flex-xl-row flex-column">
                        <div className="d-flex flex-column align-items-center w-20">
                            <Image src={data_analytics_icon} style={{ width: "5.563rem", aspectRatio: "1/1",marginBottom:'2rem' }} draggable="false"></Image>
                            <h3 className="feature-card-title text-center">Data Analytics</h3>
                        </div>
                        
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <Carousel controls={false} 
                            className="home-carousel"
                            fade 
                            // activeIndex={1} 
                            interval={2000}                     
                            >
                            {charts.map((chart, cIndex) => (
                                <CarouselItem key={cIndex}>
                                <div className="carousel-image-container">
                                    <img
                                    className="d-block"
                                    src={chart}
                                    alt={`Slide ${cIndex + 1}`}
                                    />
                                </div>
                                </CarouselItem>
                            ))}
                            </Carousel>
                        </div>
                    </div>
                </Container>
            </section>

            {/* Team and Advisors Section */}
            <section className="d-flex flex-column justify-content-center px-3" id="team-section">
                <h2 id="team-title">Team</h2>
                <Container id="members" className="d-flex flex-xl-row flex-column justify-content-around align-items-center">
                <div id="team-card" className="d-flex flex-column justify-content-flex-start align-items-center team-card">
                        <Image src={Sandeep} style={{width: "15rem", aspectRatio: "1/1", marginBottom:'2rem',borderRadius:'1rem'}} draggable="false"/>
                        <h3 id="team-name">Sandeep Zutshi</h3>
                        <div className="team-card-text">
                            <ul>
                                <li><b>Role :</b> Founder</li>
                                <li>Founder : Bombay Bijlee </li>
                                <li>Co-Founder : K-Watt Solutions</li>
                            </ul>
                        </div>
                    </div>
                    <div id="team-card" className="d-flex flex-column justify-content-flex-start align-items-center team-card">
                        <Image src={Sathya} style={{width: "15rem", aspectRatio: "1/1", marginBottom:'2rem',borderRadius:'1rem'}} draggable="false"/>
                        <h3 id="team-name">Sathya Krishna</h3>
                        <div className="team-card-text">
                        <ul>
                            <li><b>Role :</b> AI R&D</li>
                            <li>MSc, Data Science & Society</li>
                            <li>Tilburg University ,Netherland</li>
                        </ul>
                        </div>
                    </div>
                </Container>

                <h2 id="team-title">Advisors</h2>
                <Container id="teams" className="d-flex flex-xl-row flex-column justify-content-around align-items-center">
                    <div id="team-card" className="d-flex flex-column justify-content-flex-start align-items-center team-card">
                        <Image src={Rahul} style={{width: "15rem", aspectRatio: "1/1", marginBottom:'2rem',borderRadius:'1rem'}} draggable="false"/>
                        <h3 id="team-name">Rahul Aralikatte</h3>
                        <div className="team-card-text">
                            <ul>
                            <li>Founding Member, Sarvam.ai</li>
                            <li>Chief Researcher @AI4Bharat</li>
                            <li>Visiting Researcher @Google</li>
                            </ul>
                        </div>

                    </div>
                    <div id="team-card" className="d-flex flex-column justify-content-flex-start align-items-center team-card">
                        <Image src={Vignesh} style={{width: "15rem", aspectRatio: "1/1", marginBottom:'2rem',borderRadius:'1rem'}} draggable="false"/>
                        <h3 id="team-name">Vignesh Krishnan</h3>
                        <div className="team-card-text">
                        <ul>
                            <li>City Head : TFI (Hyderabad)</li>
                            <li>MA Education ,UCL</li>
                            <li>Chevening Scholar</li>
                        </ul>
                        </div>
                    </div>
                </Container>
            </section>
            {/* Contact Us Section */}
            <section className="d-flex justify-content-center align-items-center" id="contact-section">
                <Container id="contact-content" className="d-flex flex-column flex-lg-row">
                    <div id="contact-col1" className="d-flex flex-column justify-content-center align-items-center gap-2">
                        <h2>Qurious !</h2>
                        <h3>To know more, let's connect</h3>
                        <Image src={contactus} draggable="false"></Image>
                    </div>
                    <form action="/" id="contact-col2" className="d-flex flex-column justify-content-between m-auto">
                        <input type="text" name="name" id="contact-name" className="contact-input" placeholder="Enter your Name" />
                        <input type="tel" name="phone" id="contact-phone" className="contact-input" placeholder="Enter Your Mobile Number" />
                        <input type="text" name="email" id="contact-email" className="contact-input" placeholder="Enter your E-mail Address" />
                        <textarea name="message" id="contact-message" cols="30" rows="10" className="contact-input" placeholder="Message"></textarea>
                        <button type="submit" id="contact-send-btn">
                            Send
                        </button>
                    </form>
                </Container>
            </section>

            {/* Footer */}
            <footer>
                <Container id="footer-content" className="p-4">
                    <div id="footer-upper" className="d-flex flex-row justify-content-between align-items-end border-bottom pb-3">
                        <div id="footer-logo" className="d-flex flex-column justify-content-end align-items-center gap-1">
                            <Link to="/" onClick={handleToTop}>
                                <Image src={yozu_logo} draggable="false"></Image>
                            </Link>
                            <span>Ask | Learn | Practice</span>
                        </div>
                        <div id="footer-contactinfo" className="d-flex flex-column justify-content-end align-items-end">
                            <span style={{ fontSize: "1.2rem", fontWeight: "600" }} className="mb-3">
                                Say hello!
                            </span>
                            <a href="mailto:zutshi@yozu.in">zutshi@yozu.in</a>
                            {/* <a href="tel:+918484081062">+91-8484081062</a> */}
                            {/* <a href="tel:+919321299294">+91-9321299294</a> */}
                        </div>
                    </div>
                    <div id="footer-lower" className="d-flex flex-row justify-content-between align-items-center pt-3">
                        <span>@ 2023 yozu.in | Privacy policy | Terms of use | Accesibility</span>
                        <Image src={tempSocialLinks}></Image> {/* Actual links to be included here in the future */}
                    </div>
                </Container>
            </footer>
        </>
    );
}
