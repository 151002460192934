import styles from "./Teacher.module.css";
import { useTeacherContext } from "./TeacherContext";
import { useGlobal } from "../../context/GlobalContext";
import { useState, useEffect } from "react";
import LoadAnim from "./LoadAnim";
import { motion, AnimatePresence } from "framer-motion";

import maximize_icon from "../Assets/Teacher/maximize_icon.png";
import back from "../Assets/backbutton.png";

//Place Holder Image
import imgph from "../Assets/Teacher/imgph.png"

export default function TeacherCenterFrame() {
  //Teacher context states
  const {
    plan,
    setPlan,

    expLP,
    setExpLP,

    planData,
    setPlanData,

    setLPID,
    setQuestions,
  } = useTeacherContext();
  //Global context states
  const { UID, CSRFToken } = useGlobal();

  //State variables
  // const [lessonPlans, setLessonPlans] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  const [activeGrid, setActiveGrid] = useState(null);

  const [chapno, setChapno] = useState(0);
  const [chapters, setChapters] = useState([]); // Use state for chapters and setChapters to update it.

  const [topicNo,setTopicNo]=useState(0);
  const [topics, setTopics] = useState([]);

  const [hoveredElement, setHoveredElement] = useState(null);

  const [loader,setLoader]=useState(null);

  //Lesson plan structure
  const headers=["Grade","Chapter","Topic"];
  const body = ["Objectives","Key Pointers","Launch","Hook","How","Integration With Everyday Life","Guided Activity","Conclusion"];
  const media = ["videos","section_wise_images"];

  //Initial Popup structure
  const grades = ["6", "7", "8", "9", "10"];
  const boards = ["NCERT", "S.Chand"];

  const togglePopup = () => {
    setPlanData(prevPlanData => ({
      ...prevPlanData,
      board: planData.board ?? null,
      grade: planData.grade ?? null
    }));
    setActiveGrid(activeGrid ?? null);
    setShowPopup(!showPopup);
  };

  const handleBoardClick = async(index) =>{
      setPlanData(prevPlanData => ({
        ...prevPlanData,
        board: boards[index],
      }));
      setActiveGrid("grade");
  };

  const handleGradeClick = async (index) => {
    setChapters([]);
    setPlanData(prevPlanData => ({
        ...prevPlanData,
        chapter: null,
        topic: null,
        grade: grades[index],
      }));
    setHoveredElement(null);
    setActiveGrid("chapter");
  };

  const handleChapterClick = (btn) => {
    setPlanData(prevPlanData => ({
        ...prevPlanData,
        chapter: btn,
        topic:null,
      }));
    setHoveredElement(null);
    setChapno(chapters.indexOf(btn));
  };

  const handleTopicClick = (btn) => {
    setPlanData(prevPlanData => ({
      ...prevPlanData,
      topic:btn,
    }));
    setTopicNo(topics.indexOf(btn));
  };

  const handleConfirmClick = async () => {
    if (activeGrid === "chapter") {
      if (planData.chapter !== null) {
        setActiveGrid("topic");
        fetchTopicInfo();
      }
    } else if (activeGrid === "topic") {
      if (planData.topic !== null) {
        setActiveGrid(null);
        setShowPopup(false);
        await fetchLessonPlan();
      }
    }
  };

  const handleBackClick = () => {
    setActiveGrid("chapter");
    setPlanData(prevPlanData => ({
        ...prevPlanData,
        chapter: null,
        topic:null,
      }));
  };

  const handleLPClose = () => {
    setQuestions([]);
    setPlan();
    setExpLP(null);
    setLPID(null);
    setPlanData(prevPlanData => ({
      ...prevPlanData,
      board:null,
      grade: null,
      subject:null,
      chapter:null,
      topic:null,
    }));
  };

  const fetchChapterInfo = async () => {
    setLoader(true);
    const data = {
      Board: planData.board,
      Grade: planData.grade,
      // "Chapter": planData.chapter,
      Subject: planData.subject,
      language: "english",
    };
    const form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/get_keys`;

      const response = await fetch(url, {
        body: form_data,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
      });
      const responseJson = await response.json();
      console.log(responseJson);
      setLoader(false);
      setChapters(responseJson.data.Chapter);
    } catch (error) {
      alert("Error in chapter fetching", error);
    }
  };

  const fetchTopicInfo = async () => {
    setLoader(true);
    const data = {
      Board: planData.board,
      Subject: planData.subject,
      Grade: planData.grade,
      Chapter: planData.chapter,
      language: "english",
    };

    const form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }

    const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/get_keys`;
    try {
      const response = await fetch(url, {
        body: form_data,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
      });
      const responseJson = await response.json();
      setLoader(false);
      setTopics(responseJson.data.Topic);
      // console.log(responseJson.data.Topic)
    } catch (error) {
      alert("Error occuring in topic fetching :", error);
    }
  };

  const fetchLessonPlan = async () => {
    setLoader(1);
    const data = {
      Board: planData.board,
      Subject: planData.subject,
      Grade: planData.grade,
      Chapter: planData.chapter,
      Topic: planData.topic,
      language: "english",
    };

    const form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/generate`;

    try {
      const response = await fetch(url, {
        body: form_data,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
        mode:"cors"
      });
      const responseJson = await response.json();
      
      // console.log(responseJson)
      setLPID(responseJson.lesson_id);
      setPlan(responseJson);
      setLoader(null);
    } catch (e) {
      alert("Error while fetching lesson plan:", e);
      setLoader(null);
    }
  };

  useEffect(() => {
    if (activeGrid === "chapter" && planData.grade !== null) {
      fetchChapterInfo();
    } else if (activeGrid === "topic" && planData.chapter !== null) {
      fetchTopicInfo();
    }
  }, [activeGrid, planData.grade, planData.chapter]);

  useEffect(() => {
    // fetchLPs(); //ENABLE WHEN FETCHLP WHEN SAVE DESIGN IS FINSALISED
  }, [UID, showPopup]);

  return (
    <div id={styles.centerSection}>
        <div id={styles.planContainer} className={styles.msgContainer}>
          {/* POPUP */}
          <AnimatePresence>
            {showPopup && !plan && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="Popup"
                id={styles.popupContainer}
              >
                <div className={styles.popupTitle}>Select Plan Details</div>

                <div className={styles.gradeContainer}>
                  <div
                  className={`${styles.optTitleShow} ${planData.board !== null && styles.active}`}>
                  {planData.board ===null ? "Select Board" : "Selected Board"}
                  </div>
                  <div className={styles.btnGrp}>
                    {boards.map((item, index) => (
                      <button
                        key={index}
                        className={`${styles.gradeBtn} ${
                          item === planData.board ? styles.active : ""
                        }`}
                        onClick={() => handleBoardClick(index)}
                      >
                        {item}
                      </button>
                    ))}
                  </div>
                  {planData.grade !== null && <hr />}
                </div>

                    {activeGrid !== null &&(<>
                    <div className={styles.gradeContainer}>
                      <div
                        className={`${styles.optTitleShow} ${
                          planData.grade !== null && styles.active
                        }`}
                      >
                        {planData.grade === null ? "Select Grade" : "Selected Grade"}
                      </div>
                      {/* ADD ANIMATION WHILE LOADING*/}
                      
                      <div className={styles.btnGrp}>
                        {grades.map((item, index) => (
                          <button
                            key={index}
                            className={`${styles.gradeBtn} ${
                              item === planData.grade ? styles.active : ""
                            }`}
                            onClick={() => handleGradeClick(index)}
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                      {planData.grade !== null && <hr />}
                    </div>
                    </>)}
                
                {activeGrid === "chapter" && (
                  <>
                    <div className={styles.optTitleContainer}>
                      <div
                        className={`${styles.optTitleShow} ${
                          planData.chapter !== null && styles.active
                        }`}
                      >
                        {planData.chapter === null ? `Select Chapter` : `Selected Chapter`}
                      </div>
                      {/* ADD ANIMATION WHILE LOADING*/}
                      {loader && (<>
                      {/* <img src="https://media.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif"/> */}
                      {/* <LoadAnim/> */}
                      </>)} 
                      {planData.chapter !== null && (
                        <>
                          <div className={styles.optTitleShow}>
                            Ch. No.-{chapno + 1}
                          </div>
                          <div className={styles.optTitleShow}>{planData.chapter}</div>
                        </>
                      )}
                    </div>
                    <div className={styles.gridContainer}>
                      <div className={styles.grid}>
                        {chapters.map((item, index) => (
                          <button
                            key={index}
                            className={`${styles.gridBtn} ${
                              planData.chapter === item ? styles.active : ""
                            }`}
                            onMouseEnter={() => setHoveredElement(item)}
                            onClick={() => handleChapterClick(item)}
                            // You need to wrap these functions in arrow functions
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                      <div className={styles.itemDisplay}>
                        {hoveredElement && hoveredElement}
                        {/* Increase font-size and/or font-weight */}
                      </div>
                    </div>
                  </>
                )}

                {activeGrid === "topic" && (
                  <>
                    <div className={styles.showSelection}>
                      <button
                        className={styles.backBtn}
                        onClick={handleBackClick}
                      >
                        <img src={back} />
                      </button>
                      <div className={styles.optTitleShow}>
                        Ch. No.{chapno + 1}
                      </div>
                      <div className={styles.optTitleShow}>{planData.chapter}</div>
                    </div>
                    {planData.chapter !== null && <hr />}
                    <div className={styles.optTitleContainer}>
                      <div
                        className={`${styles.optTitleShow} ${
                          planData.topic !== null && styles.active
                        }`}
                      >
                        {planData.topic === null ? `Select Topic` : `Selected Topic`}
                      </div>
                      {planData.topic !== null && (
                        <div className={styles.optTitleShow}>{planData.topic}</div>
                      )}
                    </div>
                    <div className={styles.gridContainer}>
                      <div className={styles.grid}>
                        {topics.map((item, index) => (
                          <button
                            key={index}
                            className={`${styles.gridBtn} ${
                              planData.topic === item ? styles.active : ""
                            }`}
                            onMouseEnter={() => setHoveredElement(item)}
                            onClick={() => handleTopicClick(item)}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                      <div className={styles.itemDisplay}>
                        {hoveredElement && hoveredElement}
                        {/* Increase font-size and/or font-weight */}
                      </div>
                    </div>
                  </>
                )}
                {planData.grade !== null && (
                  <button
                    className={styles.confirmBtn}
                    onClick={handleConfirmClick}
                  >
                    Confirm
                  </button>
                )}
                
              </motion.div>
            )}
          </AnimatePresence>
          
          {/* LOADING ANIMATION */}
          {loader === 1 && (<>
          <LoadAnim/>
          </>)}
          
          {/* EMPTY SECTION PLACEHOLDER */}
          {!loader && !showPopup && !plan && (
            <div className={styles.teacherPH}>Generate Lesson Plan</div>
          )}

          {/* LESSON PLAN DISPLAY */}
          <AnimatePresence>
            {!expLP && plan && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                key="minPlan"
                className={styles.LPMinContainer}
              >
                <div className={styles.LPMinHead}>
                  {headers.map((headItem,headIndex)=>(<>
                    <div className={styles.LPMinHeadItem} key={headIndex}>
                    {headItem}: {plan.data[headItem][0]}
                  </div>
                  </>))}
                </div>
                <div className={styles.LPMinBody}>
                  {body.map((property,subIndex)=>(<>
                  <div key={subIndex} className={styles.LPMinBlock}>
                    <div className={styles.LPMinMsg} key={subIndex}>
                      <h1>{property}</h1>
                      <ul>
                        {plan.data[property].map((point,pointIndex)=>(<>
                        <li key={pointIndex}>{point}</li>
                        </>))}
                      </ul>
                    
                    <div className={styles.LPMinImgContainer}>
                      <h2>Images</h2>
                      <div className={styles.LPMinImgOptns}>
                        {plan.section_wise_images[property].map((img,imgIndex)=>(<>
                        <div className={styles.LPMinImgOptn}>
                          <img key={img} src={img} alt={`Image ${imgIndex+1}`}/>
                        </div>
                        </>))}
                      </div>
                    </div>
                    </div>
                  </div>
                  </>))}
                </div>
                <div className={styles.LPMinFooter}>
                  <button onClick={handleLPClose}>&times;</button>
                  <button
                    className={styles.expandBtn}
                    onClick={() => setExpLP(plan)}
                  >
                    <img src={maximize_icon} />
                  </button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <div className={styles.btnContainer}>
            {!expLP && !plan && (
              <div
                className={styles.middleBtnWrapper}
                style={showPopup ? { border: "1px solid #fff" } : {}}
              >
                <button className={`${styles.middlePlanBtn} ${showPopup ? styles.activeBtn : ""}`} onClick={togglePopup}>
                  Generate Plan
                </button>
              </div>
            )}
          </div>
        </div>
    </div>
  );
}
