import { motion } from "framer-motion";
import React, { useEffect } from "react";
import loader from "../Assets/Chatbot/loading-animation200px.svg";
import { useChatbotContext } from "./ChatbotContext";

export default function Spinner() {
    // Global context
    const { scrollToBottom } = useChatbotContext();

    //STYLES FOR LOADING ICON IN CHATBOT
    const styles = { borderRadius: "0 1.5rem 1.5rem 1.5rem", backgroundColor: "#4c4170", wordWrap: "break-word", overflowWrap: "anywhere", fontWeight: "500", fontSize: "19px", lineHeight: "1.3em", padding: "0.5rem 2rem", width: "fit-content", maxWidth: "75%", whiteSpace: "pre-wrap", display: "flex", flexDirection: "column" };

    useEffect(() => {
        scrollToBottom();
    }, []);

    return (
        // Spinner for the chatbot message while user waits
        <motion.div initial={{ opacity: 0, transform: "translateY(5px)" }} animate={{ opacity: 1, transform: ["translateY(0px)", "translateY(-5px)", "translateY(0px)"] }} transition={{ duration: 0.5 }} key={-1} style={{ ...styles }}>
            <img src={loader} alt="loading" style={{ height: "2rem", objectFit: "contain" }} />
        </motion.div>
    );
}
