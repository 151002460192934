import React, { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { redirect, useNavigate } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import "./Login.css";
import Navbar from "./Navigation/Navigation";
import NavNormalTheme from "./Navigation/ThemeCSS/NavNormalTheme";

import rightbackg from "./Assets/Signup/studentbackg.png";
import google_icon from "./Assets/Login/google_logo.png";
import lessonplan_icon from "./Assets/Login/lessonplan_icon.png";
import assessment_icon from "./Assets/Login/assessment_icon.png";
import DA_icon from "./Assets/Login/datanalytics_icon.png";


export default function Login(props) {
  // Global context
  const {
    Profession,setProfession,logout,
    setUID,
    googleLogin,
    setIsVerified,
    getUserDetails,
    setFID,
    FID,
    setEmail,
    getCSRFtoken,
    CSRFToken,
    setReferralCode,
  } = useGlobal();

  // Username and password states (Not connected to backend)
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");

  const [isGoogleBtnDisabled, setIsGoogleBtnDisabled] = useState(false);

  const navigate = useNavigate();

  // Call the firebase google login API
  const handleGoogleClick = async () => {
    setIsGoogleBtnDisabled(true);
    googleLogin()
      .then(async (result) => {
        const idTokenResult = await result.user.getIdTokenResult(
          window.localStorage.getItem("idToken")
        );
        window.localStorage.setItem("idToken", idTokenResult.token);
        // console.log("token result ", idTokenResult);
        setUID(window.localStorage.getItem("idToken"));
        setFID(idTokenResult.claims.user_id);
        setEmail(idTokenResult.claims.email);
        // console.log("idtokenresult.claims.email ", idTokenResult.claims.email);
        // console.log("FID ", FID);
        await verifyUserInDatabase(idTokenResult.claims.email);
        await getUserDetails();
      })
      .catch((error) => {
        // console.log(`error code : ${error.code}\nerror message : ${error.message}`);
        setIsGoogleBtnDisabled(false);
      });
  };

  // Verify whether the user is in the database
  const verifyUserInDatabase = async (email_id) => {
    const data = {
      email_id: email_id,
      MODE: "SINGLE",
    };

    
    let form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }

    const url = `${process.env.REACT_APP_BACKEND_SERVER}/check/email_id`;
    try {
      const response = await fetch(url, {
        mode: "cors",
        method: "POST",
        body: form_data,
        headers: {
          "X-CSRFToken": `${CSRFToken}`,
          Authorization: `Bearer ${window.localStorage.getItem("idToken")}`,
        },
        credentials: "include",
        SameSite: "None",
      });
      const responseJson = await response.json();
      // console.log(responseJson);
      if (response.status === 200) {
        if (responseJson.status && responseJson.check) {
          if (responseJson.teacher_status) {
            setIsVerified(true);
            navigate("/teach");
          } else {
            setIsVerified(true);
            // console.log("user exists");
            navigate("/chat");
          }
        } else {
          setIsVerified(false);
          alert("User doesn't exist! Please signup first");
          navigate("/signup");
        }
      } else {
        throw Error("Request not successful. Status code : " + response.status);
      }
    } catch (error) {
      logout();
      setIsVerified(false);
      // console.error(error);
      alert("Couldn't reach the server!");
    }
    setIsGoogleBtnDisabled(false);
  };

  // Collect login credentials and call parent function in App.js (Not connected to backend)
  const handleLogin = async (e) => {
    // Prevent the default form submission behavior.
    e.preventDefault();

    // Get the username and password from the form.
    const username = Username.trim();
    const password = Password.trim();

    // If the username and password are not empty, try to sign in the user.
    if (username !== "" && password !== "") {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, `${username}@yozu.in`, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const idTokenResult = await user.getIdTokenResult();
          const idToken = idTokenResult.token;
          window.localStorage.setItem("idToken", idToken);

          // Set UID and other state variables.
          setUID(idToken);
          const usernameresponse = await fetchUserInfo(idToken);
          // console.log(usernameresponse);
          if (
            usernameresponse.status === false){
              setEmail(`${username}@yozu.in`);
              setIsVerified(false);
              alert("User doesn't exist! Please signup first");
              navigate("/signup");
            }
            else{
          // console.log("Hi hello");
          setIsVerified(true);

          // Navigate to the chat page.
          navigate("/chat");}
          // ...
        })
        .catch(async (error) => {
          const errorCode = error.code;
          const errorMessage = error.message;

          switch (errorCode) {
            case "auth/user-not-found":
            case "EMAIL_NOT_FOUND":
              createUserWithEmailAndPassword(auth, `${username}@yozu.in`, password)
                .then(async (userCredential) => {
                  const user = userCredential.user;
                  const idTokenResult = await user.getIdTokenResult();
                  const idToken = idTokenResult.token;
                  window.localStorage.setItem("idToken", idToken);

                  // Set UID and other state variables.
                  setUID(idToken);
                  // console.log("Hi hello");
                  
                  // ...
                })
                .catch(async (error) => {});
              // If the user is not found, check the referral code again.
              const referralCodeCheckResponse = await checkReferralCode(
                password
              );
              const usernameresponse = await checkUserCode(username);

              if (
                usernameresponse.status &&
                referralCodeCheckResponse.check === true
              ) {
                // Set email and retrieve the token from local storage.
                setEmail(`${username}@yozu.in`);
                setIsVerified(false);
                navigate("/signup");
              } else if (referralCodeCheckResponse.check === false) {
                // If the referral code is not valid, display an error message to the user.
                alert("Contact your teacher or provide more instructions.");
              }
              break;

            case "auth/wrong-password":
              alert("Incorrect password. Please try again.");
              break;

            default:
              alert(`Login failed: ${errorMessage}`);
              break;
          }
          // ..
        });
    }
  };


  const checkReferralCode = async (referralCode) => {
    const url = `${
      process.env.REACT_APP_BACKEND_SERVER
    }/check/referal_code?referal_code=${encodeURIComponent(
      referralCode
    )}&is_teacher=0`;

    try {
      const response = await fetch(url, 
        {
        mode: "cors",
        method: "GET",
        credentials: "include",
      });

      if (response.ok) 
      {
        const responseJson = await response.json();
        // console.log(responseJson);
        if (response.status === 200) {
          return responseJson;
        }
      }
    } catch (error) {
      // console.error("An error occurred:", error);
    }
    // Handle any other error cases or return a default value if needed
    return { status: 200 };
  };


  const checkUserCode = async (username) => {
    const url = `${
      process.env.REACT_APP_BACKEND_SERVER
    }/check/username?username=${encodeURIComponent(username)}`;
    // Note: Using encodeURIComponent to properly encode the username in the URL.

    try {
      const response = await fetch(url, 
        {
        mode: "cors",
        method: "GET",
      });

      if (response.ok)
       {
        const responseJson = await response.json();
        if (responseJson.status) {
          // Handle success in your UI or application logic
          // console.log(responseJson.message);
        } else {
          // Handle server response error in your UI or application logic
          alert(responseJson.reason); // Adjust the property name based on the server response
        }
        return { status: 200 }; // Return the response for further processing if needed
      } else 
      {
        throw new Error(`${response.status}`);
      }
    } catch (error) 
    {
      // Handle the error in your UI or log it to the console
      // console.error("An error occurred:", error);
      alert("Couldn't reach the server!");
    }
  };

  const fetchUserInfo = async (idToken) => {
    const url = `${
      process.env.REACT_APP_BACKEND_SERVER
    }/api/get_user_details`;
    try{
      const response = await fetch(url, {
        mode: "cors",
        method: "GET",
        credentials: "include",
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
        credentials: "include",
    });
    if (response.ok) {
      const responseJson = await response.json();
      // console.log(responseJson);
      if (response.status === 200) {
        return responseJson;
      }
    }
  } catch (error) {
    // console.error("An error occurred:", error);
  }
  // Handle any other error cases or return a default value if needed
  return { status: 200 };
};

  // Clear session storage on load to prevent email clash
  useEffect(() => {
    localStorage.clear(); //for session clean
    // console.log("Session storage cleared");
    setProfession("")
    logout();
    getCSRFtoken();
  }, []);

    return (
        <>
            {/* Navigation */}
            {/* <NavNormalTheme /> */}
            <Navbar currentPage={"login"} class={"navbar-transp"}/>

            {/* Login card */}
            <div className="main-wrap" style={Profession === "teacher" ? {background:"#290c49"} : {backgroundColor:"#fff"}}>
                <div className={`leftSectionLogin ${Profession === "student" ? "student": Profession === "teacher" ? "teacher":""}`}>
                    {/* <Link to="/" className="logoSignup" ><img src={logo}/></Link> */}
                    {Profession === "" && (<button
                        id="studentbtn"
                        type="button"
                        className='professionLogin'
                        onClick={(e) => {
                            setProfession("student");
                        }}>
                        Student
                    </button>)}
                    {Profession === "teacher" && (<>
                      <div className="leftCaption">
                        <div className="item">
                          <img src={lessonplan_icon} alt="Lesson Plan" className="icon" />
                          <h1>Lesson Plans</h1>
                        </div>
                        <div className="item">
                          <img src={assessment_icon} alt="Assessment" className="icon" />
                          <h1>Assessments</h1>
                        </div>
                        <div className="item">
                          <img src={DA_icon} alt="Data Analytics" className="icon" />
                          <h1>Data Analytics</h1>
                        </div>
                      </div>
                    </>)}   
                    {Profession === "student" && (
                        <div className="outer-wrap">
                        <h1 id="login-title">Create Account / Login</h1>
                        <div className="social-login">
                            {/* <a href="#">
                              <img src="images/fb.png" alt="" />
                            </a>  */}
                             <button disabled={isGoogleBtnDisabled} onClick={handleGoogleClick} id="googlelogin-btn">
                                <img src={google_icon} alt="google login" draggable="false" />
                                 Using Google
                            </button> 
                            {/* <a href="#">
                            <img src="images/twitter.png" alt="" />
                            </a>  */}
                        </div>
                        <p>or</p>
    
                        <form className="form" autoComplete="off">
                            <label htmlFor="username">Username</label>
                            {/* <br /> */}
                            <input
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Username"
                                autoComplete="username"
                                required
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                }}
                            />
                            {/* <br /> */}
                            <label htmlFor="password">Password</label> <p>(Referral code provided by your school)</p>
                            {/* <br /> */}
                            <input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Password"
                                autoComplete="current-password"
                                required
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                            />
                            {/* <br /> */}
                            <div>
                                <div>
                            <input type="checkbox" name="check" id="check" />
                            <span className="rm-me">Remember Me</span>
                            </div>
                            <a href="/" className="fg-pa">
                                Forgot Password?
                            </a>
                            </div>
                            {/* <br /> */}
                            <button type="submit" id="login-btn" onClick={handleLogin}>
                                Enter
                            </button>
                            {/* <br /> */}
                        </form>
                        </div>
                    )}
                    
                </div>
                <div className={`rightSectionLogin ${Profession === "teacher" ? "teacher" : Profession === "student" ? "student":""}`}>
                    {/* <img src={teacher} className="buttonTopIcon" /> */}
                    {Profession === "" && (<button
                        id="teacherbtn"
                        type="button"
                        className='professionLogin'                        
                        onClick={(e) => {
                            setProfession("teacher");
                        }}>
                        Teacher
                    </button>)}
                    {Profession === "student" && (<>
                        <img src={rightbackg}/>
                        </>)}
                    {Profession === "teacher" && (
                        <div className="outer-wrap" style={{backgroundColor:"#290c49",color:"#ffffff"}}>
                            <h1 id="login-title" style={{color:"#ffffff"}}>Create Account / Login</h1>
                            <div className="social-login">
                                {/* <a href="#">
                                    <img src="images/fb.png" alt="" />
                                </a>  */}
                                <button disabled={isGoogleBtnDisabled} onClick={handleGoogleClick} id="googlelogin-btn">
                                <img src={google_icon} alt="google login" draggable="false" />
                                Using Google
                                </button> 
                                {/* <a href="#">
                                    <img src="images/twitter.png" alt="" />
                                </a>  */}
                            </div>
                        </div>
                    )}
                    
                </div>
            </div>
        </>
    );
}