import styles from "./Teacher.module.css";
import { useEffect, useState } from "react";

import { useTeacherContext } from "./TeacherContext";
import { useGlobal } from "../../context/GlobalContext";

import { motion } from "framer-motion";
import { Carousel, Form } from "react-bootstrap";

//Placeholder Images
import imgph from "../Assets/Teacher/imgph.png";
import imgsel from "../Assets/Teacher/download.png";

//Suggest Popup Images
import suggest from "../Assets/Teacher/suggest_icon.png"
import explain from "../Assets/Teacher/explain_icon.png"
import insert from "../Assets/Teacher/insert_icon.png"
import refresh from "../Assets/Teacher/refresh_icon.png"

import edit_icon from "../Assets/Teacher/edit_icon.png"
import logo from "../Assets/LogoWhiteBg.png"

import LessonPlanDownload from "./LessonPlanDownload";
import { event } from "jquery";

export default function LessonPlan(props) {

  //Global context states
  const { UID, CSRFToken } = useGlobal();

  //Teacher context states
  const { LPID,setExpLP,plan,setPlan,} = useTeacherContext();

  //Local State Variables
  const [isEdit, setIsEdit] = useState(false);

  const [originalPlan,setOriginalPlan] = useState(props.plan);
  const [editedPlan, setEditedPlan] = useState(props.plan);
  const [screenPlan,setScreenPlan]=useState(originalPlan);

  const [finalPopup,setFinalPopup]=useState(false);
  const[imgPopup,setImgPopup]=useState(false);
  const[previewMode,setPreviewMode]=useState(false);
  const [searchedImages,setSearchedImages]=useState([]);

  const [editLi,setEditLi]=useState([null,null]);
  const [suggestEdit,setSuggestEdit]=useState(false);

  const [pointValue, setPointValue] = useState(null); // Store the current value of the li
  const [suggValue,setSuggValue]=useState('');
  const [click,setClick]=useState(null);

  //Lesson plan structure
  const headers=["Grade","Chapter","Topic"];
  const body = ["Objectives","Key Pointers","Launch","Hook","How","Integration With Everyday Life","Guided Activity","Conclusion"];

  useEffect(()=>{
    setScreenPlan((isEdit ? editedPlan : originalPlan));
  },[isEdit,editedPlan,originalPlan]);

  const handleTopicChange = (key, value) => {
    if(key === value){
      return;
    }
    setEditedPlan((prevEditedPlan) => {
      const newEditedPlan ={...prevEditedPlan};
      
      newEditedPlan[value]=prevEditedPlan[key];

      delete newEditedPlan[key];

      return newEditedPlan;
  });
  // setScreenPlan(editedPlan);
  };

  const handleContentChange = (key,listindex,value) => {
    setScreenPlan((prevScreenPlan)=> {
      const updatedScreenPlan = {...prevScreenPlan};
      const updatedData = {...updatedScreenPlan.data};

      if(listindex>=0 && listindex <updatedData[key].length){
        updatedData[key][listindex]=value;
      }else{
        console.error('Invalid List Index');
        return prevScreenPlan;
      }

      updatedScreenPlan.data = updatedData;
      return updatedScreenPlan;

    });
      setEditedPlan(screenPlan);
      setPointValue(value);
  };

  const handleInsert = async(key,listIndex,value) =>{
    handleContentChange(key,listIndex,value);
    setEditLi([null,null]);
    setSuggestEdit();
  }

  const saveClick = async () => {  
    const isPlanDifferent = JSON.stringify(screenPlan) !== JSON.stringify(originalPlan);

    if (isEdit && isPlanDifferent) {
      const jsonObject = {};
  
      for (const key in screenPlan) {
        const value = screenPlan[key];
  
        if (Array.isArray(value)) {
          jsonObject[key] = JSON.stringify(value);
        } else {
          jsonObject[key] = value;
        }
      }

      const data2send = new FormData();
      data2send.append("lesson_plan_id", LPID);
      data2send.append("lesson_data", JSON.stringify(jsonObject));
          
      const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/edit`;
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: data2send,
          headers: {
            Authorization: `Bearer ${UID}`,
            'X-CSRFToken': CSRFToken,
          },
          credentials: 'include',
        });
  
        if (response.ok) {
          // AFTER THE RESPONSE IS OKAY,SET THE PLAN AS WHAT THE USER EDITED
          const responseJson = await response.json();
          setPlan(screenPlan);
          setOriginalPlan(screenPlan);
          alert("Lesson Plan Saved Successfully!");
        } else {
          throw new Error('Failed to save Custom LessonPlan');
        }
      } catch (e) {
        alert('Error while saving lesson plan', e);
        console.log(e);
      }
      setIsEdit(false);
    }
    else {
      setExpLP(null);
    }
  };
  
  const getSuggestions = async(typeClick) => {
    const url=`${process.env.REACT_APP_BACKEND_SERVER}/chat/get_sugesstions`

    // console.log(typeof typeClick,"type");
    // console.log(typeof pointValue,"PV");

    const formData = new FormData();
    formData.append('type', typeClick);
    formData.append('text', pointValue);

    const resp = await fetch(url,{
      method: 'POST',
      body:formData,
      headers: {
        Authorization: `Bearer ${UID}`,
        'X-CSRFToken': CSRFToken,
      },
      credentials: 'include',
      mode:"cors",
    });

    const responseJSON = await resp.json();

    console.log(responseJSON);
    console.log(resp);

    // setPointValue(responseJSON.new_text.new_text);
    setSuggValue(responseJSON.new_text);
  }

  const handleRemoveImg = (indexToRemove) =>{
    setScreenPlan((prevPlan)=>{
      const updatedSectionImages = prevPlan.section_wise_images[imgPopup].filter((_, i) => i !== indexToRemove);

      const updatedSectionWiseImages = {
        ...prevPlan.section_wise_images,
        [imgPopup]: updatedSectionImages,
      };
  
      return {
        ...prevPlan,
        section_wise_images: updatedSectionWiseImages,
      };
    });
  };

  const handleImgAdd = (img) => {
    setScreenPlan((prevPlan) => {
      const updatedSectionImages = [...prevPlan.section_wise_images[imgPopup]];
  
      if (updatedSectionImages.length < 2) {
        updatedSectionImages.push(img);
  
        const updatedSectionWiseImages = {
          ...prevPlan.section_wise_images,
          [imgPopup]: updatedSectionImages,
        };
  
        return {
          ...prevPlan,
          section_wise_images: updatedSectionWiseImages,
        };
      } else {
        alert("Only 2 images allowed per section!");
        return prevPlan;
      }
    });
  };
  
  const handleLiClick = (property,point,pointIndex) => {
      setEditLi([property,pointIndex]);
      setPointValue(point);
      setSuggValue(null);
  };
  
  const findImages = async (event) =>{
    if(event){
      event.preventDefault();
    }

    const query= new FormData(event.target);
    const formData = new FormData();

    formData.append( 'search_query' , query.get('imgSearch'));

    const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/allennlp_image`;
    const response = await fetch(url,{
      method:"POST",
      body:formData,
      headers: {
        Authorization: `Bearer ${UID}`,
        'X-CSRFToken': CSRFToken,
      },
      credentials: 'include',
    });
    const responseJsoned= await response.json();
    console.log(responseJsoned);
    setSearchedImages(responseJsoned.images[0]);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.LPContainer}
      >

        {/* EXPANDED LESSON PLAN */}
        <div className={styles.LPWrapper}>
          <div className={styles.LPHeader}>
            {headers.map((headItem,headIndex)=>(<>
            <div className={styles.LPHeadElmnt}>
              {headItem}: {screenPlan.data[headItem][0]}
            </div>
            </>))}
          </div>
          <div className={styles.LPBody}>
            <>
            {/* LOOPING THROUGH SECTIONS */}
              {body.map((property,subIndex)=>(<>
                <div className={styles.LPBlock} key={subIndex}>
                  <div className={styles.LPMsg} key={subIndex}>
                    <span>
                      <h1>
                      {property}
                      </h1>
                      {isEdit && <img src={edit_icon}/>}
                    </span>
                    <ul>
                      {/* SECTION WISE POINTS */}
                      {screenPlan.data[property].map((point,pointIndex)=>(
                        <li
                        className={styles.liPoint}
                        onDoubleClick={()=>setIsEdit(true)}
                        onClick={()=>handleLiClick(property,point,pointIndex)} 
                        contentEditable={isEdit}
                        onBlur={()=> handleContentChange(property,pointIndex,pointValue)}
                        onKeyDown={(e) => {
                          if(e.key === "Enter"){
                            e.preventDefault();
                            e.target.blur(); 
                          }
                        }}
                        key={pointIndex}>
                          
                          {point}

                          {/* EDIT BUTTONS */}
                          {isEdit && editLi[0] === property && editLi[1] === pointIndex && (<>
                          <div className={styles.editLiBtns}>
                            {/* TRIGGERS SUGGEST POPUP */}
                            <button className={styles.liBtn} onClick={()=>setSuggestEdit(property)}><img alt="Logo button"src={logo}/></button>
                            {/* UNDOES SUGGEST POPUP(MANUAL EDITING) */}
                            <button className={styles.liBtn} onClick={()=>setSuggestEdit(null)}><img alt="Manual edit button" src={edit_icon}/></button>
                          </div>

                          {/* SUGGEST TEXT POPUP */}
                          {suggestEdit &&  suggestEdit === property &&(<>
                          <div className={styles.suggestPopup}>
                              <textarea value={pointValue} rows="2" 
                              disabled  //DISABLED PERMANENTLY,MAY BE CHANGED LATER
                              //onChange is irrelevant for now,text area is diabled anyway
                              onChange={(e)=> setPointValue(e.target.value)}/>

                              {/* BUTTONS TO GENERATE DIFFERENT POINTS */}      {/* FIND A DIFFERENT WAY TO MAKE CONTENT EDITABLE */}
                              <div className={styles.suggestPopupBtns}>
                                <div className={`${styles.suggestPopupBtn} ${click === 'Explain' ? styles.active: ""}`} 
                                contentEditable={false}
                                onClick={()=> {getSuggestions("Explain");
                                                setClick("Explain")}}>
                                  <img src={explain}/>
                                  Explain

                                </div>
                                <div className={`${styles.suggestPopupBtn} ${click === "Suggest" ? styles.active: ""}`} 
                                contentEditable={false}
                                onClick={()=> {getSuggestions("Suggest");
                                                setClick("Suggest")}}>
                                  <img src={suggest}/>
                                  Suggest
                                </div>
                              </div>
                            
                              <div className={styles.responseTA}>
                                <textarea className={styles.suggestionTA}  
                                value={suggValue} 
                                onChange={(event)=>{event.preventDefault();
                                  setSuggValue(event.target.value)}} 
                                // DISABLED UNTIL THERES A VALUE PRESENT IN THE TEXT AREA
                                // disabled={!suggValue}
                                disabled
                                />

                              {/* REFRESH RESULTS BUTTON APPEARS WHEN THERE'S A RESULT TO SHOW */}
                              {suggValue && (<img src={refresh} alt='refresh results' onClick={()=> getSuggestions(click)}/>)}
                              </div>

                            {/* INSERT BUTTON APPEARS WHEN THERE'S A RESULT */}
                            {suggValue && (
                              <button onClick={()=>handleInsert(property,pointIndex,suggValue)} className={styles.suggestPopupBtn}>
                                <img src={insert}/>
                                Insert
                              </button>
                            )}
                          </div>
                          </>
                          )}
                          </>
                          )}
                        </li>
                      ))}
                    </ul>
                    {screenPlan.section_wise_images && (<>
                      <h1>Images selected: {screenPlan.section_wise_images[property].length}/2<br/></h1> 
                    <div className={styles.LPImgContainer}>
                    <div className={styles.LPImgOptns}>
                      {screenPlan.section_wise_images[property].map((img, iIndex) => (
                        <div className={styles.LPImgOptn}>
                          <img key={img} src={img} alt={`Image ${iIndex + 1}`} />
                        </div>
                      ))}
                      <div className={styles.newImgBtn} onClick={()=>setImgPopup(property)}> + </div>
                      </div>
                    </div>
                    </>)}
                  </div>
                </div>
              </>))}
              {screenPlan.videos ? (
                <div className={styles.LPBlock}>
                <div className={styles.LPMsg}>
                  <h1>References</h1>
                    <Carousel className="LPCarousel" interval={null}>
                      {screenPlan.videos.map((video, vindex) => (
                        <Carousel.Item key={`video-${vindex}`}>
                          <div className="media-container">
                            <iframe
                              title={`Video ${vindex + 1}`}
                              width="100%"
                              height="550"
                              src={`https://www.youtube.com/embed/${video.video_id}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                </div>
                </div>
              ) : (
                ""
              )}
            </>
          </div>
          <div className={styles.LPFooter}>
            <button className={`${styles.footerBtn}`}
            onClick={() => setExpLP(null)}>
              {/* &times; */}
              Close
            </button>
            <button className={`${styles.footerBtn} ${previewMode ? styles.active : ""}`}
            onClick={() => setPreviewMode(true)}>
                Preview
            </button>
            <button
              onClick={() => {
                setIsEdit((prevIsEdit) => !prevIsEdit);
              }}
              className={`${styles.footerBtn} ${isEdit ? styles.active : ""}`}
              // style={{ border: isEdit ? "1px solid #jnk" : "" }}
            >
              Edit
              {/* {isEdit ? "Cancel Edit" : "Edit"} */}
            </button>
            <button className={`${styles.footerBtn}`} onClick={() => setFinalPopup(true)}>
              Save
            </button>
          </div>
        </div>

        {/* FINAL POPUP BEFORE LESSON PLAN IS SAVED */}
        {finalPopup &&(<div className={styles.finalPopupWrapper}>
          <div className={styles.finalPopupContainer}>
            <h1>Are you sure?</h1>
            <h2>Once Saved,<br/>Lesson Plans cannot be edited</h2>
            <div className={styles.finalBtns}>
              <button onClick={()=>(saveClick())}>
              Yes
              </button>
              <button onClick={()=> setFinalPopup(false)}>
              No
              </button>
            </div>
            
          </div>
          </div>
          )}
        
        {/* PREVIEW POPUP BEFORE LESSON PLAN IS DOWNLOADED */}
        {previewMode && (<LessonPlanDownload plan={screenPlan} closePreview={setPreviewMode}/>)}

        {/* Image Selector Popup */}
        {imgPopup && (<>
        <div className={styles.imgPopupContainer}>
          {!searchedImages &&(<div className={styles.teacherPH}>
                Search for Images
          </div>)}
          <div className={styles.imgPopupHead}>
            <h1> {imgPopup} </h1>
            {screenPlan.data[imgPopup].map((point,pointIndex)=>(
                        <li 
                        key={pointIndex}>
                          {point}
                        </li>
                      ))}
            <form className={styles.imgSearchInput} onSubmit={(event)=>findImages(event)}>
              <input name="imgSearch" placeholder="Search for Images!"></input>
              <button type="submit">Search</button>
            </form>
          </div>
          <div className={styles.currentImgShow}>
            {screenPlan.section_wise_images[imgPopup].map((img,index)=>(
              <div className={styles.currentImg}>
                <img src={img} alt={"Image"+index}/>
                <span onClick={()=> handleRemoveImg(index)}>&times;</span>
              </div>
            ))}
          </div>
          <hr/>
          <div className={styles.imgPopupBody}>
            {/* <div className={styles.imgDisplay}>
            {screenPlan.section_wise_images[{imgPopup}].map((img,imgIndex)=>(<>

            </>))}   
            </div>
            <hr/> */}
            {searchedImages && searchedImages.map((img,imageIndex)=>(<>
            <div key={imageIndex} className={styles.imgPopupOptn} onClick={()=>handleImgAdd(img)}>
              <img key={img} src={img}/>
            </div>
            </>))}
          </div>
           <button className={styles.imgPopupClose} onClick={()=> setImgPopup(null)}> x </button> 
        </div>
        </>)}
      </motion.div>
    </>
  );
}
