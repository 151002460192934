import React from 'react';
import styles from './LoadAnim.module.css'; // Import CSS for styling loading animation (adjust as needed)
import loading from "../Assets/load.gif"

const LoadingAnimation = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingAnimation}>
        <img src={loading}/>
      </div>
    </div>
  );
};

export default LoadingAnimation;
