import React, { useState, useRef, useEffect } from "react";
import { useTeacherContext } from "./TeacherContext";
import { useGlobal } from "../../context/GlobalContext";
import styles from "./Teacher.module.css"

export default function FeedbackForm(props) {

  //Global Context
  const { UID, CSRFToken } = useGlobal();

  //Teacher context states
  const { CurrentLang } = useTeacherContext();

  const [selectedOption, setSelectedOption] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [starOver, setStarOver]=useState(null);

  const handleSubmit = async () => {
    const feedbackData = {
      category: selectedOption,
      text: feedbackText,
      image: selectedFile,
      rating: selectedRating,
    };

    function handleSubmit() {
      // Reset the form
      document.getElementById("feedback_main").reset();
    }

    const formData = new FormData();
    formData.append("category", feedbackData.category);
    formData.append("text", feedbackData.text);
    if (selectedFile) {
      const base64String = await convertFileToBase64(selectedFile);
      formData.append("image", base64String); // Use "image" instead of "myfile"
    }
    formData.append("rating", feedbackData.rating);

    const url = `${process.env.REACT_APP_BACKEND_SERVER}/feedback`;

    try {
      const response = await fetch(url, {
        mode: "cors",
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
        SameSite: "None",
      });

      const responseJson = await response.json();
      // Handle the response as needed
    } catch (error) {
      console.error("An error occurred while submitting feedback:", error);
      alert("Server timed out. Please try again later!");
    }
    setSelectedOption(""); // Reset the selected option
    setFeedbackText(""); // Reset the feedback text
    setSelectedFile(null); // Reset the selected file
    setSelectedRating(""); // Reset the selected rating
  };

  // const csrfToken = await getCsrfToken();

  // if (csrfToken) {
  //   await handleSubmit(feedbackData, csrfToken);
  // } else {
  //   console.error("CSRF token is not available.");
  // }
  //   };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }

    if (file) {
      // Display an alert when an image is selected
      alert("Image uploaded!");
    }
  };

  //     if (isModalOpen) {
  //         window.addEventListener('click', handleModalClick);
  //     } else {
  //         window.removeEventListener('click', handleModalClick);
  //     }

  //     return () => {
  //         window.removeEventListener('click', handleModalClick);
  //     };
  // }, [isModalOpen]);
  return (
    <>
      <div id={styles.feedback_main}>
        <div id={styles.message_feed_one} className={styles.fb_elmnt}>
          <b>{CurrentLang.TellUs}</b>
        </div>
        <div id={styles.select_feedback} className={styles.fb_elmnt}>
          <div id={styles.message_feed_two}>{CurrentLang.FeedQues}</div>
          <div className={styles.options_group}>
            <button
              className={`${styles.select_feedback} ${
                selectedOption === "Lesson Plan" ? "active" : ""
              }`}
              tabIndex="0"
              onClick={() => setSelectedOption("Lesson Plan")}
            >
              Lesson Plan
            </button>
            <button
              className={`${styles.select_feedback} ${
                selectedOption === "Questions" ? "active" : ""
              }`}
              tabIndex="1"
              onClick={() => setSelectedOption("Questions")}
            >
              Questions
            </button>
            <button
              className={`${styles.select_feedback} ${
                selectedOption === "Groups" ? "active" : ""
              }`}
              tabIndex="2"
              onClick={() => setSelectedOption("Groups")}
            >
              Groups
            </button>
            <button
              className={`${styles.select_feedback} ${
                selectedOption === "Community" ? "active" : ""
              }`}
              tabIndex="3"
              onClick={() => setSelectedOption("Community")}
            >
              Community
            </button>
            <button
              className={`${styles.select_feedback} ${
                selectedOption === "Bug" ? "active" : ""
              }`}
              tabIndex="4"
              onClick={() => setSelectedOption("Bug")}
            >
              Bug
            </button>
            <button
              className={`${styles.select_feedback} ${
                selectedOption === "General" ? "active" : ""
              }`}
              tabIndex="5"
              onClick={() => setSelectedOption("General")}
            >
              General
            </button>
          </div>
        </div>
        <div id={styles.feedback_text} className={styles.fb_elmnt}>
          <div id={styles.message_feed_three}>{CurrentLang.PlzTell}</div>
          <div id={styles.text_area}>
            <textarea
              id={styles.my_text}
              rows="1"
              placeholder={CurrentLang.MaxChar}
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div id={styles.feedback_upload} className={styles.fb_elmnt}>
          <div id={styles.message_feed_four}>{CurrentLang.AddAttach}</div>
          <div className={styles.upload_btn_wrapper}>
            <button type="submit" className={styles.ul_btn} name="submit">
              {CurrentLang.Upload}
            </button>
            <input
              type="file"
              name="myfile"
              id={styles.myfileInput}
              className={styles.fileInp}
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className={styles.fb_elmnt}>
          {CurrentLang.PlzRate}
          <div className="rate">
              <input type="radio" id="star1" name="rate" value="1" onClick={() => setSelectedRating(1)} />
              <label className={starOver >= 1 || selectedRating >= 1 ? 'hovered':''} htmlFor="star1" title="text" onMouseEnter={()=>setStarOver(1)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
              <input type="radio" id="star2" name="rate" value="2" onClick={() => setSelectedRating(2)} />
              <label className={starOver >= 2 || selectedRating >= 2 ? 'hovered':''} htmlFor="star2" title="text" onMouseEnter={()=>setStarOver(2)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
              <input type="radio" id="star3" name="rate" value="3" onClick={() => setSelectedRating(3)} />
              <label className={starOver >= 3 || selectedRating >= 3 ? 'hovered':''} htmlFor="star3" title="text" onMouseEnter={()=>setStarOver(3)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
              <input type="radio" id="star4" name="rate" value="4" onClick={() => setSelectedRating(4)} />
              <label className={starOver >= 4 || selectedRating >= 4 ? 'hovered':''} htmlFor="star4" title="text" onMouseEnter={()=>setStarOver(4)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
              <input type="radio" id="star5" name="rate" value="5" onClick={() => setSelectedRating(5)} />
              <label className={starOver >= 5 || selectedRating >= 5 ? 'hovered':''} htmlFor="star5" title="text" onMouseEnter={()=>setStarOver(5)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
          </div>
        </div>
        <div id={styles.feedback_submit} className={styles.fb_elmnt}>
          <button type="button" id={styles.feed_click} onClick={handleSubmit}>
            {CurrentLang.Submit}
          </button>
        </div>
      </div>
    </>
  );
}  