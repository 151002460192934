import React, { useState } from "react";
import dislike_icon from "./Assets/dislike-icon.png";
import like_icon from "./Assets/like-icon.png";
import "./Platform.css";

export default function Platform() {
    const [selectedStatement, setSelectedStatement] = useState(0);
    const [Statements, setStatements] = useState(["sun", "moon", "star", "dog", "cat", "boy", "girl", "earth", "venus"]);
    const [likeValue, setLikeValue] = useState(0);
    const [dislikeValue, setdislikeValue] = useState(0);
    const [commentValue, setCommentValue] = useState("");
    const commentbox = document.querySelector("#commentInput");

    const handleStatementClick = (statement) => {
        setSelectedStatement(statement);
    };

    const handleLike = (e) => {
        e.preventDefault();
        setLikeValue(1);
        setdislikeValue(0);
    };

    const handleDislike = (e) => {
        e.preventDefault();
        setLikeValue(0);
        setdislikeValue(1);
    };

    const handleCommentChange = (e) => {
        setCommentValue(e.target.value.trim());
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform any necessary actions with the comment and rating values
        // console.log("Comment:", commentValue);
        // console.log("Like Value:", likeValue);
        // console.log("Dislike Value:", dislikeValue);
        // postStatementFeedback(data);
        setCommentValue("");
        commentbox.value = "";
        // Move to the next sentence
        nextStatement();
    };

    const nextStatement = () => {
        setLikeValue(0);
        setdislikeValue(0);
        setCommentValue("");
        setSelectedStatement(selectedStatement + 1);
    };

    return (
        <div className="platform">
            <div className="statement-selector">
                {/* <h1>Tags</h1> */}
                <div className="statement-list">
                    {Array(Statements.length)
                        .fill(0)
                        .map((_, i) => i + 1)
                        .map((_, key) => (
                            <div key={key} className={`statement ${selectedStatement === key ? "selected" : ""}`} onClick={() => handleStatementClick(key)}>
                                {key + 1}. {Statements[key]}
                            </div>
                        ))}
                </div>
            </div>
            <div className="selected-statement">
                <h2>{Statements[selectedStatement]}</h2>
                <form className="rating" onSubmit={handleSubmit}>
                    <button id="likeBtn" className={`like-btn ${likeValue === 1 ? "btn-active" : ""}`} onClick={handleLike}>
                        <img src={like_icon} alt="Like" className="feedback-icons" />
                    </button>
                    <button id="dislikeBtn" className={`dislike-btn ${dislikeValue === 1 ? "btn-active" : ""}`} onClick={handleDislike}>
                        <img src={dislike_icon} alt="Dislike" className="feedback-icons" />
                    </button>
                    <div className="comment">
                        <input type="text" id="commentInput" placeholder="Enter your comment" onChange={handleCommentChange} />
                        <button type="submit" id="submitBtn" className="submit-btn">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
