import React from "react";
import { Navigate } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";

export default function ProtectedRoute({ children }) {
    const { user } = useGlobal();
    // Navigate to login if there is no user
    if (!user) {
        return <Navigate to="/login" />;
    } else {
        return children;
    }
}
