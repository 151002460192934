import { React, createContext, useContext, useEffect, useState } from "react";
import { useGlobal } from "../../context/GlobalContext";
import language from "../../Locale/language-data.json";

// Create chatbot context
const ChatbotContext = createContext(null);

export const ChatbotProvider = ({ children }) => {
  // Global context states
  const { WebAppLocale } = useGlobal();

  // Set states
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );
  const [CurrentLang, setCurrentLang] = useState(language[WebAppLocale]);
  const [ChatMode, setChatMode] = useState("Ask");
  const [Subject, setSubject] = useState("Science");
  const [MsgLoading, setMsgLoading] = useState(false);
  const [IsAnimationRenderedOnce, setIsAnimationRenderedOnce] = useState(false);

  const [turn, setTurn] = useState(0);
  const [InputAllowed, setInputAllowed] = useState(true);
  const [input, setInput] = useState("");
  const [scienceMessages, setScienceMessages] = useState([]);

  const [scienceMessagesT, setScienceMessagesT] = useState([]);
  const [mathsMessagesT, setMathsMessagesT] = useState([]);

  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [CurrentImageId, setCurrentImageId] = useState(0);
  const [currentConvId, setCurrentConvId] = useState(0);
  const [currentImageData, setCurrentImageData] = useState([]);

  const [refData, setRefData] = useState({});

  const [leaderboardStats, setLeaderboardStats] = useState();

  //Voice Button/Send Button
  const [isFocused, setIsFocused] = useState(false);

  //BOOKS SECTION CONTEXT
  const [bookData, setBookData] = useState({
    board: "NCERT",
    grade: "6",
    subject: "science",
    chapter: null,
    topic: null,
  });

  // Utility functions
  const scrollToBottom = () => {
    // Code to scroll .chat-display to bottom
    setTimeout(() => {
      document.querySelector(".chat-display").scrollTop = document.querySelector(".chat-display").scrollHeight;
    }, 150);
  };

  // Update current time
  const updateTime = () => {
    setCurrentTime(
      new Date().toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };

  // Image carousel modal
  // Make images full screen on click
  const handleImgClick = (key) => {
    // console.log("make img fullscreen - ", key);
    setCurrentImageId(key);
    setIsImgModalOpen(true);
  };

  // Close image modal
  const handleModalClose = () => {
    setIsImgModalOpen(false);
  };

  useEffect(() => {}, [isFocused]);

  const values = {
    Subject,
    setSubject,
    ChatMode,
    setChatMode,
    CurrentLang,
    setCurrentLang,
    IsAnimationRenderedOnce,
    setIsAnimationRenderedOnce,
    scrollToBottom,
    MsgLoading,
    setMsgLoading,
    turn,
    setTurn,
    InputAllowed,
    setInputAllowed,
    input,
    setInput,
    updateTime,
    currentTime,
    scienceMessagesT,
    setScienceMessagesT,
    mathsMessagesT,
    setMathsMessagesT,
    setIsImgModalOpen,
    isImgModalOpen,
    handleImgClick,
    handleModalClose,
    CurrentImageId,
    setCurrentImageId,
    currentConvId,
    setCurrentConvId,
    currentImageData,
    setCurrentImageData,
    refData,
    setRefData,
    leaderboardStats,
    setLeaderboardStats,
    scienceMessages,
    setScienceMessages,
    isFocused,
    setIsFocused,

    //BOOKS mode context variables
    bookData,
    setBookData,
  };
  return <ChatbotContext.Provider value={values}>{children}</ChatbotContext.Provider>;
};

export default ChatbotContext;

export function useChatbotContext() {
  return useContext(ChatbotContext);
}
