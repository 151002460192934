import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Components/About";
import Chatbot from "./Components/Chatbot/Chatbot-v2";
import Home from "./Components/Home";
import Login from "./Components/Login";
import Platform from "./Components/Platform";
import ProtectedRoute from "./Components/ProtectedRoute";
import Signup from "./Components/Signup";
import Signupv2 from "./Components/Signup-v2";
import LPCOPY from "./Components/LPCOPY"
import { GlobalContextProvider } from "./context/GlobalContext";
import { ChatbotProvider } from "./Components/Chatbot/ChatbotContext";

import Teacher from "./Components/Teacher/Teacher";
import { TeacherProvider } from "./Components/Teacher/TeacherContext";


function App() {
    return (
        // Router used to change endpoints
        <BrowserRouter>
            {/* Global context that provides context to all the children */}
            <GlobalContextProvider>
                <Routes>
                    {/* Routes used to change endpoints */}
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/about" element={<About />} />
                    <Route exact path="/login" element={<Login />} />
                    <Route exact path="/signup" element={<Signupv2 />} />
                    
                    {/* To test PDF rendering without needing to login everytime */}
                    {/* <Route exact path="/test" element= {<LPCOPY/>}/> */}
                    <Route
                        exact
                        path="/chat"
                        element={
                            // Protected route used to protect the Chatbot from being accessible directly
                            <ProtectedRoute>
                                <ChatbotProvider>
                                    <Chatbot />
                                </ChatbotProvider>
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        exact
                        path="/teach"
                        element={
                            // Protected route used to protect the Teacher section from being accessible directly
                            <ProtectedRoute>
                                <TeacherProvider>
                                    <Teacher />
                                </TeacherProvider>
                            </ProtectedRoute>
                        }
                    />
                    <Route exact path="/platform" element={<Platform />} />
                </Routes>
            </GlobalContextProvider>
        </BrowserRouter>
    );
}

export default App;
