import React, { useState, useEffect } from "react";
import fox from "../Assets/Profile/fox.png";
import { useGlobal } from "../../context/GlobalContext";
import earth from "../Assets/Leaderboard/earth.png";
import star from "../Assets/Leaderboard/star.png";
import trophy from "../Assets/Leaderboard/trophy.png";
import crystal from "../Assets/Leaderboard/crystal.png";
import camera from "../Assets/Profile/camera.png";
import signout from "../Assets/Profile/signout.png";
import { Link, useNavigate } from "react-router-dom";
import { useTeacherContext } from "./TeacherContext";
import styles from "./Teacher.module.css";
// import { useHistory } from 'react-router-dom';

export default function Profile(props) {
  const { CurrentLang } = useTeacherContext();
  const { setProfession,UID, CSRFToken, logout } = useGlobal();
  const navigate = useNavigate();
  const [ranks, setRanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user_data, setUser_data] = useState({});

  useEffect(() => {
    fetchUserInfo();
  }, [UID]);

  const fetchUserInfo = () => {
    fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/get_user_details`, {
      headers: {
        Authorization: `Bearer ${UID}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("API Response:", data); // Log the API response for debugging
        if (data.status) {
          setUser_data(data.user_details);
        } else {
          console.error("API Error:", data.Reason); // Log API errors
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleLogout = (e) => {
    // e.preventDefault();
    setProfession("");
    logout();
    navigate("/login");
  };

  return (
    <>
      <div id={styles.profile_content}>
        <div id={styles.pic}>
          <div id={styles.image_container}>
            {user_data.profile_pic ? (
              <img
                src={`data:image/png;base64,${user_data.profile_pic}`}
                alt="User Profile"
                id={styles.image}
              />
            ) : (
              <img src={fox} alt="Default Fox" id={styles.image} />
            )}
            <button id={styles.upload_button}>
              <img
                src={camera}
                alt="Upload Icon"
                width="22px"
                draggable="false"
              />
            </button>
          </div>
        </div>
  
        <div id={styles.user_name}>{`${user_data.name}`}</div>
  
        <div id={styles.user_details}>
          {`${user_data.username} / ${user_data.class_of_study}`} {CurrentLang.Class}
        </div>
        
        <div id={styles.logout}>
          <img src={signout} alt="Signout Icon" />
          <button onClick={() =>handleLogout()} className={styles.logout_button}>
            {CurrentLang.Logout}
          </button>
        </div>
      </div>
    </>
  );
            }  