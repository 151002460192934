import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import language from "../../Locale/language-data.json";
import { useGlobal } from "../../context/GlobalContext";
import logo from "./../Assets/LogoWhiteBg.png";

//Student side components
import Profile from "./../Chatbot/Profile";
import Leaderboard from "./../Chatbot/Leaderboard";
import FeedbackForm from "../Chatbot/FeedbackForm";

//Teacher side components
import ProfileTeacher from "../Teacher/Profile";
import FeedbackTeacher from "../Teacher/FeedbackForm";
import SavedPlans from "../Teacher/SavedPlans";
import Groups from "../Teacher/Groups";
import Assessments from "../Teacher/Assessments"

export default function HNavbar(props) {
  const { Profession, WebAppLocale, setWebAppLocale } = useGlobal();

  const [CurrentLang, setCurrentLang] = useState(language[WebAppLocale]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  //set the initial component in the side view
  const [activeSideWindow,setActiveSideWindow]=useState(Profession === 'student' ? "profile":"groups");

  const navigate = useNavigate();

  let mediaQuery = window.matchMedia("(max-width: 65rem)");
  if (props.currentPage === 'chatbot' || props.currentPage === 'teacher') {
        mediaQuery = window.matchMedia("(max-width: 65rem)");
    }

  const handleLanguageChange = (eventKey) => {
    setWebAppLocale(eventKey);
    setCurrentLang(language[eventKey]);
  };

  return (
    // ${props.currentPage === "chatbot" ? `cb`: props.currentPage === "teach" ? "tchr" : ""} // ADD LATER
    <div id={`hh-nav`} className={`${props.currentPage === 'teacher' ? "teachernav": "chatbotnav" }`}>
      {/* <Navbar className={`${props.class} start-0 end-0 top-0 px-4`} expand={props.currentPage === "chatbot" ? "lg" : "md"}> */}
      {/* Offcanvas toggle button on navbar */}
      <Link to="/"><img src={logo} alt="Header Logo" id="logo-head" draggable="false"/></Link>
      
      <NavDropdown className="right-lang" title={CurrentLang[WebAppLocale]} onSelect={handleLanguageChange}>
        <NavDropdown.Item draggable="false" value="English" eventKey={"English"}>
            {CurrentLang.English}
        </NavDropdown.Item>
        <NavDropdown.Item draggable="false" value="Hindi" eventKey={"Hindi"}>
            {CurrentLang.Hindi}
        </NavDropdown.Item>
        <NavDropdown.Item draggable="false" value="Marathi" eventKey={"Marathi"}>
            {CurrentLang.Marathi}
        </NavDropdown.Item>
        <NavDropdown.Item draggable="false" value="Bengali" eventKey={"Bengali"}>
            {CurrentLang.Bengali}
        </NavDropdown.Item>
        <NavDropdown.Item draggable="false" value="Telugu" eventKey={"Telugu"}>
            {CurrentLang.Telugu}
        </NavDropdown.Item>
      </NavDropdown>

      <Navbar.Toggle aria-controls="offcanvasMenu-expand-md" className="hh-element" id="nav-close" onClick={()=>setIsMenuOpen(true)}>
      
        <div className={`menu_btns ${props.currentPage === 'teacher' ? "teacher":"student"}`} />
      </Navbar.Toggle>
      {/* The navigation links on the left side of the navbar */}
      <Navbar.Offcanvas
        show={isMenuOpen}
        className={`${props.currentPage}-canvas`}
        onHide={()=>setIsMenuOpen(false)}
        id={`offcanvasLeaderboard-expand-md`}
        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
        placement="start"
      >
        <Offcanvas.Header closeButton>
        <div id="menu_btns">
        {mediaQuery.matches && props.currentPage === "chatbot" ? (
          <>
            <div className="flex flex-row w-100">
                <button type="click" id="profile-button" onClick={()=> setActiveSideWindow("profile")} className={`header-button ${activeSideWindow === "profile" ? "active" : ""}`}>
                  Profile
                </button>
                <button type="click" id="feedback-button" onClick={()=> setActiveSideWindow("feedback")} className={`header-button ${activeSideWindow === "feedback" ? "active" : ""}`}>
                  Feedback
                </button>
            </div>
          
            <div id="leaderboard_button">
              <button type="click" id="leaderboard-button" onClick={()=> setActiveSideWindow("leaderboard")} className={`header-button ${activeSideWindow === "leaderboard" ? "active" : ""}`}>
                <div id="message-feed">Leaderboard</div>
              </button>
            </div>
          </>
          ) : mediaQuery.matches && props.currentPage === "teacher" ? (
            <>
            <div className="flex flex-row w-100">
                <button type="click" id="profile-button" onClick={()=> setActiveSideWindow("profile")} className={`header-button ${activeSideWindow === "profile" ? "active" : ""}`}>
                  Profile
                </button>
              <button type="click" id="feedback-button" onClick={()=> setActiveSideWindow("feedback")} className={`header-button ${activeSideWindow === "feedback" ? "active" : ""}`}>
                Feedback
              </button>
            </div>
            <div>
              <button type="click" id="feedback-button" onClick={()=> setActiveSideWindow("groups")} className={`header-button ${activeSideWindow === "groups" ? "active" : ""}`}>
              Groups
              </button>
              <button type="click" id="feedback-button" onClick={()=> setActiveSideWindow("saved")} className={`header-button ${activeSideWindow === "saved" ? "active" : ""}`}>
              Saved Plans
              </button>
              <button type="click" id="feedback-button" onClick={()=> setActiveSideWindow("assessments")} className={`header-button ${activeSideWindow === "assessments" ? "active" : ""}`}>
              Assessments
              </button>
            </div>
            <div>
              
            </div>
            </>
          ):""}
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="sidebar-item">
          {props.currentPage === "chatbot" ? (<>
            {activeSideWindow === "profile" ? (<>
              <Profile isProfileOpen={true} />
            </>): activeSideWindow === "feedback" ?(<>
              <FeedbackForm/>
            </>): activeSideWindow === "leaderboard" &&(<>
              <Leaderboard/>
            </>)}
          </>
          ): props.currentPage == "teacher" && (<>
          {activeSideWindow === "profile" ? (<>
              <ProfileTeacher isProfileOpen={true} />
            </>): activeSideWindow === "feedback" ?(<>
              <FeedbackTeacher/>
            </>): activeSideWindow === "groups" ?(<>
              <Groups/>
            </>): activeSideWindow === "assessments" ?(<>
              <Assessments/>
            </>):activeSideWindow === "saved" && (<>
              <SavedPlans/>
            </>)}
          </>)}
          </div>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </div>
  );
}
