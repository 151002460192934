import React, { useEffect, useRef, useState } from "react";
import language from "../../Locale/language-data.json";
import { useGlobal } from "../../context/GlobalContext";
import { useChatbotContext } from "./ChatbotContext";
// import sampleResponseJson from "../../sample/sample_chatbot_response.json";
import { AnimatePresence } from "framer-motion";
import "./Chatbot-v2.css";
import ImageCarouselModal from "./ImageCarouselModal";
import Message from "./Message";
import Spinner from "./Spinner";

import Books from "./Books.js";
import Input from "./Input";
import ChatbotLeftFrame from "./ChatbotLeftFrame";
import ChatbotRightFrame from "./ChatbotRightFrame";
import Navbar from "./..//Navigation//Navigation.js"
import HNavbar from "../Navigation/HNavbar.js"
import Settings from "../Settings.js";

const NavChatbotTheme = React.lazy(() => import("../Navigation/ThemeCSS/NavChatbotTheme"));

export default function Chatbot(props) {
    // Chatbot context states
    const {scienceMessages, setScienceMessages,bookData,currentConvId, currentImageData, CurrentImageId, handleModalClose, isImgModalOpen, scrollToBottom, CurrentLang, ChatMode, Subject, setIsAnimationRenderedOnce, IsAnimationRenderedOnce, setCurrentLang, setInputAllowed, MsgLoading, setMsgLoading, input, setInput, updateTime, currentTime ,refData ,setRefData,isFocused,setIsFocused} = useChatbotContext();

    // Global context states
    const { showSettings,setShowSettings,UID, setImageLikeDislikes, imageLikeDislikes, WebAppLocale, getCSRFtoken, CSRFToken,translator} = useGlobal();

    // State variables
    const [mathsMessages, setMathsMessages] = useState([]);
    
    // Dynamic media query
    const mediaQuery = window.matchMedia("(max-width: 61.99rem)");

    //Variables for closing read-mores
    const contRef = useRef(null);

    //Handling clicks outside of the ref box for Readmore
    useEffect(()=> {
        const handleCloseReadMore = (event) => {
            if(contRef.current 
                && !contRef.current.contains(event.target))
            {
                setRefData({});
            }
        };
        document.addEventListener('click', handleCloseReadMore);
        return () => {
            document.removeEventListener('click', handleCloseReadMore);
        };
    }, []);

    // Handle user input
    const handleUserInput = (event) => {
        event.preventDefault();
        scrollToBottom();

        setIsAnimationRenderedOnce(false);
        const text = input;
        updateTime();
        if (text.trim() !== "") {
            if (Subject === "Science") {
                setScienceMessages((prevMessages) => [...prevMessages, { text: text, sender: "User", related_image_data: [], time: currentTime ,source:{} }]);
            } else if (Subject === "Maths") {
                setMathsMessages((prevMessages) => [...prevMessages, { text: text, sender: "User", related_image_data: [], time: currentTime ,source:{}}]);
            }

            // ENSURE THIS IS ON FOR PRODUCTION ↓
            setTimeout(() => {
                // postUserMessage(text);
                postUserMessage(text);
            }, 1000);
            // simulateChatbotResponse(text);
        }
        setInput("");
        setTimeout(() => {
            document.querySelector('#user-input input[type="text"]').focus();
        }, 50);
    };

    // Post user message to the server
    const postUserMessage = async (text) => {
        setInputAllowed(false);
        const data = {
            question: text,
        };
        let form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/new_chat`;
        try {
            setMsgLoading(true);
            const response = await fetch(url, {
                mode: "cors",
                method: "POST",
                body: form_data,
                headers: { Authorization: `Bearer ${UID}`, "X-CSRFToken": `${CSRFToken}` },
                credentials: "include",
                SameSite: "None",
            });
            const responseJson = await response.json();
            // translator(responseJson.response.text,"en","hi");
            console.log(responseJson);
            
            if (responseJson) {
                await getChatbotMessage(responseJson);
            }
            setInputAllowed(true);
            setMsgLoading(false);
        } catch (error) {
            console.error(error);
            alert("Server timed out please try again in some time! 🙂");
            setInputAllowed(true);
            setMsgLoading(false);
        }
    };

    // Get chatbot response from the server
    const getChatbotMessage = async (responseJson) => {
        updateTime();
        
        responseJson.response = await preProcessResponse(responseJson);
        if (Subject === "Science") {
            setScienceMessages((prevMessages) => [...prevMessages, { convId: responseJson.saved_id, text: responseJson.response.text, sender: "YoZu", media: responseJson.response.media, time: currentTime ,source:responseJson.response.source, entities:responseJson.response.entities, mainent:responseJson.readmore_entities, }]);   
        }
        setTimeout(() => {
            document.querySelector('#user-input input[type="text"]').focus();
        }, 50);

        // Store image like / dislike data in global context
        let data = responseJson.response.media || []; 
        let ndata = {};
        data.forEach((image_data) => {
            ndata[image_data.image_id || image_data.video_id] = {
                is_disliked: image_data.is_disliked || false,
                is_liked: image_data.is_liked || false,
            };
        });
        let local_imageLikeDislikes = imageLikeDislikes;
        local_imageLikeDislikes[responseJson.saved_id + "conv"] = ndata;
        setImageLikeDislikes(local_imageLikeDislikes);
    };

    // Simulate chatbot response | Testing and development of API connection purposes only
    //Based on the new_chat responses
    // const simulateChatbotResponse = (message) => {
    //     updateTime();
    //     // if (Subject === "Science") {
    //     //     setScienceMessages((prevMessages) => [...prevMessages, { text: message, sender: "User", related_image_data: [], time: currentTime }]);
    //     // } else if (Subject === "Maths") {
    //     //     setMathsMessages((prevMessages) => [...prevMessages, { text: message, sender: "User", related_image_data: [], time: currentTime }]);
    //     // }
    //     setMsgLoading(true);
    //     setTimeout(() => {
    //         const response = sampleResponseJson;
    //         getChatbotMessage(response);
    //         setMsgLoading(false);
    //     }, 500);
    // };

    const preProcessResponse = async (responseJSON) => {
        let processedResponse = responseJSON.response;
    
        // Injecting placeholders
        const datetime = new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
        let text = processedResponse.text;
        text = text.replace("__CURRENT_TIME__", datetime);
        processedResponse.text = text;
    
        // Blocking some images
        let images = responseJSON.related_image_data;
        let index = images.findIndex((image) => image.image_url === "https://upload.wikimedia.org/wikipedia/commons/4/4a/Commons-logo.svg");
        images.splice(index, 1);

        processedResponse.media = [];
        // Injecting youtube results in carousel list if videos available
        if (responseJSON.video_list) {
            const ytVids = [];
            responseJSON.video_list.forEach((item) => {
                ytVids.unshift({
                    video_id: item.Link,
                    // thumbnail: item.thumbnail,
                    is_liked:false,
                    is_disliked:false,
                });
            });
            
            //ADD VIDEOS FIRST THEN IMAGES IN MEDIA ARRAY
            let med = responseJSON.related_image_data;
            med = ytVids.concat(med);
    
            processedResponse.media = processedResponse.media.concat(med);
            // console.log(responseJSON.video_list);

        }
        return processedResponse;
    };
    

    useEffect(() => {
        setCurrentLang(language[WebAppLocale]);
    }, [WebAppLocale]);

    useEffect(() => {
        getCSRFtoken();
    }, []);

    return (
        <>
            {/* Navigation */}
            <React.Suspense fallback={<></>}>
                <NavChatbotTheme />
            </React.Suspense>
    
            <div id="app-frame">
                {/* <Navbar
          navdata={props.navdata}
          currentPage={"chatbot"}
          class={"scrollnav navbar-light"}
            />  */}
                

                <HNavbar 
                class={"scrollnav navbar-light"}
                navdata={props.navdata}
                currentPage={"chatbot"} />


                {/* Settings Popup */}
                {/* DISABLED TEMPORARILY TILL MORE SETTINGS ARE AVAILABLE */}
                {/* <AnimatePresence>{showSettings && <Settings/>}</AnimatePresence> */}
                
                {/* Render image carousel when enlarged */}
                <AnimatePresence>{isImgModalOpen && <ImageCarouselModal handleModalClose={handleModalClose} currImageId={CurrentImageId} convId={currentConvId} media={currentImageData} />}</AnimatePresence>

                <ChatbotLeftFrame />
                <div id="chatbot-frame">

                    {/* Chatbot Section */}
                    <div id="chat-section">
                        <div id="title-bar">
                            {ChatMode}
                            {ChatMode === "Books" && (<>
                                <div className="book-details">{bookData.chapter ? `/${bookData.chapter}`: ""}</div>
                                <div className="book-details">{bookData.topic ? `/${bookData.topic}`: ""}</div>
                            </>)}                            
                            {/* {ChatMode === "Books" && `${bookData.chapter ? `/${bookData.chapter}`:""}${bookData.topic ? `/${bookData.topic}` : ""}`} */}
                        </div>
                        {/* READMORE EXPANDED SECTION */}
                        {Object.keys(refData).length !== 0 ? (<>
                        <div className="reference-container" >
                            <div className="ref-box" ref={contRef}>
                                {/* <button className="close-ref-button" onClick={()=>setRefData({})}>X</button> */}
                                {refData?.Board && (<h1>{/* <b>Board:</b> */}{refData?.Board}</h1>)}
                                {refData?.Grade && (<p><b>Grade:</b> {refData?.Grade}</p>)}
                                {refData?.Subject &&(<p><b>Subject:</b> {refData?.Subject}</p>)}
                                <h2>{refData?.Chapter}</h2>
                                <h3>{refData?.Topic}</h3>
                                <div className="text-content" dangerouslySetInnerHTML={{__html: refData.text}}/>
                            </div>
                        </div>
                        </>):""}

                        {ChatMode === "Ask" ? (
                            <>
                                <div className="chat-display">
                                    {Subject === "Science" ? scienceMessages.map((message, index) => <Message key={index} index={index} message={message} subject={Subject} messagesLength={scienceMessages.length} IsAnimationRenderedOnce={IsAnimationRenderedOnce} mainEnt={scienceMessages[index].mainent} />) : ""}
                                    {Subject === "Maths" ? mathsMessages.map((message, index) => <Message key={index} index={index} message={message} subject={Subject} messagesLength={mathsMessages.length} IsAnimationRenderedOnce={IsAnimationRenderedOnce} />) : ""}
                                    {MsgLoading && <Spinner />}
                                </div>
                                <Input handleUserInput={handleUserInput} inputAllowed={true}/>
                            </>
                        ) : ChatMode === "Practice" ? (
                            <>
                                <div className="chat-display">
                                    {MsgLoading && <Spinner />}
                                </div>
                                <Input handleUserInput={handleUserInput} inputAllowed={false}/>
                            </>
                        ) : ChatMode === "Books" && (
                            <>
                                <div className="books-container">
                                    <Books/>
                                </div>
                                <Input handleUserInput={handleUserInput} inputAllowed={false}/>
                            </>
                        )}

                        {/* {ChatMode === "Tutor" ? <Tutor /> : ""} */}
                    </div>
                </div>
                <ChatbotRightFrame />
            </div>
        </>
    );
}
