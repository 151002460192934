import React, { useEffect, useState } from "react";
import language from "../../Locale/language-data.json";
import { useGlobal } from "../../context/GlobalContext";
import { useTeacherContext } from "./TeacherContext";
import { AnimatePresence } from "framer-motion";
import LessonPlan from "./LessonPlan.js";

import TeacherCenterFrame from "./TeacherCenterFrame";
import TeacherRightFrame from "./TeacherRightFrame";
import TeacherLeftFrame from "./TeacherLeftFrame";
import HNavbar from "../Navigation/HNavbar.js";
import Navbar from "../Navigation/Navigation.js";

import styles from "./Teacher.module.css";

const NavChatbotTheme = React.lazy(() =>
  import("../Navigation/ThemeCSS/NavChatbotTheme")
);
export default function Teacher(props) {
  //Teacher context states
  const {
    expLP,
  } = useTeacherContext();
  
  return (
    <>
      <React.Suspense fallback={<></>}>
        <NavChatbotTheme />
      </React.Suspense>
      <div id={styles.appFrame}>

        {/* <Navbar
          navdata={props.navdata}
          currentPage={"teacher"}
          class={"scrollnav navbar-light"}
            />  */}
        <HNavbar
          class={"scrollnav navbar-light"}
          navdata={props.navdata}
          currentPage={"teacher"}
        />

        {/* {console.log(plans[expLP])} */}

        <AnimatePresence>
          {expLP != null && <LessonPlan plan={expLP} />}
        </AnimatePresence>
        <TeacherLeftFrame />
        <TeacherCenterFrame />
        <TeacherRightFrame />
      </div>
    </>
  );
}
