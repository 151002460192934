import React, { useState, useRef, useEffect } from "react";
import { useChatbotContext } from "./ChatbotContext";
import { useGlobal } from "../../context/GlobalContext";
import logo from "../Assets/logo-New-removebg-preview.png";

export default function FeedbackForm(props) {

    //Global Context
    const { UID, CSRFToken } = useGlobal();

    //Teacher context states
    const { CurrentLang } = useChatbotContext();

    const [selectedOption, setSelectedOption] = useState("");
    const [feedbackText, setFeedbackText] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedRating, setSelectedRating] = useState(null);
    const [starOver, setStarOver]=useState(null);

    const options = [
        "Response Time",
        "Bug",
        "Rewards",
        "Responses",
        "Points",
        "Leaderboard"
      ];

    const handleSubmit = async () => {
        const feedbackData = {
            category: selectedOption,
            text: feedbackText,
            image: selectedFile,
            rating: selectedRating,
        };

        const formData = new FormData();
        formData.append("category", feedbackData.category);
        formData.append("text", feedbackData.text);
        if (selectedFile) {
            const base64String = await convertFileToBase64(selectedFile);
            formData.append("image", base64String); // Use "image" instead of "myfile"
        }
        formData.append("rating", feedbackData.rating);

        const url = `${process.env.REACT_APP_BACKEND_SERVER}/feedback`;

        try {
            const response = await fetch(url, {
                mode: "cors",
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${UID}`,
                    "X-CSRFToken": `${CSRFToken}`,
                },
                credentials: "include",
                SameSite: "None",
            });

            const responseJson = await response.json();
            // Handle the response as needed
        } catch (error) {
            console.error("An error occurred while submitting feedback:", error);
            alert("Server timed out. Please try again later!");
        }
        
        setSelectedOption(""); 
        setFeedbackText(""); 
        setSelectedFile(null); 
        setSelectedRating(""); 
        document.getElementById("feedback_main").reset();
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(",")[1];
                resolve(base64String);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }

        if (file) {
            // Display an alert when an image is selected
            alert("Image uploaded!");
        }
    };

    return (
        <>
                <div id="feedback_main">
                    <div id="message_feed_one" className="fb-elmnt">{CurrentLang.TellUs}</div>
                    <div id="select-feedback" className="fb-elmnt">
                        <div id="message_feed_two">{CurrentLang.FeedQues}</div>
                        <div className="options-group">
                        {options.map((option, index) => (
                            <button
                            key={index}
                            className={`select-feedback ${(selectedOption === option) ? "active" : ""}`}
                            tabIndex={index}
                            onClick={() => setSelectedOption(option)}
                            >
                            {CurrentLang[option.replace(/\s+/g, '')]}
                            </button>
                        ))}
                        </div>
                    </div>
                    <div id="feedback-text" className="fb-elmnt">
                        <div id="message_feed_three">{CurrentLang.PlzTell}</div>
                        <div id="textarea">
                            <textarea id="my-text" rows="1" placeholder={CurrentLang.MaxChar} value={feedbackText} onChange={(e) => setFeedbackText(e.target.value)}/>
                        </div>
                    </div>
                    <div id="feedback-upload" className="fb-elmnt flex-row justify-content-between">
                        {CurrentLang.AddAttach}
                        <div className="upload-btn-wrapper">
                            <button type="submit" className="ul-btn" name="submit" onClick={()=>document.getElementById('myfileInput').click()}>
                            {CurrentLang.Upload}
                            </button>
                            <input type="file" name="myfile" id="myfileInput" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                        </div>
                    </div>
                    <div id="rateYoZu" className="fb-elmnt flex-row justify-content-between">
                        {CurrentLang.PlzRate}
                        <div className="rate">
                            <input type="radio" id="star1" name="rate" value="1" checked={selectedRating === 1} onClick={() => setSelectedRating(1)} />
                            <label className={starOver >= 1 || selectedRating >= 1 ? 'hovered':''} htmlFor="star1" title="text" onMouseEnter={()=>setStarOver(1)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
                            <input type="radio" id="star2" name="rate" value="2" checked={selectedRating === 2} onChange={() => setSelectedRating(2)} />
                            <label className={starOver >= 2 || selectedRating >= 2 ? 'hovered':''} htmlFor="star2" title="text" onMouseEnter={()=>setStarOver(2)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
                            <input type="radio" id="star3" name="rate" value="3" checked={selectedRating === 3} onChange={() => setSelectedRating(3)} />
                            <label className={starOver >= 3 || selectedRating >= 3 ? 'hovered':''} htmlFor="star3" title="text" onMouseEnter={()=>setStarOver(3)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
                            <input type="radio" id="star4" name="rate" value="4" checked={selectedRating === 4} onChange={() => setSelectedRating(4)} />
                            <label className={starOver >= 4 || selectedRating >= 4 ? 'hovered':''} htmlFor="star4" title="text" onMouseEnter={()=>setStarOver(4)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
                            <input type="radio" id="star5" name="rate" value="5" checked={selectedRating === 5} onChange={() => setSelectedRating(5)} />
                            <label className={starOver >= 5 || selectedRating >= 5 ? 'hovered':''} htmlFor="star5" title="text" onMouseEnter={()=>setStarOver(5)} onMouseLeave={()=>{if(selectedRating){setStarOver(null)}}}/>
                        </div>
                    </div>
                    <div id="feedback-submit">
                        <button type="button" id="feed-click" onClick={handleSubmit}>
                            {CurrentLang.Submit}
                        </button>
                    </div>
                </div>
        </>
    );
}
