import React, { useEffect, useState } from "react";
import {NavDropdown} from "react-bootstrap";
import { useGlobal } from "../../context/GlobalContext";
import language from "../../Locale/language-data.json";
import { useChatbotContext } from "./ChatbotContext";
import choose_icon from "../Assets/Chatbot/choose-icon.png";
import Leaderboard from "./Leaderboard";

export default function ChatbotRightFrame(){

    const { WebAppLocale, setWebAppLocale } = useGlobal(); // logout, user, Name, isVerified, removed from {}
    const [CurrentLang, setCurrentLang] = useState(language[WebAppLocale]);

    const handleLanguageChange = (eventKey) => {
        setWebAppLocale(eventKey);
        setCurrentLang(language[eventKey]);
    };

    return (
        <div id="right-section">
            <NavDropdown id="right-lang" title={CurrentLang[WebAppLocale]} onSelect={handleLanguageChange}>
                <NavDropdown.Item draggable="false" value="English" eventKey={"English"}>
                    {CurrentLang.English}
                </NavDropdown.Item>
                <NavDropdown.Item draggable="false" value="Hindi" eventKey={"Hindi"}>
                    {CurrentLang.Hindi}
                </NavDropdown.Item>
                <NavDropdown.Item draggable="false" value="Marathi" eventKey={"Marathi"}>
                    {CurrentLang.Marathi}
                </NavDropdown.Item>
                <NavDropdown.Item draggable="false" value="Bengali" eventKey={"Bengali"}>
                    {CurrentLang.Bengali}
                </NavDropdown.Item>
                <NavDropdown.Item draggable="false" value="Telugu" eventKey={"Telugu"}>
                    {CurrentLang.Telugu}
                </NavDropdown.Item>
            </NavDropdown>
            <Leaderboard />
        </div>
    )
}