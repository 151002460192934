import React, { useState, useEffect } from "react";
import fox from "../Assets/Profile/fox.png";
import { useGlobal } from "../../context/GlobalContext";
import earth from "../Assets/Leaderboard/earth.png";
import star from "../Assets/Leaderboard/star.png";
import trophy from "../Assets/Leaderboard/trophy.png";
import crystal from "../Assets/Leaderboard/crystal.png";
import camera from "../Assets/Profile/camera.png";
import signout from "../Assets/Profile/signout.png";
import { Link, useNavigate } from "react-router-dom";
import { useChatbotContext } from "./ChatbotContext";

// import { useHistory } from 'react-router-dom';

export default function Profile(props) {
    const {CurrentLang} =useChatbotContext();
    const { setProfession,UID, CSRFToken, logout } = useGlobal();
    const navigate = useNavigate();
    const [ranks, setRanks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user_data, setUser_data] = useState({});

    useEffect(() => {
        fetchUserInfo();
    }, [UID]);

    const fetchUserInfo = () => {
        fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/get_user_details`, {
            headers: {
                Authorization: `Bearer ${UID}`,
            },
            credentials: "include",
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log("API Response:", data); // Log the API response for debugging

                if (data.status) {
                    setUser_data(data.user_details);
                } else {
                    console.error("API Error:", data.Reason); // Log API errors
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    };

    const handleLogout = (e) => {
        // e.preventDefault();
        setProfession("");
        logout();
        navigate("/login");
    };

    return (
        <>
            {props.isProfileOpen && (
                <div id="profile_content">
                    <div id="pic">
                        <div id="image-container">
                            {user_data.profile_pic ? <img src={`data:image/png;base64,${user_data.profile_pic}`} alt="User Profile" id="image" /> : <img src={fox} alt="Default Fox" id="image" />}
                            <button id="upload-button">
                                <img src={camera} alt="Upload Icon" width="22px" draggable="false" />
                            </button>
                        </div>
                    </div>
                    {`${user_data.name}`}
                    <br/>
                    {`${user_data.username} / ${user_data.class_of_study}`} {CurrentLang.Class}
                    <div id="profile-contain1">
                        <div id="earth-points" className="points_tabs">
                            {/* <div className="Earth-points"> */}
                                <img src={earth} alt="" id="earth-image" className="points-img"/>
                                <div id="message-earth">{CurrentLang.Earth}</div>
                                <div id="earth-data">{user_data.points}</div>
                            {/* </div> */}
                        </div>
                        <div id="streak-points" className="points_tabs">
                        <div id="streak-container">
                            {CurrentLang.Streak}
                            <div id="streak-subcontainer">
                                <img src={trophy} alt="" id="trophy-image" className="points-img" />
                                <div id="streak-data">{user_data.trophies}</div>
                            </div>
                        </div>
                        <div id="progress-bar-container">
                            <div className="progress-bar">
                                <div className="progress" style={{ width: `${(user_data.streak / 7) * 100}%` }} />
                            </div>
                            <div>{user_data.streak}/7</div>
                            {/* <img src={trophy} alt="" id="trphy-streak" className="points-img" /> */}
                        </div>
                        </div>
                    {/* </div> */}

                    {/* <div id="profile-contain2"> */}
                        <div id="questions" className="points_tabs">
                            {/* <div className='questions'> */}
                            <div id="message-questions"> {CurrentLang.AnsweredQuestions}</div>
                            <img src={crystal} alt="" id="crystal-image" className="points-img"/>
                            <div id="crystal-data">{user_data.crystals}</div>
                        </div>
                        {/* </div> */}

                        <div id="askques" className="points_tabs">
                            {/* <div className='askques'> */}
                            <div id="message-askques">{CurrentLang.AskedQuestions}</div>
                            <img src={star} alt="" id="star-image" className="points-img"/>
                            <div id="ask-data">{user_data.stars}</div>
                        </div>

                        {/* </div> */}
                    </div>
                    <div id="logout">
                        <button onClick={() => handleLogout()} className="logout-button">
                            <img src={signout} alt="Signout Icon" />
                            {" "}
                            {CurrentLang.Logout}
                        </button>
                    </div>
                </div>
             )}
        </>
    );
}
