import React, { useState, useEffect, useRef } from "react";
// import dots from "../Assets/dots.png";
import tutor from "../Assets/Chatbot/tutor-icon.png";
import maths from "../Assets/Chatbot/maths-icon.png";
import ask from "../Assets/Chatbot/Chat-Modes/ask-icon.png";
import practice from "../Assets/Chatbot/Chat-Modes/practice-icon.png";
import book from "../Assets/Chatbot/Chat-Modes/books-icon.png";
import sci from "../Assets/Chatbot/sci-icon.png";
import open_books from "../Assets/books_o.png";
import books from "../Assets/books.png";

import { useChatbotContext } from "./ChatbotContext";

export default function Chatbotdotbutt() {
  const [asideNavVisible, setAsideNavVisible] = useState(false);
  const chatbotRef = useRef(null);
  const {
    //
    CurrentLang,
    ChatMode,
    Subject,
    setSubject,
    setChatMode,
    setIsAnimationRenderedOnce,
  } = useChatbotContext();
  const [imageSrc, setImageSrc] = useState(books);
  const handleDotClick = () => {
    setAsideNavVisible(!asideNavVisible);
  };

  const handleAsk = () => {
    handleChatModeSelection("Ask");
  };

  const handlePractice = () => {
    handleChatModeSelection("Practice");
  };

  const handleScience = () => {
    handleSubjectSelection("Science");
  };

  const handleMaths = () => {
    handleSubjectSelection("Maths");
  };

  const handleSubjectSelection = (subject) => {
    setSubject(subject);
    setIsAnimationRenderedOnce(true);
    setAsideNavVisible(false);
  };

  const handleChatModeSelection = (mode) => {
    setChatMode(mode);
    setIsAnimationRenderedOnce(true);
    setAsideNavVisible(false);
  };
  const handleDocumentClick = (e) => {
    if (chatbotRef.current && !chatbotRef.current.contains(e.target)) {
      setAsideNavVisible(false);
    }
  };

  useEffect(() => {
    if (asideNavVisible) {
      // When the chatbot aside is open-booksed, change the image
      setImageSrc(open_books);
    } else {
      // When the chatbot aside is closed, change the image back to the initial image
      setImageSrc(books);
    }
    if (asideNavVisible) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [asideNavVisible]);

  return (
    <div id="three_">
      <button type="button" id="dots-icon" onClick={handleDotClick} ref={chatbotRef}>
        <img src={imageSrc} alt="dots" />
      </button>
      {asideNavVisible && (
        <div id="chatbot-aside-frame_dot">
          <div id="select-mode-options_dot">
            <div id="message">{CurrentLang.WhatWillYou}</div>
            <div onClick={() => handleChatModeSelection("Ask")} className={`select-mode-option_dot ${ChatMode === "Ask" ? "select-mode-option-active-dot" : ""}`}>
              <img src={ask} alt="ask-mode" className="option-icon" />
              {CurrentLang.Ask}
            </div>
            <div onClick={() => handleChatModeSelection("Practice")} className={`select-mode-option_dot ${ChatMode === "Practice" ? "select-mode-option-active-dot" : ""}`}>
              <img src={practice} alt="practice-mode" className="option-icon" />
              {CurrentLang.Practice}
            </div>
            
            {/* <div onClick={() => handleChatModeSelection("Books")} className={`select-mode-option_dot ${ChatMode === "Books" ? "select-mode-option-active-dot" : ""}`}>
              <img src={book} alt="book-mode" className="option-icon" />
              Books
            </div> */}
          </div>
          {ChatMode === "Ask" ? (
            <>{/* MEANT TO SHOW ADDITIONAL OPTIONS IN ASK SECTION */}</>
          ) : ChatMode === "Practice" ? (
            <>
              {/* MEANT TO SHOW ADDITIONAL OPTIONS IN PRACTICE SECTION */}
              <div id="select-sub-options_dot">
                <div id="message-two">{CurrentLang.SelectSub}</div>
                <div onClick={handleScience} className={`select-sub-option_dot ${Subject === "Science" ? "select-sub-option-active-dot" : ""} large-font`} id="ch-op1">
                  <img src={sci} alt="" className="option-icon" />
                  YoZu
                </div>
                <div onClick={handleMaths} className={`select-sub-option_dot ${Subject === "Maths" ? "select-sub-option-active-dot" : ""}`} id="ch-op2">
                  <img src={maths} alt="" className="option-icon" />
                  Teacher
                </div>
              </div>
            </>
          ) : (
            ChatMode === "Books" && <>{/* MEANT TO SHOW ADDITIONAL OPTIONS IN BOOKS SECTION */}</>
          )}
        </div>
      )}
    </div>
  );
}
