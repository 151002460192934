import React from "react";

import styles from "./Teacher.module.css";

import Assessments from "./Assessments";

export default function TeacherRightFrame() {
  return (
    <div id={styles.rightSection}>  
      <div id={styles.groups} className={styles.sectionContainer}>
        <div id={styles.groupContainer} className={styles.msgContainer}>
          <Assessments/>
        </div>
      </div>
    </div>
  );
}
