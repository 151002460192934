import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import language from "../../Locale/language-data.json";
import { useGlobal } from "../../context/GlobalContext";
import community_icon from "../Assets/Chatbot/community_icon.svg";
import competition_icon from "../Assets/Chatbot/competition_icon.svg";
import dashboard_icon from "../Assets/Chatbot/dashboard_icon.svg";
import leaderboard_icon from "../Assets/Chatbot/leaderboard_icon.svg";
import yozu_logo from "../Assets/LogoWhiteBg.png";
// import ChatbotAsideNav from "../Chatbot/ChatbotAsideNav";

export default function Navigation(props) {
    // Global context
    const { logout, user, Name, isVerified, WebAppLocale, setWebAppLocale } = useGlobal();

    const [menuOpen, setMenuOpen] = useState(false);
    const [CurrentLang, setCurrentLang] = useState(language[WebAppLocale]);

    const navigate = useNavigate();

    // Call the firebase logout API when user clicks logout
    const handleLogout = (e) => {
        e.preventDefault();
        logout();
        navigate("/login");
    };

    // Set media query for responsiveness
    let mediaQuery = window.matchMedia("(max-width: 48rem)");
    if (props.currentPage === "chatbot" || props.currentPage === "teach") {
        mediaQuery = window.matchMedia("(max-width: 61.99rem)");
    }

    // Toggle the offcanvas manu on mobiles
    const toggleMenu = () => {
        if (mediaQuery.matches) {
            setMenuOpen(!menuOpen);
        }
    };

    // Close the offcanvas menu on mobiles
    const handleClose = () => {
        if (mediaQuery.matches) {
            setMenuOpen(false);
        }
    };

    // Handle the navigation to the contact section on home page
    const handleContact = () => {
        setTimeout(() => {
            document.querySelector("#contact-section").scrollIntoView();
        }, 700);
        toggleMenu();
    };

    // Handle the language change in the chatbot web app
    const handleLanguageChange = (eventKey) => {
        setWebAppLocale(eventKey);
        setCurrentLang(language[eventKey]);
    };

    useEffect(() => {
        setCurrentLang(language[WebAppLocale]);
    }, [WebAppLocale]);

    return (
        <>
            {!(props.currentPage === 'chatbot' || props.currentPage === 'teacher') &&  (<>
            <Navbar className={`${props.class} start-0 end-0 top-0 px-4`} expand={props.currentPage === "chatbot" ? "lg" : "md"}>
                {/* The brand logo on the left side of the navbar  */}
                {props.noLogo ? "" : 
                (<Navbar.Brand href="/" className="logo me-3 px-1">
                    <img src={yozu_logo} loading="lazy" width="50" alt="" className="d-inline-block align-top" draggable="false" />
                </Navbar.Brand>)}
                {/* The language change dropdown for chatbot and small screen sizes */}
                {mediaQuery.matches && props.currentPage === "chatbot" ? (
                    user && isVerified ? (
                        <>
                            <NavDropdown title={WebAppLocale} onSelect={handleLanguageChange}>
                                <NavDropdown.Item className="dd-elmnt" draggable="false" value="English" eventKey={"English"} >
                                    English
                                </NavDropdown.Item>
                                <NavDropdown.Item className="dd-elmnt" draggable="false" value="Hindi" eventKey={"Hindi"}>
                                    Hindi
                                </NavDropdown.Item>
                                <NavDropdown.Item className="dd-elmnt" draggable="false" value="Marathi" eventKey={"Marathi"}>
                                    Marathi
                                </NavDropdown.Item>
                                <NavDropdown.Item className="dd-elmnt" draggable="false" value="Bengali" eventKey={"Bengali"}>
                                    Bengali
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title={Name}>
                                <NavDropdown.Item onClick={handleLogout} className="bg-danger bg-gradient text-white text-center">
                                    Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        </>
                    ) : (
                        ""
                    )
                ) : (
                    ""
                )}
                {/* Offcanvas toggle button on navbar when page isn't login or signup (they don't need external links,only home page link)*/}
                {props.currentPage !== "login" && props.currentPage !== "signup" && (<>
                    <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" className="" id="nav-close" onClick={toggleMenu} />
                </>)}
                {/* The navigation links on the left side of the navbar */}
                <Navbar.Offcanvas show={menuOpen} onHide={handleClose} id={`offcanvasNavbar-expand-md`} aria-labelledby={`offcanvasNavbarLabel-expand-md`} placement="start">
                    <Offcanvas.Header closeButton>
                        <Navbar.Brand href="/" className="logo me-3 px-1">
                            <img src={yozu_logo} loading="lazy" width="50" alt="" className="d-inline-block align-top" draggable="false" />
                        </Navbar.Brand>
                        {/* Chatbot navigation links if page is chatbot and small screen sizes */}
                        {mediaQuery.matches && props.currentPage === "chatbot" ? (
                            <>
                                <div id="cnb-dashboard" className="cn-btns">
                                    <img src={dashboard_icon} alt="Dashboard" draggable="false" />
                                    {/* <span>{CurrentLang.Dashboard}</span> */}
                                </div>
                                <div id="cnb-leaderboard" className="cn-btns">
                                    <img src={leaderboard_icon} alt="Leaderboard" draggable="false" />
                                    {/* <span>{CurrentLang.Leaderboard}</span> */}
                                </div>
                                <div id="cnb-competition" className="cn-btns">
                                    <img src={competition_icon} alt="Competition" draggable="false" />
                                    {/* <span>{CurrentLang.Competition}</span> */}
                                </div>
                                <div id="cnb-community" className="cn-btns">
                                    <img src={community_icon} alt="Community" draggable="false" />
                                    {/* <span>{CurrentLang.Community}</span> */}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {/* If page is chatbot and small screen size is detected, render aside menu of chatbot in offcanvas */}
                        {mediaQuery.matches && props.currentPage === "chatbot" ? (
                            // <ChatbotAsideNav />
                            <></>
                        ) : (
                            <>
                                <Nav className="me-auto d-flex align-items-center">
                                    {/* When current page is home or about */}
                                    {props.currentPage === "home" || props.currentPage === "about" ? (
                                        <>
                                            <Link to="" className="navi-link" onClick={toggleMenu}>
                                                About
                                            </Link>
                                            <Link to="" className="navi-link" onClick={toggleMenu}>
                                                Blog
                                            </Link>
                                            <Link to="/#contact-section" className="navi-link" onClick={handleContact}>
                                                Contact Us
                                            </Link>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* When current page is chatbot*/}
                                    {/* {props.currentPage === "chatbot" ? (
                                        <>
                                            <div id="cnb-dashboard" className="cn-btns">
                                                <img src={dashboard_icon} alt="Dashboard" draggable="false" />
                                                <span>{CurrentLang.Dashboard}</span>
                                            </div>
                                            <div id="cnb-leaderboard" className="cn-btns">
                                                <img src={leaderboard_icon} alt="Leaderboard" draggable="false" />
                                                <span>{CurrentLang.Leaderboard}</span>
                                            </div>
                                            <div id="cnb-competition" className="cn-btns">
                                                <img src={competition_icon} alt="Competition" draggable="false" />
                                                <span>{CurrentLang.Competition}</span>
                                            </div>
                                            <div id="cnb-community" className="cn-btns">
                                                <img src={community_icon} alt="Community" draggable="false" />
                                                <span>{CurrentLang.Community}</span>
                                            </div>
                                            <span style={{ alignSelf: "flex-end", paddingLeft: "1rem", color: "#7138D0", filter: "opacity(50%)", fontSize: "12.5px", fontWeight: "500" }}>&#8592; coming soon</span>
                                        </>
                                    ) : (
                                        ""
                                    )} */}
                                </Nav>

                                {/* Right Links */}
                                <Nav className="ms-auto d-flex align-items-center">
                                    {/* When current page is home or about*/}
                                    {props.currentPage === "home" || props.currentPage === "about" ? (
                                        <>
                                            {/* <Link to="/login" className="navi-link" style={{ border: "none" }}>
                                                Login
                                            </Link> */}
                                            <Link to="/login" className="highlight-link">
                                                Login / Signup
                                            </Link>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* When current page is chatbot */}
                                    {props.currentPage === "chatbot" ? (
                                        <>
                                            {user && isVerified ? (
                                                <>
                                                    <NavDropdown title={WebAppLocale} onSelect={handleLanguageChange}>
                                                        <NavDropdown.Item draggable="false" value="English" eventKey={"English"}>
                                                            English
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item draggable="false" value="Hindi" eventKey={"Hindi"}>
                                                            Hindi
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item draggable="false" value="Marathi" eventKey={"Marathi"}>
                                                            Marathi
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Item draggable="false" value="Bengali" eventKey={"Bengali"}>
                                                            Bengali
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                    <NavDropdown title={Name}>
                                                        <NavDropdown.Item onClick={handleLogout} className="bg-danger bg-gradient text-white text-center">
                                                            Logout
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {/* When current page is signup */}
                                    {props.currentPage === "signup" ? (
                                        <Link to="/login" className="highlight-link">
                                            Login
                                        </Link>
                                    ) : (
                                        ""
                                    )}
                                </Nav>
                            </>
                        )}
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            </>)}
        </>
    );
}
