import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import video_icon from "../Assets/Chatbot/video-icon.png";
import playbtn from "../Assets/Chatbot/video_play.png"

export default function ImageLoadWithAnim({ ...props }) {
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        const img = new Image();
        img.src = props.src;
        img.onload = () => {
            setisLoading(false);
        };
    }, [props]);

    return (
        <span className="message-img-loader-outer" key={props.key} aria-hidden="true" style={{ display: "inline-block" }}>
            {isLoading ? (
                <span className="message-img-loader"></span>
            ) : (
                <>
                    <motion.img initial={{ opacity: 0, filter: "blur(5px)" }} animate={{ opacity: 1, filter: "blur(0px)" }} {...props} draggable={false} />
                    {props.dtype === "video" && <img id="play-btn" src={playbtn}/>}

                    {/* {props.dtype === "video" ? <motion.img initial={{ opacity: 0, filter: "blur(5px)" }} animate={{ opacity: 0.6, filter: "blur(0px) drop-shadow(0px 0px 1.5px rgba(99, 99, 99, 1))" }} className="video-icon" src={video_icon} loading="lazy" alt="video-icon" draggable={false} /> : ""} */}
                </>
            )}
        </span>
    );
}
