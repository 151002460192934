import { useChatbotContext } from "./ChatbotContext";
import { useGlobal } from "../../context/GlobalContext";
import React,{ useEffect, useState } from "react";

import backbutton from "../Assets/backbutton.png";
import "./Chatbot-v2.css";
import LoadingAnimation from "../Teacher/LoadAnim";

export default function Books() {
  //GLOBAL VARIABLES
  const { UID, CSRFToken } = useGlobal();

  //CHATBOT CONTEXT STATES
  const {bookData,setBookData} = useChatbotContext();

  //LOCAL VARIABLES
  
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [loader, setLoader] = useState(false);
  const [bookText,setBookText] = useState(null);

  const [selectedText,setSelectedText]= useState(null);
  const [buttonPosition,setButtonPosition]=useState({});
  const [explainText,setExplainText]=useState(null);

  const handleSelection = (event) => {
    const target=event.target;

    const selection=window.getSelection();
    const text= selection.toString().trim();
    if(text !== ''){
        setSelectedText(text);
        setButtonPosition({left:target.offsetLeft,top:target.offsetTop+40});
        }
  };

  const handleExplainClick = async () => {
    const data={text:selectedText};
    const url=`${process.env.REACT_APP_BACKEND_SERVER}/chat/explain_text`

    const form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }

    try{
        const response= await fetch(url,{
            body: form_data,
            method: "POST",
            headers: {
                Authorization: `Bearer ${UID}`,
                "X-CSRFToken": `${CSRFToken}`,
            },
            credentials:"include",
        });
        const responseJSON=await response.json();
        setExplainText(responseJSON.new_text);
        highlightSelect();
    }
    catch(e){
        alert("Couldn't obtain explained text",e)
    }
  };

  const highlightSelect= () => {
    // console.log("Selected Text:", selectedText);
    // console.log("Book Text:", bookText);
    const parts = bookText.split(selectedText);
    const highlightedParts = parts.map((part,index)=>{
        return(<React.Fragment key={index}>
            {index>0 && <span className="highlighted">{selectedText}</span>}
            {part}
        </React.Fragment>);
    });
    setBookText(highlightedParts);
  };
  const fetchChapterInfo = async () => {
    setLoader(true);
    const data = {
      Board: bookData.board,
      Grade: "Class 6",
      // "Chapter": chap,
      Subject: "Science",
      language: "english",
    };
    const form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }
    try {
      const url = `${process.env.REACT_APP_BACKEND_SERVER}/chat/get_book_data_keys`;

      const response = await fetch(url, {
        body: form_data,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
      });
      const responseJson = await response.json();
      //   console.log(responseJson);
      setLoader(false);
      setChapters(responseJson.data.Chapter);
    } catch (error) {
      alert("Error in chapter fetching", error);
    }
  };

  const fetchTopicInfo = async () => {
    setLoader(true);
    const data = {
      Board: bookData.board,
      Grade: "Class 6",
      Subject: "Science",
      Chapter: bookData.chapter,
      language: "english",
    };

    const form_data = new FormData();
    for (var key in data) {
      form_data.append(key, data[key]);
    }

    const url = `${process.env.REACT_APP_BACKEND_SERVER}/chat/get_book_data_keys`;
    try {
      const response = await fetch(url, {
        body: form_data,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
      });
      const responseJson = await response.json();
      setLoader(false);
      setTopics(responseJson.data.Topic);
    //   console.log(responseJson);
    } catch (error) {
      alert("Error occuring in topic fetching :", error);
    }
  };

  const handleDeselect = async (event) =>{ 
    const target=event.target;
    if(target.id !== "explain-book" && selectedText !== null)
    {setSelectedText(null);setButtonPosition({})}};

  const fetchBooks = async () => {
    setLoader(true);
    const data = {Chapter: bookData.chapter,Topic: bookData.topic};
    const url = `${process.env.REACT_APP_BACKEND_SERVER}/chat/get_book_data`;

    const formdata = new FormData();
    for (var key in data) {
      formdata.append(key, data[key]);
    }
    try {
      const response = await fetch(url, {
        body: formdata,
        method: "POST",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
        },
        credentials: "include",
      });
      const responseJSON = await response.json();
      console.log(responseJSON);
      setBookText(responseJSON.topic_data);
      setLoader(false);
    } catch (e) {
      alert("Error while fetching Books:", e);
    }
  };

  useEffect(() => {
    fetchChapterInfo();
  }, []);

  useEffect(() => {
    fetchTopicInfo();
  }, [bookData.chapter]);

  useEffect(() => {
    if (bookData.topic !== null) {
      fetchBooks();
    }
  }, [bookData.topic]);

  return (
    <>
      {/* SELECTION AREA */}

      {/* CHAPTER GRID */}
      {loader && <LoadingAnimation />}
      {bookText === null && !loader && bookData.chapter === null && (
        <>
          <h1 className="select-heading">Chapters</h1>
          <div id="book-grid">
            {chapters.map((chapter, chIndex) => (
              <>
                <div
                  className={`grid-item ${
                    chapter === bookData.chapter ? "active" : ""
                  }`}
                  onClick={() =>
                    setBookData((prevData) => ({
                      ...prevData,
                      chapter: chapter,
                    }))
                  }
                >
                  {chapter}
                </div>
              </>
            ))}
          </div>
        </>
      )}
      {/* TOPIC GRID */}
      {bookText === null && !loader && topics !== null && bookData.chapter !== null && (
        <>
          
          <h1 className="select-heading">
            <span
            className="back2chap"
            onClick={() => {
              setTopics([]);
              setBookData((prevData) => ({
                ...prevData,
                chapter: null,
                topic: null,
              }));
            }}
            >
                <img src={backbutton} alt="Back-Button" />
            </span>
            Topics</h1>
          <div id="book-grid">
            {topics.map((topic, tIndex) => (
              <>
                <div
                  className={`grid-item ${
                    topic === bookData.topic ? "active" : ""
                  }`}
                  onClick={() =>
                    setBookData((prevData) => ({
                      ...prevData,
                      topic: topic,
                    }))
                  }
                >
                  {topic}
                </div>
              </>
            ))}
          </div>
        </>
      )}

      {/* ACTUAL TEXT */}
      {bookText !== null && (<>
      <div id="book-text" onMouseUp={(event)=>handleSelection(event)} onMouseDown={(event)=>handleDeselect(event)}>
        {/* CLOSE BUTTON */}
        <span id="close-book" onClick={()=>{setBookText(null);setExplainText(null);setBookData((prevData)=>({...prevData,chapter:null,topic:null}))}}> &times; </span>

        {/* TEXT */}
        {bookText}

        {/* EXPLAIN BUTTON */}
        {selectedText !== null && (<>
        <span id="explain-book" style={{top:buttonPosition.top,left:buttonPosition.left}} onClick={()=>handleExplainClick()}>Explain</span>
        </>)}
      </div>
      </>)}
       {/* EXPLAINED TEXT */}
       {explainText !== null && (<>
       <div id="book-explained">
       <span id="close-book" onClick={()=>{setExplainText(null);}}> &times; </span>
        <h1>Explained</h1>
        {explainText}
       </div>
       </>)}
    </>
  );
}
