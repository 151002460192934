import styles from "./Teacher.module.css";
import { NavDropdown } from "react-bootstrap";
import React from "react";

import { useTeacherContext } from "./TeacherContext";
import { useGlobal } from "../../context/GlobalContext";
import { useState,useEffect } from "react";

// import logo from "./..//Assets//LogoWhiteBg.png";
import logo from "./..//Assets//logo-New-removebg-preview.png"; //Alternative logo without background
import profile_icon from "./..//Assets//profile_icon.png";
import feedback_icon from "./..//Assets//feedback_icon.png";
import group_icon from "./..//Assets//Teacher//group.png";
import saved_icon from "./..//Assets//Teacher//saved_icon.png";

import { Link } from "react-router-dom";

import FeedbackForm from "./FeedbackForm";
import Groups from "./Groups";
import SavedPlans from "./SavedPlans";
import TeacherGroupPopup from "./TeacherGroupPopup";
import Profile from "./Profile";
import { AnimatePresence } from "framer-motion";


export default function TeacherLeftFrame() {
  const {groups,setExpLP} = useTeacherContext();

  const [captionVis, setCaptionVis] = useState("flex");
  const [savedPlans,setSavedPlans] = useState([]);

  const [activeContainer,setActiveContainer] = useState("groups")

  const { UID, CSRFToken } = useGlobal();
  
  //Lesson plan structure
  const headers=["Grade","Chapter","Topic"];

  //FETCH SAVED PLANS
  const savedLPs = async () => {
    setSavedPlans([]);
    const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/getallplans`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${UID}`,
          "X-CSRFToken": `${CSRFToken}`,
          credentials: "include",
        },
      });
      if (response.status) {
        const responseJSON = await response.json();
        setSavedPlans(responseJSON.lessons_created);
        // console.log(responseJSON.lessons_created); 
        }
    } catch (e) {
      console.log("Error while loading user lesson plans", e);
    }
  };

  const getDateTime = (timeString) =>{
    const isoDateString = timeString.slice(0,19);
    const date = new Date(isoDateString);

    const options = {weekday:"long"};
    const dayName = new Intl.DateTimeFormat('en-US',options).format(date);

    const formattedDate = new Intl.DateTimeFormat('en-US',{
      month:'long',
      day:'numeric'
    }).format(date)
    return {
      day:dayName,
      date:formattedDate};
    };

  return (
    <div id={styles.leftSection}>
      <div className={styles.leftHeader}>
        <Link to="/"><img src={logo} alt="Logo" id={styles.logoImg} /></Link>
        
        <button onClick={() =>setActiveContainer(activeContainer === "saved" ? "groups" : "saved")}> <img src={saved_icon} alt="Feedback Icon"/></button>
        <button onClick={() =>setActiveContainer(activeContainer === "feedback" ? "groups" : "feedback")}> <img src={feedback_icon} alt="Feedback Icon"/> </button>
        <button onClick={() =>setActiveContainer(activeContainer === "profile" ? "groups" : "profile")}> <img src={profile_icon} alt="Profile Icon"/> </button>
      </div>
      <div id={styles.leftContent} className={styles.sectionContainer}>
        <div className={styles.msgContainer}>
          {activeContainer === "groups" ? (<>
          {/* Displaying Groups Section */}
            <Groups />
            </>
          ) : activeContainer === "profile" ? (<>
            {/* Displaying Profile Section */}
            <Profile isProfileOpen={true}/>
            </>
          ) : activeContainer === "feedback" ? (<>
            {/* Displaying Feedback Section */}
            <FeedbackForm />
            </>
          ) : activeContainer=== "saved" ? (<>
            {/* Displaying Saved Plans Section */}
              <SavedPlans/>
          </>):
          (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
