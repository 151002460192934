import { useState,useEffect } from "react";
import { useGlobal } from "../../context/GlobalContext";
import { useTeacherContext } from "./TeacherContext";

import TeacherGroupPopup from "./TeacherGroupPopup";
import styles from "./Teacher.module.css";

import { AnimatePresence } from "framer-motion";

import group_icon from "./..//Assets//Teacher//group.png";

export default function Groups (){

    const { UID, CSRFToken } = useGlobal();

    const {groups,setGroups,setExpLP} = useTeacherContext();
    
    const [showPopup, setShowPopup] = useState(null);
    const [groupIndex,setGroupIndex] = useState(null);
    const [captionVis, setCaptionVis] = useState("flex");
    const [groupImage, setGroupImage] = useState({group_icon});

    const handleGroupClick = (index,action) =>{
      setGroupIndex(index);
      setShowPopup(action);
    };
    
    useEffect(() => {
        fetchGroupInfo();
      }, [UID,showPopup]);

    const fetchGroupInfo = async() => {
  
        try{
          const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/student_group/get_groups`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${UID}`,
              "X-CSRFToken":`${CSRFToken}`,
            },
            credentials: "include",
          });
          const responseJson =await response.json();
          //PICK OUT ACTIVE GROUPS ONLY
          const activeGroups= responseJson.data.filter(item=> item.group_details && item.group_details.is_active === true);
          setGroups(activeGroups);
        }
        catch(e){
          console.error('Error while fetching groups:',e);
        }    
      };

return(
    <>
    {/* Create,View and Edit Popup */}
    <AnimatePresence>{showPopup && (
        <TeacherGroupPopup
            mode={showPopup}
            group={showPopup === "view" || showPopup === "edit" ? groups[groupIndex] : undefined}
            closePopup={() => setShowPopup(null)}
        />
        )}
    </AnimatePresence>

    {/* Placeholder if no groups are present */}
    {showPopup=== null && groups.length <=0 && (
        <div className={styles.teacherPH} style={{ display: captionVis }}>
        Make New Groups
        </div>
    )}

    {/* Main groups section */}
    {showPopup === null && groups.length >0 && (
    <div className={styles.groupsContainer}>
        {groups.map((group,groupIndex) => (
        <>
        <div className={styles.groupDisplay} key={groupIndex}>
            <div className={styles.pfpBtns}>
            <div className={styles.groupPfp}><img alt="Group"src={group_icon}/></div>
            <button id={styles.viewGrpBtn} key={groupIndex} onClick={() => handleGroupClick(groupIndex,"view")}>View</button>
            <button id={styles.editGrpBtn} onClick={() => handleGroupClick(groupIndex,"edit")}>Edit</button>
            </div>
            <div className={styles.groupDetails}>
            <ul>
                <li key="Group Name">Group name : {group.group_details.group_name} </li>
                <li key="Class">Class : {group.group_details.class_of_study || "N/A"}</li>
                <li key="Level">Reading Level : {group.group_details.level || "N/A"}</li>
                <li key="School">School : {group.group_details.school_name || "N/A"}</li>
                <li key="Student Num">No. of Students : {group.members && (group.members.filter(member => member.is_active === true)).length || "N/A"}</li>
            </ul>
            </div>
        </div>
        </>
        ))}
    </div>
    )}

    {/* Create Group Button */}
    {showPopup === null && (
    <div className={styles.teacherBtnWrapper} style={showPopup ? { border: "1px solid #fff" } : {}}>
        <button className={styles.teacherBtn} onClick={()=> setShowPopup("create")}>
        Create Group
        </button>
    </div>
    )}
    </>
);
}