import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, provider } from "../googleLogin/config";

const globalContext = createContext();

export function GlobalContextProvider({ children }) {
    // Global context states to manage user
    const [user, setUser] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [UID, setUID] = useState("");
    const [FID, setFID] = useState("");
    const [CSRFToken, setCSRFToken] = useState("");
    const [WebAppLocale, setWebAppLocale] = useState("English");
    const [Profession,setProfession]= useState("");

    const[showSettings,setShowSettings]=useState(false);
    const [fontSlider,setFontSlider]=useState(2);

    const languages={
        English:"en",
        Hindi:"hi",
        Marathi:"mr",
        Bengali:"bn",
        Telugu:"te"
    }

    //Google Translation API
    const translator = async(text,fromLang,toLang) =>{
        let url=`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_TRANSLATE_API_KEY}`;
        url+=`&q=`+encodeURI(text);
        url+=`&source=${fromLang}`;
        url+=`&target=${languages[toLang]}`;

        fetch(url,{
            method:'GET',
            headers:{
                "Content-Type":"application/json",
                Accept:"application/json"
            }
        })
        .then(res => res.json())
        .then((response)=>{
            console.log("translation",response);
        })
        .catch(error=>{
            console.error("There was an error with the translation request",error);
        })
    };

    // Chat specific context
    const [imageLikeDislikes, setImageLikeDislikes] = useState({
        "0000conv": {},
    });

    // Google login function to get user information
    const googleLogin = async () => {
        const promise = await signInWithPopup(auth, provider);
        return promise;
    };

    // Logout function to clear user information from and logout from firebase
    const logout = () => {
        signOut(auth).then(
            () => {
                // console.log("logging out!");
                // The user is now signed out.
                sessionStorage.clear();
            },
            (error) => {
                // console.log("Logout failed");
            }
        );
    };

    // Get CSRF token to use in current session
    async function getCSRFtoken() {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/csrf`, {
            mode: "cors",
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("idToken")}`,
            },
            credentials: "include",
            SameSite: "None",
        });
        const responseJson = await response.json();
        // console.log("X-CSRFToken : ", responseJson.csrfToken);
        setCSRFToken(responseJson.csrfToken);
        return responseJson.csrfToken;
    }

    // Fetch user details to display
    async function getUserDetails() {
        // console.log("UID ", UID);
        const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER}/api/get_user_details`, {
            mode: "cors",
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("idToken")}`,
            },
            credentials: "include",
            SameSite: "None",
        });
        const responseJson = await response.json();
        // console.log(responseJson)
        setName(responseJson.user_details.username);
    }

    useEffect(() => {
        // Listen for changes in the user state.
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        // // Check if the parent window is available.
        // const parentWindow = window.opener;
        // if (!parentWindow) {
        //     // The parent window is not available, so close the popup window ourselves.
        //     window.close();
        // }
        return () => {
            unsubscribe();
        };
    }, []);

    const values = {
        translator,
        Name,
        setName,
        UID,
        setUID,
        logout,
        user,
        googleLogin,
        isVerified,
        setIsVerified,
        WebAppLocale,
        setWebAppLocale,
        imageLikeDislikes,
        setImageLikeDislikes,
        getCSRFtoken,
        getUserDetails,
        FID,
        setFID,
        CSRFToken,
        setCSRFToken,
        Email,
        setEmail,
        Profession,
        setProfession,

        showSettings,
        setShowSettings,
        fontSlider,
        setFontSlider,
    };

    return <globalContext.Provider value={values}>{children}</globalContext.Provider>;
}

export function useGlobal() {
    return useContext(globalContext);
}
