import React from 'react';
import { PDFDownloadLink, Document, Page, Text, Image,StyleSheet, View, Font, PDFViewer } from '@react-pdf/renderer'
import styles from './LessonPlanDownload.module.css';
import NunitoRegular from '../Assets/Fonts/Nunito/static/Nunito-Regular.ttf'
import NunitoBold from '../Assets/Fonts/Nunito/static/Nunito-Bold.ttf'
import logo from "../Assets/logo-New-removebg-preview.png"
import imageph from "../Assets/Teacher/imgph.png"
import { useGlobal } from '../../context/GlobalContext';

export default function LessonPlanDownload({ plan, closePreview }) {
    const headers=["Grade","Chapter","Topic"];
    const body = ["Objectives","Key Pointers","Launch","Hook","How","Integration With Everyday Life","Guided Activity","Conclusion"];
    const media = ["videos","section_wise_images"];
    const dynamicWidthStyle = (textLength) => {
        const minWidth = 50; // Set a minimum width to prevent tiny containers
        const maxWidth = 300; // Set a maximum width to prevent excessively wide containers
        const estimatedWidth = textLength * 8; // Adjust this multiplier based on font-size and content
    
        return {
          width: Math.min(Math.max(minWidth, estimatedWidth), maxWidth),
        };
    };

    const{Name}=useGlobal();

    Font.register({ family: 'Nunito', 
    fonts:[
        {src:NunitoRegular,fontWeight:'normal'},
        {src:NunitoBold,fontWeight:'bold'},],})

    // const nunitoFont = { family: 'Nunito', src: 'https://fonts.googleapis.com/css?family=Nunito' };
    const title = "YoZu LessonPlan";
    const author = `${Name}`;
    const filename= `YoZu-LessonPlan_${plan.data.board}-${plan.data.grade}-Sci-${plan.chapternum}.${plan.topicnum}.pdf`;
    const LPPDF = () => 
        (<Document style={docstyles.document} title={title} author={author}>
            <Page size="A4">
                <View style={docstyles.header}> 
                    <Image src={logo} style={docstyles.logo}/>
                    <View>    
                        <Text style={[docstyles.headerContent]}>Teacher: {Name}</Text>
                        <Text style={[docstyles.headerContent]}>School Name: </Text>
                        <Text style={[docstyles.headerContent]}>Board: {plan.data.Board}</Text>
                        {headers.map((headItem,headIndex)=>(
                            <Text style={[docstyles.headerContent]}>{headItem}: {plan.data[headItem]}</Text>
                        ))}
                    </View>
                </View>
            </Page>
            <Page size="A4" style={{paddingTop:30,paddingBottom:40}}>
                <View style={docstyles.body} >
                    <View style={docstyles.bodyItem}>
                        {body.map((property,index)=> (<>
                        <View key={index} wrap={false}>
                            <Text style={docstyles.bodyItemHeading}>{property}</Text>
                            {plan.data[property].map((point,pointIndex)=>(
                                <Text style={docstyles.bodyItemContent}>
                                    {pointIndex+1}{")"}{point}
                                </Text>  
                            ))}
                               
                        </View>
                         
                        <View style={{flexDirection:"row",padding:"10px",height:"200px",justifyContent:"space-around"}}>
                            {plan.section_wise_images[property]?.map((img,index) => (
                                <Image src={img}/>
                            ))}
                            {/* <Image style={{}}src={imageph}/> */}
                        </View> 
                        <View style={docstyles.line} />
                        </>))}
                    </View>
                    
                </View>
                <Text
                style={docstyles.pageNo}
                render={({pageNumber, totalPages})=> `${pageNumber} / ${totalPages}`} fixed/>
            </Page>
        </Document>);

    const docstyles = StyleSheet.create({
        viewer:{
            width:'100%',
            height:'100%',
        },
        document:{
            fontFamily:'Nunito'
        },
        logo:{
            alignSelf:"center",
            width:"100px",
            height:"100px",
            margin:"5px",
            // marginRight:"10px",
            marginBottom:"50px",
            // borderRadius:"50%",
            padding:'2px'
        },
        header:{
            alignContent:"center",
            backgroundColor:"#fff",
            height:"100%",
            color:"#fff",
            overflow:"auto",
            padding:'10px',
            // alignContent:"space-around",
            justifyContent:"center",
            // borderBottomLeftRadius:"25px",
            // borderBottomRightRadius:"25px",
        },
        headerContent:{ 
            width:"auto",
            marginBottom:"10px",
            fontWeight:'bold',
            textAlign:"center",
            height:"auto",
            backgroundColor:"#fff",
            borderRadius:"15px",
            color:"#18072b",
            padding:"5px",
            marginRight:"10px"
        },
        body:{
            padding:"10px",
            // paddingBottom:30,
            // paddingTop:30,
            fontFamily:'Nunito',
            // fontWeight:"500",
        },
        bodyItem:{

        },
        bodyItemHeading:{
            width:"30%",
            backgroundColor:"rgb(225, 208, 19)",
            // border:"1px solid #18072b",
            borderRadius:"15px",
            textAlign:"center",
            padding:"5px",
            marginBottom:"10px",
            fontFamily:'Nunito',
            fontWeight:'bold',
        },
        bodyItemContent:{
            marginLeft:'5px',
        },
        line: {
            borderBottomWidth: 2,
            borderBottomColor: '#18072b',
            borderBottomStyle: 'solid',
            width: '100%',
            marginBottom:"10px"
          },
        pageNo:{
            position:"absolute",
            fontSize:12,
            bottom:30,
            left:0,
            right:0,
            textAlign:"center",
            color:"grey",
            zIndex:50,
        },
        downloadButton:{
            // border:"1px solid #fff",
            fontWeight:"bold",
            backgroundColor:"rgb(225, 208, 19)",
            color:"#18072b",
            borderRadius:"20px",
            padding:"8px",
            textDecoration:"none"
        },
    });
  return (
    <>
    
      <div  className={styles.Wrapper}>
        <div className={styles.documentContainer}>
            <div className={styles.header}>Preview</div>
            <div className={styles.document}>
                <PDFViewer style={docstyles.viewer} showToolbar={false}>
                    <LPPDF/>
                </PDFViewer>
            </div>            
            <div className={styles.footer}>
                <PDFDownloadLink document={<LPPDF/>} fileName={filename} style={docstyles.downloadButton}>
                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
                </PDFDownloadLink> 
                <button onClick={()=>closePreview()} className={styles.closeBtn}> Close </button>
            </div>
        </div>
        </div> 
    </>
  );
};

