import { useState,useEffect } from "react";
import styles from "./Teacher.module.css"
import { AnimatePresence,motion } from "framer-motion";
import { useTeacherContext } from "./TeacherContext";
import { useGlobal } from "../../context/GlobalContext";

import group_icon from "./..//Assets//Teacher//group.png";
import edit_icon from "./..//Assets//Teacher//edit_icon.png";

export default function GroupPopup(props) {
  const {CSRFToken,UID}=useGlobal();
  const{groups}=useTeacherContext();
  const[addStd,setAddStd]=useState(false);
  const[stdEmail,setStdEmail]=useState();
  const[newName,setNewName]=useState("");

  const [group, setGroup]= useState({
    image:group_icon,
    name:"N/A",
    class: "N/A",
    readAbility: null,
    level:null,
    school: "N/A",
    subject: "N/A",
    id:"N/A,"
  }); 

  const [groupMembers,setGroupMembers] = useState();
  useEffect(() =>{
    if (props.group) {
      setGroup({
        image:props.group.image || group_icon,
        name: props.group.group_details.group_name || "N/A",
        class: props.group.group_details.class_of_study ||"N/A",
        level: props.group.group_details.level ||"N/A",
        school: props.group.group_details.school_name ||"N/A",
        subject: props.group.group_details.subject ||"N/A",
        id:props.group.group_details.group_id || "N/A"
    });
    setGroupMembers(props.group.members || [])
    // console.log(props.group.members)
    }
  }, [props.group]);

    const handleGroupCRUD= async(m) => {
        const data={
          mode:m,
          group_icon:group.image,
          group_name:group.name,
          grade:group.class,
          level:group.readAbility,
          school_name:group.school,
          subject:group.subject,
        }

        const form_data = new FormData;
        for (var key in data){
          form_data.append(key,data[key])
        }
        if(m==="RENAME"){
          form_data.append("new_group_name",newName)
        }
        const url= `${process.env.REACT_APP_BACKEND_SERVER}/student_group/groups`

        try{
        const response = await fetch(url,{
          method:"POST",
          body:form_data,
          headers: {
            Authorization: `Bearer ${UID}`,
            "X-CSRFToken":`${CSRFToken}`,
          },
          credentials:"include",
        })
        const responseJson = await response.json();
        console.log(responseJson);
        props.closePopup(); //groups will be updated after popup closes since there is a useeffect hook in TeacherLeftFrame
      }
      catch(e){ alert("Error while operating on group: ",e)}
    };
    
    const handleImageChange= (e) =>{
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (event) => {
          setGroup({...group, image: event.target.result});
        };
        reader.readAsDataURL(file);
      }
    };

    const RemoveStudent = async(username) =>{
      const url= `${process.env.REACT_APP_BACKEND_SERVER}/student_group/members`
      const data ={
        members:JSON.stringify([username]),
        group_id:group.id,
        mode:"REMOVE",
      }

      const form_data = new FormData();
      for (var key in data){ form_data.append(key,data[key]); }
      try{
        const response=await fetch(url,
          {
          method:"POST",
          body:form_data,
          headers:{
            Authorization:`Bearer ${UID}`,
            "X-CSRFToken":`${CSRFToken}`
          },
          credentials:"include",
        });
        const responseJson = await response.json();
        if(response.status === 200 ){
          const updatedGroups = groups.map((group) => {
            if (group.id === props.group.id){
              group.members=group.members.filter((member)=> member.id !== username);
            }
            return group;
          });
          setGroupMembers(updatedGroups);
        } else {
          alert("Error while removing student");
        }
      }
      catch(e){
        alert("Error while removing student selected:",e)
      }
    }

    const AddStudent = async() =>{
      const emailcheckurl= `${process.env.REACT_APP_BACKEND_SERVER}/check/email_id`
      const emailbody = {
        email_id:stdEmail.trim(),
        MODE:"SINGLE"
      }

      const emailcheckformbody = new FormData();
      for (var key in emailbody){ emailcheckformbody.append(key,emailbody[key]);}
      try{
      const emailcheckresponse=await fetch(emailcheckurl,
        {
          method:"POST",
          body :emailcheckformbody,
          headers:{
            Authorization:`Bearer ${UID}`,
            "X-CSRFToken":`${CSRFToken}`
          },
          credentials:"include",
        })
        const responseJson = await emailcheckresponse.json();
        if (responseJson.status){
          if (responseJson.is_teacher) {
            alert("This is a teacher's email ID. Students only.");
          } else {
          const username = responseJson.username;
          const url= `${process.env.REACT_APP_BACKEND_SERVER}/student_group/members`
          const data ={
            members:JSON.stringify([username]),
            group_id:group.id,
            mode:"ADD",
          }
          const form_data=new FormData();
          for (var key in data){ form_data.append(key,data[key]);}
          try{
            // console.log(data)
            const response=await fetch(url,
              {method:"POST",
              body:form_data,
              headers:{
                Authorization:`Bearer ${UID}`,
                "X-CSRFToken":`${CSRFToken}`
              },
              credentials:"include",
            })
            const responseaddjson= await response.json();
            // console.log(responseaddjson);
            // console.log(response);
            if(response.ok){
              alert("Student Added Successfully");
              setAddStd(false);
              props.closePopup();
            }
          }
          catch(e){
            alert("Error while adding student:",e)
          }
        }}
        else{
          alert("Enter valid email ID!");
        }
      }
      catch(e){

      }
    }
    

    return (
    <motion.div
      initial={{ opacity: 0, }}
      animate={{ opacity: 1, }}
      exit={{ opacity: 0, }}
      key="groupPopup"
     id={styles.groupPopup} className={styles.sidePopupContainer}>
    {props.mode  === "create" ? 
    (<>
       {/* add the css class after modification --- */}
      <div className={styles.sidePopupTitle}>Create New Group</div>
        <div className={styles.groupPhotoUpload}>
          <input id="groupPfpInput"  type = "file" accept="image/*" onChange={handleImageChange} />
          <label htmlFor="groupPfpInput"><img src ={group_icon}/> Set Group Picture</label>
        </div>
        <div className={styles.groupDetailsContainer}>
          <div className={styles.groupDetailTab}>
            <h1> Set Group Name</h1>
            <div className={styles.detailInput}> 
              <input placeholder="Group Name" onChange={(e) =>setGroup({...group,name: e.target.value })} />
              <img src={edit_icon}/>
             </div>
           
          </div>
          <div className={styles.groupDetailTab}>
            <h1>Enter School Name</h1>
            <div  className={styles.detailInput}>
              <input placeholder="Enter School Name" onChange={(e) =>setGroup({...group,school: e.target.value })}/>
              <img src={edit_icon}/>
            </div>
          </div>
          <div className={styles.groupDetailTab}>
            <h1>Enter Subject</h1>
            <div className={styles.detailInput}>
              <input placeholder="Enter Subject" onChange={(e) =>setGroup({...group,subject: e.target.value })}/> 
              <img src={edit_icon}/>
            </div>
          </div>
          <div className={styles.groupDetailTab}>
            <h1>Select Class</h1>
            <div className={styles.groupBtn}>
              {Array.from({length: 5}, (_,index)=> (
                <button key={index} className={`${group.class === index+6 ? styles.active : ''}`} onClick={() => (setGroup({...group,class: index + 6,}))}>{index +6}</button>
              ))}
            </div>
          </div>
          <div style={{border:'1px solid #fff',borderRadius:'1rem'}}>
            <div className={styles.groupDetailTab}>
            <h1>Select Reading Ability</h1>
            <div className={styles.groupBtn}>
              {["Very Poor", "Low", "Average","Good","Very Good"].map((level, index) => (
                <button key={index} className={`${group.readAbility === level ? styles.active : ''}`} onClick={() => (setGroup({...group,readAbility: level}))}>{level}</button>
              ))}
            </div>
          </div>
          {group.readAbility && (<>
          <div className={styles.groupDetailTab}>
            <h1>Select Level</h1>
            <div className={styles.groupBtn}>
              {Array.from({length:3},(_,index)=>(
            <button key={index} className={`${group.level === index+1 ? styles.active :''}`} onClick={()=>setGroup({...group,level:index+1})}>L{index+1}</button>))}
            </div>
          </div>
          </>)}
          </div>
          
          
        </div>
        <div className={styles.groupPopupFooter}>
        <button className={styles.groupPopupClose} onClick={props.closePopup}>&times;</button>
        <button className={styles.teacherBtn} onClick={() =>handleGroupCRUD("CREATE")}>Confirm</button>
      </div>
    </>) : props.mode === "view" ? 
    (<>
     {/* add the css class after modification --- */}
    <div className={styles.metaGroup}>
      {/* <button className={styles.groupEditBtn}><img src={edit_icon}/></button> */}
      <div className={styles.viewGroupImg}>
        <img src={group.image}/>    
      </div>
      <div className={styles.viewGroupDetails}>
        <div className={styles.groupDetail}>Group Name :{group.name}</div>
        <div className={styles.groupDetail}>Class :{group.class}</div>
        <div className={styles.groupDetail}>School :{group.school}</div>
        <div className={styles.groupDetail}>Reading Ability :{group.readAbility ? `${group.readAbility} : ${group.level}` : "N/A"}</div>
        <div className={styles.groupDetail}>Subject :{group.subject}</div>
      </div>
    </div>
    <div className={styles.groupStudentsContainer}>
    {groupMembers && (groupMembers.length <= 0 ? (
      <div className={styles.studentPlaceholder} >Add/Invite students</div>
    ):(<>
      {groupMembers.map((student,studentIndex)=>(
        student.is_active && (
          <div key={studentIndex} className={styles.studentDetailsContainer}>
        <div className={styles.studentImg}>
          {/* ADD STUDENT PROFILE PICTURE HERE */}
          <img src={group_icon}/>
        </div>
        <div className={styles.studentDetails}>
          <span>Name : {student.user.name}</span>
          <button onClick={()=>RemoveStudent(student.user.username)}>Remove Student</button>
        </div>
      </div>
        )
      ))}
    </>))}
    </div>
    <div id={styles.groupViewPopupFooter} className={styles.groupPopupFooter}>
      <button className={styles.groupPopupClose} onClick={props.closePopup}>&times;</button>
      <button className={styles.teacherBtn} onClick={() =>setAddStd(true)}>Add/Invite Students</button>
    </div>
    {addStd && (
        <div className={styles.addStudentContainer}>
          <div className={styles.addStudentTitle}> Add Student</div>
          <input className={styles.detailInput} placeholder="Enter Student Email" value={stdEmail || ''} onChange={(e) =>setStdEmail(e.target.value)}/>
          <div className={styles.groupSelectorFooter}>
            <button className={styles.groupPopupClose} onClick={() =>setAddStd(false)}>&times;</button>
            <button className={styles.teacherBtn} onClick={(e) =>AddStudent()}>Confirm</button>
          </div>
        </div>
      )}

    </>) :props.mode === "edit" ? 
    (<>
 {/* add the css class after modification --- */}
      <div className={styles.sidePopupTitle}>{group.name}</div>
        <div className={styles.groupPhotoUpload}>
          {/* REMOVE DISABLED WHEN IMAGE IS EDITABLE */}
          <input id="groupPfpInput"  type = "file" accept="image/*" onChange={handleImageChange} disabled/>
          <label htmlFor="groupPfpInput"><img src ={group.image}/></label>
        </div>
        <div className={styles.groupDetailsContainer}>
          <div className={styles.groupDetailTab}>
            <h1>Group Name : </h1>
            <div className={styles.detailInput}>
            <input placeholder={group.name} onChange={(e)=>setNewName(e.target.value)}  />
            <img src={edit_icon}/>
            </div>
          </div>
          <div className={styles.groupDetailTab}>
            <h1>Class: </h1>
            <div className={styles.groupBtn}>
              {/* UNCOMMENT WHEN GRADE/CLASS IS EDITABLE IN APIS */}
              {/* {Array.from({length: 5}, (_,index)=> (
                <button key={index} className={`${group.class === index+6 ? styles.active : ''}`} onClick={() => (setGroup({...group,class: index + 6,}))}>{index +6}</button>
              ))} */}
            <button className={styles.active}>{group.class}</button>
            </div>
          </div>
          <div className={styles.groupDetailTab}>
            <h1>Reading Ability :</h1>
            <div className={styles.groupBtn}>
              {/* UNCOMMENT WHEN LEVEL IS EDITABLE IN APIS */}
              {/* {["Easy", "Medium", "Hard"].map((level, index) => (
                <button key={index} className={`${group.readAbility === level ? styles.active : ''}`} onClick={() => (setGroup({...group,level: level}))}>{level}</button>
              ))} */}
            <button className={styles.active}>{group.readAbility} : {group.level}</button>
            </div>
          </div>
          <div className={styles.groupDetailTab}>
            <h1>School Name :</h1>
            <div className={styles.detailInput}> 
              <input placeholder={group.school} onChange={(e)=>setGroup({...group,school:e.target.value})} disabled/>
              {/* UNCOMMENT AND REMOVE DISABLED WHEN SCHOOL IS EDITABLE IN APIS */}
              {/* <img src={edit_icon}/> */}
              </div>
          </div>
          <div className={styles.groupDetailTab}>
            <h1>Subject :</h1>
            <div className={styles.detailInput} >
              <input placeholder={group.subject} disabled/>
              {/* UNCOMMENT AND REMOVE DISABLED WHEN SUBJECT IS EDITABLE IN APIS */}
              {/* <img src={edit_icon}/> */}
            </div>
          </div>
        </div>
        <div className={styles.groupPopupFooter}>
          <button className={styles.groupPopupClose} onClick={props.closePopup}>&times;</button>
          <button className={styles.teacherBtn} onClick={() => handleGroupCRUD("DELETE")}>Delete Group</button>
          <button className={styles.teacherBtn} onClick={() => handleGroupCRUD("RENAME")}>Confirm</button>
        </div>
    </>) :""}         
    </motion.div>
    );
}
