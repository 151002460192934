import $ from "jquery";
import { React, useState } from "react";
import styles from "./HomeHeading.module.css"
import yozu_logo from "./Assets/LogoWhiteBg.png"
import { Offcanvas, OffcanvasBody } from "react-bootstrap";

// Interactive cloud doodle background
export default function HomeStarter() {

    return (<>
        <div className={styles.fadedContainer}>
            <div className={`${styles.heading}`}>
                <div className={styles.logo}>
                <img src={yozu_logo}/>
                </div>
                <span>
                <div id={styles.Yo}>
                    <h2>Yo / </h2>
                    Informal way of greeting someone or express excitement  
                </div>
                <div id={styles.Zu}>
                    <h2>Zu / </h2>
                    In Kashmiri language, 'Zu' phonetically means <b>'life'</b>.
                </div>
                </span>
            </div>
            
            <p className={styles.bodyText}>
            Technology (AI) startup empowering educational organizations of India.
            </p>
        </div>
    </>
    );
}
