import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";

// Firebase project configuration object (see Firebase Console)
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_YOZU_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_YOZU_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_YOZU_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_YOZU_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_YOZU_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_YOZU_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_YOZU_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_YOZU_MEASUREMENT_ID,
};

// Initialize Firebase
initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth();

export { auth, provider };
