import React, { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useGlobal } from "../../context/GlobalContext";
import language from "../../Locale/language-data.json";
import { useTeacherContext } from "./TeacherContext";

import styles from "./Teacher.module.css";
import { AnimatePresence,motion } from "framer-motion";
import LoadAnim from "./LoadAnim"

import back from "../Assets/backbutton.png";


export default function Assessments(){
    //Teacher Context
    const {questions,setQuestions,LPID,groups} = useTeacherContext();
    
    //Global Variables
    const { CSRFToken , UID , WebAppLocale, setWebAppLocale } = useGlobal();
    const [ setCurrentLang] = useState(language[WebAppLocale]);

    //Set State Variables
    const [showPopup, setShowPopup] = useState(false);
    const [questGroups, setQuestGroups] = useState([]);
    const [groupSelector,setGroupSelector] = useState(false);
    const [activeOptn, setActiveOptn] = useState("type");

    const [testData, setTestData] = useState({
        type: null,
        format: null,
        level: null,
        qNo: null,
      });

    const [loader,setLoader]=useState(null);


    const testTypes = [
        "Remembering",
        "Understanding",
        "Applying",
        "Analysing",
        "Evaluating",
        "Creating",
      ];
      const testFormats = [
        "Short Answer",
        "Essay",
        //"MCQ", //Add when MCQs are being recieved from the server
        "True or False",
      ];
    const testLevels = ["Easy", "Medium", "Difficult"];
    const testQNos = [3, 4, 5, 6, 7];

    const updateTestData = (key,value) =>{
        setTestData((prevTestData)=>({
          ...prevTestData,
          [key]:value,
        }));
      };
    
    const handleLanguageChange = (eventKey) => {
        setWebAppLocale(eventKey);
        setCurrentLang(language[eventKey]);
      };
      
    const handleBackClick = () => {
        if(activeOptn === "format"){
          updateTestData('format', null);
          updateTestData('type', null);
    
          setActiveOptn("type");
        }
        else if(activeOptn === "level"){
          updateTestData('level',null)
          updateTestData('format', null);
          setActiveOptn("format");
        }
        else if(activeOptn === "qnos"){
          updateTestData('qNo',null)
          updateTestData('level',null)
          setActiveOptn("level");
        }
        updateTestData('qNo',null)
      };
    
    const handleConfirmClick = async() => {
        if(activeOptn === "type"){
          if(!testData.type){
            alert("Select type first");
            return
          }
          updateTestData('format', null);
          updateTestData('level',null)
          updateTestData('qNo',null)
          setActiveOptn("format");
        }
        else if(activeOptn === "format"){
          if(!testData.format){
            alert("Select format first");
            return
          }
          setActiveOptn("level");
        }
        else if(activeOptn === "level"){
          if(!testData.level){
            alert("Select level first");
            return
          }
          setActiveOptn("qnos");
        }
        else if(activeOptn === "qnos"){
          if(!testData.qNo){
            alert("Select Number of Questions first");
            return
          }
          setActiveOptn("type");
          setShowPopup(false);
          await fetchQuestions();
        }
      };
    
    const handleQAClose = () =>{
        setQuestions([]);
        setTestData({
          type: null,
          format: null,
          level: null,
          qNo: null,
        });
      }
    
    const fetchQuestions = async() => {
        setLoader(1);
        const data = {
          "assessment_type": [testData.type],
          "assessment_level": [testData.level],
          "assessment_format": [testData.format],
          "number_of_questions": [testData.qNo],
          "lesson_plan_id": LPID 
      }
      const form_data = new FormData();
      for(var key in data){
        form_data.append(key,data[key]);
      } 
      const url = `${process.env.REACT_APP_BACKEND_SERVER}/assessment/get_questions`; 
      try{
        const response = await fetch(url,{
          body:form_data,
          method:"POST",
          headers: {
            Authorization: `Bearer ${UID}`,
            "X-CSRFToken":`${CSRFToken}`,
          },
          credentials:"include"
        });
        const responseJson = await response.json();
        setQuestions(responseJson.assessment.assessment);
        setLoader(null);
      }
      catch(e){
        alert("Error while getting assessment questions:",e);
        setLoader(null);
      }
    };

    const toggleGroupSelection = (groupId) => {
        if (questGroups.includes(groupId)) {
          // Group is already selected, so remove it
          setQuestGroups(questGroups.filter((group) => group !== groupId));
        } else {
          // Group is not selected, so add it
          setQuestGroups([...questGroups, groupId]);
        }
      };
      
      const sendQuestions = async() => {
        // console.log(questGroups);
        if(questGroups.length >0){
        const data={
          group_ids:questGroups,
          questions:questions.map(function(item){return item.question})
        }
      
        const form_data = new FormData();
        for (var key in data){
          form_data.append(key,data[key])
        }
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/student_group/send_questions`;
        try{
          const response=await fetch(url,{
            method:"POST",
            body:form_data,
            credentials:"include",
          })
          console.log(response);
          if (response.ok){
            alert("Questions sent Successfully!")
            setQuestGroups([]);
            setGroupSelector(false);
          }
        }
        catch(e){
          console.log("Error while sending questions",e);
        }
      }
      };
      
    return(<>
          {/* ASSESSMENT SECTION called QA */}
            {LPID !== null && !showPopup && questions.length > 0 &&(<div className={styles.QAContainer}>
              <div className={styles.QAHeader}>
                <div className={styles.QAHeadElmnt}>
                 Type: {testData.type}
                </div>
                <div className={styles.QAHeadElmnt}>
                  Format: {testData.format}
                </div>
                <div className={styles.QAHeadElmnt}>
                  Level: {testData.level}
                </div>
              </div>
              <div className={styles.QABlock} >
                {questions.map((QA,QAIndex) => (
                  <div className={styles.QAWrapper} key={QAIndex}>
                    <div className={styles.question}>Q{QAIndex+1}.{QA.question}</div>
                    <div className={styles.answer}>
                   {/* Add style for showing MCQ Options */}
                    {testData.format === "MCQ" && (
                      <div className={styles.options}>
                      {QA.options.map((option,optionIndex)=> (<>
                      <div key={optionIndex} className={styles.MCQOpt}>
                       {optionIndex+1}: {option}
                      </div>
                      </>))}
                      </div>
                    )}
                      <b>Ans:</b> {QA.answer}
                      </div>
                  </div> 
                ))}
              </div>
              <div className={styles.QAFooter}>
                        <button
                          className={styles.LPClose}
                          onClick={() =>handleQAClose()}
                        >
                          &times;
                        </button>
                        <button className={styles.expandBtn} onClick={() => setGroupSelector(true)}>Send</button> {/* Write Logic for sending Questions to Students*/}
                      </div>
                      {groupSelector &&(
                        <>
                          <div className={styles.groupSelector}>
                            <div className={styles.groupSelectorTitle}>Select Groups</div>
                            <div className={styles.groupSelectorBody}>
                              {groups.map((group,groupIndex) => (<>
                                <div className={styles.groupColumn}>
                                <div className={styles.groupSelectorGroups}>{group.group_details.group_name}</div>
                                <label className={`${styles.groupSelection} ${questGroups.includes(group.group_details.group_id) ? styles.active : ""}`} key={groupIndex} onClick={() => toggleGroupSelection(group.group_details.group_id)}>
                                  &#x2713;
                                </label>
                                </div>
                                </>))}
                            </div>
                            <div className={styles.groupSelectorFooter}>
                            <button className={styles.groupPopupClose} onClick={() =>setGroupSelector(false)}>&times;</button>
                            <button className={styles.confirmBtn} onClick={() => sendQuestions()} style={{visibility:questGroups.length <= 0 ? "hidden": ""}}>Confirm</button>
                            </div>
                          </div>
                        </>
                      )}
              </div>
          )}

          {/* ASSESSMENT POPUP */}
          <AnimatePresence>
          {showPopup && (
            
            <motion.div initial={{ opacity: 0, }}
            animate={{ opacity: 1, }}
            exit={{ opacity: 0, }}
            key="AssessmentPopup" 
            className={styles.sidePopupContainer}>
              <div className={styles.sidePopupTitle}>Choose Questions</div>
              {LPID !== null ? (<>
              {/* <div className={styles.gradeContainer}> */}
                {activeOptn === "type" && (<>
                  <div
                  className={`${styles.optTitle} ${
                    testData.type !== null && styles.active
                  }`}
                >
                  {testData.type === null ? "Select Type" : "Selected Type"}
                </div>
                {/* {MsgLoading && <Spinner />} */}
                <div className={styles.sideGrid}>
                  {testTypes.map((type, typeindex) => (
                    <button
                      key={typeindex}
                      className={`${styles.sideGridBtn} ${
                        type === testData.type ? styles.active : ""
                      }`}
                      onClick={() => updateTestData('type', type)}
                    >
                      {type}
                    </button>
                  ))}
                </div>
                </>)}
              {/* </div> */}
               
                {activeOptn === "format" && (<>
                  {testData.type !== null && (
                    <>
                <div className={styles.showSelection}>
                    <button
                      className={styles.sideBackBtn}
                      onClick={handleBackClick}
                    >
                      <img alt="Back BTN"src={back} />
                    </button>
                    <div  className={styles.sideOptTitleShow}>
                      Selected Test Type
                    </div>
                    <div  className={styles.sideOptTitleShow}>
                      {testData.type}
                    </div>
                  </div>
                  <hr/>
                  </>
                  )}
                  <div
                  className={`${styles.optTitle} ${
                    testData.type !== null && styles.active
                  }`}
                >
                  {testData.format === null ? "Select Format" : "Selected Format"}
                </div>
                <div className={styles.sideGrid}>
                  {testFormats.map((format,formatIndex) => (
                    <button
                    key={formatIndex}
                    className={`${styles.sideGridBtn} ${
                      format === testData.format ? styles.active : ""
                    }`}
                    onClick={() => updateTestData('format', format)}
                    >
                      {format}
                    </button>
                  ))}
                </div>
                </>
                )}
                {activeOptn === "level" && (<>
                  {testData.type !== null && (<>
                <div className={styles.showSelection}>
                    {/* <button
                      className={styles.sideBackBtn}
                      onClick={handleBackClick}
                    >
                      <img src={back} />
                    </button> */}
                    <div  className={styles.sideOptTitleShow}>
                      Selected Test Type
                    </div>
                    <div  className={styles.sideOptTitleShow}>
                      {testData.type}
                    </div>
                  </div>
                  <hr/>
                  </>
                  )}
                   {testData.format !== null && (<>
                <div className={styles.showSelection}>
                    <button
                      className={styles.sideBackBtn}
                      onClick={handleBackClick}
                    >
                      <img alt="Back BTN" src={back} />
                    </button>
                    <div  className={styles.sideOptTitleShow}>
                      Selected Test Format
                    </div>
                    <div  className={styles.sideOptTitleShow}>
                      {testData.format}
                    </div>
                  </div>
                  <hr/>
                  </>
                  )}
                  <div
                  className={`${styles.optTitle} ${
                    testData.format !== null && styles.active
                  }`}
                >
                  {testData.level === null ? "Select Level" : "Selected Level"}
                </div>
                <div className={styles.sideGrid}>
                  {testLevels.map((level,levelIndex) => (
                    <button
                    key={levelIndex}
                    className={`${styles.sideGridBtn} ${
                      level === testData.level ? styles.active : ""
                    }`}
                    onClick={() => updateTestData('level',level)}
                    >
                      {level}
                    </button>
                  ))}
                </div>
                </>
                )}
                {activeOptn === "qnos" && (<>
                  {testData.type !== null && (<>
                <div className={styles.showSelection}>
                    {/* <button
                      className={styles.sideBackBtn}
                      onClick={handleBackClick}
                    >
                      <img src={back} />
                    </button> */}
                    <div  className={styles.sideOptTitleShow}>
                      Selected Test Type
                    </div>
                    <div  className={styles.sideOptTitleShow}>
                      {testData.type}
                    </div>
                  </div>
                  <hr/>
                  </>
                  )}
                   {testData.format !== null && (<>
                <div className={styles.showSelection}>
                    {/* <button
                      className={styles.sideBackBtn}
                      onClick={handleBackClick}
                    >
                      <img src={back} />
                    </button> */}
                    <div  className={styles.sideOptTitleShow}>
                      Selected Test Format
                    </div>
                    <div  className={styles.sideOptTitleShow}>
                      {testData.format}
                    </div>
                  </div>
                  <hr/>
                  </>
                  )}
                  {testData.level !== null && (<>
                <div className={styles.showSelection}>
                    <button
                      className={styles.sideBackBtn}
                      onClick={handleBackClick}
                    >
                      <img alt="Back BTN" src={back} />
                    </button>
                    <div  className={styles.sideOptTitleShow}>
                      Selected Test Level
                    </div>
                    <div  className={styles.sideOptTitleShow}>
                      {testData.level}
                    </div>
                  </div>
                  <hr/>
                  </>
                  )}
                  <div
                  className={`${styles.optTitle} ${
                    testData.level!== null && styles.active
                  }`}
                >
                  {testData.level === null ? "Select Number of Questions" : "Number of Questions"}
                </div>
                <div className={styles.sideGrid}>
                  {testQNos.map((num,numindex) => (
                    <button
                    key={numindex}
                    className={`${styles.sideGridBtn} ${
                      num === testData.qNo ? styles.active : ""
                    }`}
                    onClick={() => updateTestData('qNo',num)}
                    >
                      {num}
                    </button>
                  ))}
                </div>
                </>
                )}
              
              {testData.type !== null && (
                <button
                  className={styles.confirmBtn}
                  onClick={() => {
                    handleConfirmClick();
                  }}
                >
                  Confirm
                </button>
              )}
              <div/>
              </>
              ):(
            <div className={styles.teacherPH}>Generate Lesson Plan First</div>
              )}
            </motion.div>
          )}
          </AnimatePresence>
          
          {/* LOADING ANIMATION */}
          {loader === 1 && (<>
          <LoadAnim/>
          </>)}

          {/* ASSESSMENT PLACEHOLDER */}
          {!loader && !showPopup && questions.length <=0 && (<div className={styles.teacherPH}>Assessment</div>)}

          {/* POPUP BUTTON */}
          {questions.length <=0 && (
            <div className={styles.teacherBtnWrapper} style={showPopup ? { border: "1px solid #fff" } : {}}>
            <button className={`${styles.teacherBtn} ${showPopup ? styles.activeBtn : ""}`} onClick={()=> setShowPopup(!showPopup)}>
              Assessment
            </button>
          </div>
          )}
    </>);
}