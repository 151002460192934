import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { React, useEffect, useState } from "react";
import { useGlobal } from "../../context/GlobalContext";
import { motion } from "framer-motion";
import close_icon from "../Assets/Chatbot/close_FILL0_wght400_GRAD200_opsz48.svg";
import dislike_icon from "../Assets/Chatbot/thumb_down_FILL0_wght400_GRAD200_opsz48.svg";
import dislike_icon_active from "../Assets/Chatbot/thumb_down_FILL1_wght400_GRAD200_opsz48.svg";
import like_icon from "../Assets/Chatbot/thumb_up_FILL0_wght400_GRAD200_opsz48.svg";
import like_icon_active from "../Assets/Chatbot/thumb_up_FILL1_wght400_GRAD200_opsz48.svg";

export default function ImageCarouselModal(props) {
    // Global context
    const { imageLikeDislikes, setImageLikeDislikes, UID, CSRFToken } = useGlobal();

    // State variables
    const [imageArray, setImageArray] = useState(props.media);
    const [currImageIndex, setCurrImageIndex] = useState(imageArray.findIndex((image) => image.image_id === props.currImageId || image.video_id === props.currImageId));
    const [currImageId, setcurrImageId] = useState(props.currImageId);
    const [is_liked, setIs_liked] = useState();
    const [is_disliked, setIs_disliked] = useState();
    const [likeDislikeLoading, setLikeDislikeLoading] = useState(false);

    // Post like or dislike of image
    const postLikeDislike = async (incdata) => {
        const data = {
            image_id: incdata.image_id,
            state_type: incdata.state_type,
            conversation_id: props.convId,
        };
        let form_data = new FormData();
        for (var key in data) {
            form_data.append(key, data[key]);
        }
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/chat/image/like_dislike`;
        try {
            const response = await fetch(url, {
                mode: "cors",
                method: "POST",
                body: form_data,
                headers: { Authorization: `Bearer ${UID}`, "X-CSRFToken": `${CSRFToken}` },
                credentials: "include",
                SameSite: "None",
            });

            const responseJson = await response.json();
            // console.log(responseJson);
            return responseJson;
        } catch (error) {
            // console.error(error);
            alert("Couldn't like or dislike the image! Please try again in some time! 🙂");
        }
    };

    // Handle the liking of image
    const likeImage = () => {
        setLikeDislikeLoading(true);
        // if (is_disliked) {
        //     dislikeImage();
        // }
        let data = imageLikeDislikes;
        let convId = props.convId + "conv";
        setIs_liked(data[convId][currImageId]["is_liked"]||false);
        setIs_disliked(data[convId][currImageId]["is_disliked"]||false);
        // console.log("convId : ", convId);
        // console.log("imgId : ", currImageId);
        // console.log("imageLikeDislikes ", imageLikeDislikes);

        const responseJSON = postLikeDislike({ image_id: currImageId, state_type: "like" });
        responseJSON.then((responseJSON) => {
            // console.log("responseJSON ", responseJSON);
            if (responseJSON.message === "Liked Sucessfully") {
                data[convId][currImageId]["is_liked"] = true;
                setIs_liked(true);
            } else if (responseJSON.message === "Like removed Sucessfully") {
                data[convId][currImageId]["is_liked"] = false;
                setIs_liked(false);
            }

            // data[currImageId].is_disliked = false;
            setImageLikeDislikes(data);
            // console.log("imageLikeDislikes ", imageLikeDislikes);
            setLikeDislikeLoading(false);
        });
    };

    // Handle the disliking of image
    const dislikeImage = () => {
        setLikeDislikeLoading(true);
        // if (is_liked) {
        //     likeImage();
        // }
        const data = imageLikeDislikes;
        const convId = props.convId + "conv";
        setIs_liked(data[convId][currImageId]["is_liked"]||false);
        setIs_disliked(data[convId][currImageId]["is_disliked"]||false);
        // console.log("convId : ", convId);
        // console.log("imgId : ", currImageId);
        // console.log("imageLikeDislikes ", imageLikeDislikes);

        const responseJSON = postLikeDislike({ image_id: currImageId, state_type: "dislike" });
        responseJSON.then((responseJSON) => {
            // console.log(responseJSON);
            if (responseJSON?.message === "Disliked Sucessfully") {
                data[convId][currImageId]["is_disliked"] = true;
                setIs_disliked(true);
            } else if (responseJSON?.message === "Dislike removed Sucessfully") {
                data[convId][currImageId]["is_disliked"] = false;
                setIs_disliked(false);
            }

            // data[currImageId].is_liked = false;
            setImageLikeDislikes(data);
            // console.log("imageLikeDislikes ", imageLikeDislikes);
            setLikeDislikeLoading(false);
        });
    };

    // Handle next carousel button
    const handleImgNext = () => {
        let currimgindex = null;
        if (currImageIndex === imageArray.length - 1) {
            currimgindex = 0;
        } else {
            currimgindex = currImageIndex + 1;
        }

        const currimgid = imageArray[currimgindex].image_id || imageArray[currimgindex].video_id;
        const data = imageLikeDislikes;
        const convId = props.convId + "conv";
        // console.log(data[convId],currimgid);
        // console.log(data[convId][currimgid]["is_disliked"]);
        setIs_liked(data[convId][currimgid]["is_liked"]||false);
        setIs_disliked(data[convId][currimgid]["is_disliked"]||false);

        setCurrImageIndex(currimgindex);
        setcurrImageId(currimgid);

        // console.log("currimgid carousel ", currimgid);
    };

    // Handle previous carousel button
    const handleImgPrev = () => {
        let currimgindex = null;
        if (currImageIndex === 0) {
            currimgindex = imageArray.length - 1;
        } else {
            currimgindex = currImageIndex - 1;
        }

        const currimgid = imageArray[currimgindex].image_id || imageArray[currimgindex].video_id;
        const data = imageLikeDislikes;
        const convId = props.convId + "conv";
        console.log(data);
        setIs_liked(data[convId][currimgid]["is_liked"]||false);
        setIs_disliked(data[convId][currimgid]["is_disliked"]||false);

        setCurrImageIndex(currimgindex);
        setcurrImageId(currimgid);

        // console.log("currimgid carousel ", currimgid);
    };

    // Close modal when clicked outside of modal
    const handleOutsideClick = (e) => {
        // if (!e.target.closest(".message-carousel-next")) {
        //     props.handleModalClose();
        // }
    };

    // Pre manipulation of the data
    useEffect(() => {
        let data = imageLikeDislikes;
        let convId = props.convId + "conv";
        if (!props.media[0].video_id) {
            setIs_liked(data[convId][props.currImageId]["is_liked"]||false);
            setIs_disliked(data[convId][props.currImageId]["is_disliked"]||false);
        }
        setcurrImageId(props.currImageId);
        // console.log("usereffect currImageId ", props.currImageId);
    }, []);

    // Return null if no image id or related image data is passed
    if (!props.media || !props.currImageId || !imageLikeDislikes) {
        return null;
    } else {
        return (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.1 }} className="image-carousel-modal" onMouseDown={handleOutsideClick}>
                <div className="message-carousel-indicator">
                    <div
                        className="message-carousel-prev"
                        onClick={() => {
                            handleImgPrev();
                        }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <div
                        className="message-carousel-next"
                        onClick={() => {
                            handleImgNext();
                        }}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                    {currImageIndex + 1 + " / " + imageArray.length}
                </div>
                <motion.div initial={{ opacity: 0, zoom: 0.97 }} animate={{ opacity: 1, zoom: 1 }} exit={{ opacity: 0, zoom: 0.97 }} transition={{ duration: 0.1 }} className="image-carousel-modal-content">
                    {/* Change component based on the image or video or youtube video */}
                    {imageArray[currImageIndex].image_url && imageArray[currImageIndex].image_url.includes(".webm") ? (
                        <video className="image-carousel-modal-content-image" controls>
                            <source src={imageArray[currImageIndex].image_url} type="video/webm"></source>
                        </video>
                    ) : (
                        <div className="image-carousel-modal-content-image" style={{ backgroundImage: `url(${imageArray[currImageIndex].image_url})` }} alt="image-from-yozu"></div>
                    )}
                    {imageArray[currImageIndex].video_id ? <iframe className="image-carousel-modal-content-image" src={`https://www.youtube.com/embed/${imageArray[currImageIndex].video_id.split('.be/')[1]}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> : ""}
                    <div className="image-carousel-modal-content-close-button image-carousel-modal-buttons" onClick={props.handleModalClose}>
                        <img src={close_icon} alt="close" />
                    </div>

                    {!imageArray[currImageIndex].video_id ? (
                        <>
                            <div className={`image-carousel-modal-content-like-button image-carousel-modal-buttons ${likeDislikeLoading ? "disabledLikeDislike" : ""}`}>
                                <img src={is_liked ? like_icon_active : like_icon} alt="like" onClick={!likeDislikeLoading ? likeImage : () => {}} />
                            </div>
                            <div className={`image-carousel-modal-content-dislike-button image-carousel-modal-buttons ${likeDislikeLoading ? "disabledLikeDislike" : ""}`}>
                                <img src={is_disliked ? dislike_icon_active : dislike_icon} alt="dislike" onClick={!likeDislikeLoading ? dislikeImage : () => {}} />
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </motion.div>
            </motion.div>
        );
    }
}
