import React,{ useState,useEffect } from "react";
import { useGlobal } from "../../context/GlobalContext";
import { useTeacherContext } from "./TeacherContext";

import styles from "./Teacher.module.css";

import LoadAnim from "./LoadAnim";

export default function SavedPlans(){
    //Global Context states
    const { UID, CSRFToken } = useGlobal();

    //Variable Controls which saved LP is in expanded view(currently only text no images)
    const {setExpLP} = useTeacherContext();

    //State Variables
    const [savedPlans,setSavedPlans] = useState([]);
    const [plansLoading,setPlansLoading]=useState(false); //Loading Animation variable

    //Header variable structure
    const headers=["Grade","Chapter","Topic"];

    //FETCH SAVED PLANS
    const savedLPs = async () => {
      setPlansLoading(true);
        setSavedPlans([]);
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/lesson_plan/getallplans`;
        try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
            Authorization: `Bearer ${UID}`,
            "X-CSRFToken": `${CSRFToken}`,
            credentials: "include",
            },
        });
        if (response.status) {
            const responseJSON = await response.json();
            setSavedPlans(responseJSON.lessons_created);
            // console.log(responseJSON.lessons_created); 
            setPlansLoading(false);
            }
        } catch (e) {
        console.log("Error while loading user lesson plans", e);
        setPlansLoading(false);
        }
    };

    //Adjust Date and time for each LP
    const getDateTime = (timeString) =>{
        const isoDateString = timeString.slice(0,19);
        const date = new Date(isoDateString);
    
        const options = {weekday:"long"};
        const dayName = new Intl.DateTimeFormat('en-US',options).format(date);
    
        const formattedDate = new Intl.DateTimeFormat('en-US',{
          month:'long',
          day:'numeric'
        }).format(date)
        return {
          day:dayName,
          date:formattedDate};
    };
    
    //Fetch saved plans when component is rendered
    useEffect(()=>{
        savedLPs();
    },[]);

return(<>
          {plansLoading ? (<>
          {/* LOADING ANIMATION */}
          <LoadAnim/>
          </>):(<>
          {savedPlans.length <=0 ? (
              <div className={styles.teacherPH}>
                Generate Lesson Plans!
              </div>
            ):(<>
            {/* SAVED PLAN SECTION(reffered to as SP) */}
            <div className={styles.SPContainer}>
              {savedPlans.map((plan,planIndex)=>(<>
                <div className={styles.SPDisplay} key={planIndex}>
                  <div className={styles.SPTimeInfo}>
                    <h1>{getDateTime(plan.when_created).day}</h1>
                    <h2>{getDateTime(plan.when_created).date}</h2>
                  </div>
                  <div className={styles.SPInfo} onClick={()=> setExpLP({data:savedPlans[planIndex].original})}>
                    {headers.map((headItem,headIndex)=>(
                    <React.Fragment key={headIndex}>
                      {headItem}: {savedPlans[planIndex].original[headItem]}<br/>
                    </React.Fragment>))}
                  </div>
                </div>
              </>))}
            </div>
            </>)}
          </>)}
          
</>);
}