import React, { useState, useEffect } from 'react';
import universe from "../Assets/Leaderboard/universe.png";
import stars from "../Assets/Leaderboard/stars.png";
import world from "../Assets/Leaderboard/world.png";
import planets from "../Assets/Leaderboard/planets.png";
import earth from "../Assets/Leaderboard/earth.png";
import design from "../Assets/Leaderboard/frame.png";
import star from "../Assets/Leaderboard/star.png";
import trophy from "../Assets/Leaderboard/trophy.png";
import crystal from "../Assets/Leaderboard/crystal.png";

// import * as Images from "../Assets/Leaderboard";

import { useGlobal } from '../../context/GlobalContext';
import { useChatbotContext } from "./ChatbotContext";
import "./Chatbot-v2.css";

export default function Leaderboard(props) {
  
  const {
    UID,
    CSRFToken,
  } = useGlobal();
  const user = {};
  const { CurrentLang, setLeaderboardStats ,leaderboardStats,ChatMode} = useChatbotContext();
  const [selectedOption, setSelectedOption] = useState("Universe");
  const [sectionImages, setSectionImages] = useState({
    Universe: universe, 
    Stars: stars, 
    Planets: planets,
    World: world,
    Earth: earth,
  });

  const [fetchedUser, setFetchedUser] = useState({}); // State for user data
  const [ranks, setRanks] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading state
  const [user_data, setUser_data] = useState({});
  
  useEffect(() => {
    fetchUserInfo();
  }, [UID]);

  const fetchUserInfo = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_BACKEND_SERVER}/refresh_ranks`, {
      headers: {
        Authorization: `Bearer ${UID}`,
      },
      credentials: "include",
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        if (data.status) {
          setRanks(data.Ranks);
          setUser_data(data.user_data[0]);
          setLeaderboardStats(data.user_data[0]);
        } else {
          console.error('Error:', data.Reason);
        }
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error
      });
  };
  
  const [isToggleOn, setIsToggleOn] = useState(false);

  const filterRanksByClass = (classOfStudy) => {
    return ranks.filter((rank) => rank.class_of_study === classOfStudy);
  };

  const handleClick = () => {
    setIsToggleOn((prevIsToggleOn) => !prevIsToggleOn);
  };
  return (
    <div id="leaderboard_content">
      <div id="leaderboard_title">
        {/* {CurrentLang.QRank} */}
        <div className='row1'>
          Quriosity Rank: {leaderboardStats?.rank} 
          <span className='vert-line'> </span>
          Name:{" "+leaderboardStats?.username}
        </div>
        
        <div className="title-user-data">
            {ChatMode == "Ask" && (<>
            <span className="stat">
                <img src={star} alt="star stat"/>
                {leaderboardStats?.stars} 
            </span>
            <span className="stat">
                <img src={crystal} alt="crystal stat"/>
                {leaderboardStats?.crystals} 
            </span>
            <span className="stat">
                <img src={trophy} alt="trophy stat"/>
                {leaderboardStats?.trophies}
            </span>
            
            {leaderboardStats?.points}
            </>)} 
        </div>
      </div>
      <div id="leaderboard_main">
        <div className="background-vec">
          <img src={design} alt="" />
          <div id="section-container">
          {Object.keys(sectionImages).map((option) => (
            <div
              key={option}
              className={`section ${selectedOption === option ? "selected" : ""}`}
              onClick={() => setSelectedOption(option)}
            >
              {CurrentLang[option]}
            </div>
          ))}
        </div>
          <div className="selected-image">
          {sectionImages[selectedOption] && (
            <img src={sectionImages[selectedOption]} alt="Leaderboard "  className="image"/>
          )}
        </div>
        </div>

        <div id="ToggleSwitchSection">
          <div id="bystd">{CurrentLang.SortStd}‏‏‎ ‎</div>
          <div onClick={handleClick} className={isToggleOn ? "ToggleSwitch active" : "ToggleSwitch"}>
            <div className={isToggleOn ? 'knob active' : 'knob'} />
            
          </div>
        </div>

        <div className="results">
          {ranks && ranks.length > 0 ? (
          isToggleOn
              ? filterRanksByClass(user_data.class_of_study).map((rank) => (
                  <div className={`user-info ${rank.user_id === user_data.user_id ? `current` : ''}`} key={rank.user_id}>
                    <span className="user-data" id="user-id">{rank?.rank}</span>
                    <span className="user-data" id="name" style={{ marginRight: 30 }}>
                      {rank?.username}
                    </span>
                    
                    <span className="user-data">
                      <img src={star} alt="Stars" id="icon" />
                      {rank?.stars}</span>
                    
                    <span className="user-data">
                      <img src={crystal} alt="Crystal" id="icon" />
                      {rank?.crystals}
                      </span>
                    
                    <span className="user-data">
                      <img src={trophy} alt="Trophies" id="icon" />
                      {rank?.trophies}
                      </span>
                    <div
                      id="overall-score"
                      style={{
                        float: rank.points > 10000 ? "left" : "right",
                      }}
                    >
                      {rank.points}
                    </div>
                  </div>
                ))
                : ranks.map((rank) => (
                  <div className={`user-info ${rank.user_id === user_data.user_id ? `current` : ''}`} key={rank?.user_id}>
                    <span id="user-id" className="user-data">{rank?.rank} </span>
                    <span id="name" className="user-data">
                      {rank?.username}
                    </span>
                    
                    <img src={star} className="user-data" alt="Stars" id="icon" />
                    <span className="user-data">{rank?.stars}</span>
                    <img src={crystal} className="user-data" alt="Crystal" id="icon" />
                    <span className="user-data">{rank?.crystals}</span>
                    <img src={trophy} className="user-data" alt="Trophies" id="icon" />
                    <span className="user-data">{rank?.trophies}</span>
                    <div
                      id="overall-score"
                      className="user-data"
                      style={{
                        float: rank.points > 10000 ? "left" : "right",
                      }}
                    >
                      {rank.points}
                    </div>
                  </div>
                ))
          
          ) : (
            <div>No rank available for user.</div>
          )}
        </div>

        {/* <div className="user-info-bottom">
            <span className="user-id-bottom">{user_data?.rank}</span>
            <span className="name-bottom" style={{ marginRight: 30 }}>
              {user_data?.username}
            </span>
            <img src={star} alt="" className="icon" />
            <span className="stars-bottom">{user_data?.stars}</span>
            <img src={crystal} alt="" className="icon" />
                
            <span className="icon_medal-bottom">{user_data?.crystals}</span>
            <img src={trophy} alt="" className="icon" />
                
            <span className="icon_trophy-bottom">{user_data?.trophies}</span>
            <div
              className="overall-score-bottom"
              style={{
                float: user_data?.points > 10000 ? "left" : "right",
              }}
            >
              {user_data?.points}
            </div>
          </div> */}
      </div>
    </div>
  );
}
