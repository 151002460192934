import Profile from "./Profile"
import FeedbackForm from "./FeedbackForm"

import { useChatbotContext } from "./ChatbotContext";
import { useGlobal } from "../../context/GlobalContext";
import { useState } from "react";
import { Link } from "react-router-dom";

import logo from "./..//Assets//LogoWhiteBg.png"

export default function ChatbotLeftFrame()
{
    const {showSettings,setShowSettings,fontSlider,setFontSlider}=useGlobal();
    const {CurrentLang} = useChatbotContext();
    const [isProfileOpen,setIsProfileOpen] = useState(false);
    const [isFeedbackOpen,setIsFeedbackOpen] = useState(true);
    const [activeFrame,setActiveFrame] = useState("Feedback");

    const toggleProfile = () => {
      // Handle the functionality when "Profile" button is clicked
        setActiveFrame("Profile");
        setIsProfileOpen(true);
        setIsFeedbackOpen(false);
        // console.log(activeFrame);

    };
    const toggleFeedback = () => {
      // Handle the functionality when "Feedback" button is clicked
      setActiveFrame("Feedback");
      setIsProfileOpen(false);
      setIsFeedbackOpen(true);
      // console.log(activeFrame);
    };
    
return(
<div id="left-section">
  <Link to="/"><img src={logo} alt="" id="logo-img"/></Link>
  <div id="pf-content">
      <div id="profile_feedback_btns">
              <button type="click" id="feedback" onClick={toggleFeedback} className={`profile-feedback ${activeFrame === "Feedback" ? "active" : ""}`}>
                  {CurrentLang.Feedback}
              </button>
              <button type="click" id="profile" onClick={toggleProfile} className={`profile-feedback ${activeFrame === "Profile" ? "active" : ""}`}>
                  {CurrentLang.Profile}
              </button>
              <button type="click" id="profile" onClick={()=>setShowSettings(!showSettings)}className={`profile-feedback ${showSettings ? "active" : ""}`}>
                  Font-Size
              </button>
              {showSettings && (<>
              <div id="font-slider" onMouseLeave={()=>setShowSettings(false)}>
                <input type="range" min="1" max="3" value={fontSlider} onChange={(e)=>setFontSlider(Number(e.target.value))}
                id="slide-input"/>
                {["Small","Medium","Large"][fontSlider-1]}
              </div>
              </>)}
      </div>
      <div id="pf-main">
        {activeFrame === "Profile" ? (
        <Profile isProfileOpen={isProfileOpen}/>
        ): activeFrame === "Feedback" ? (
        <FeedbackForm isModalOpen={isFeedbackOpen} setIsModalOpen={setIsFeedbackOpen}/>
        ):("")}
      </div>
      </div>
  </div>
)
}