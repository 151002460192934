import React,{ useState } from "react";
import send_icon from "../Assets/Chatbot/send-icon.png";
import { useChatbotContext } from "./ChatbotContext";
import Chatbotdotbutt from "./Chatbotdotbutt";
import VoiceBot from "./VoiceBot/VoiceBot";

export default function Input(props) {
  // Chatbot context
  const { CurrentLang, input, setInput, isFocused,setIsFocused } = useChatbotContext();

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };


  return (
    <>
      <div id="input-section">
        <Chatbotdotbutt />
        <form id="user-input" onSubmit={props.handleUserInput} autoComplete="off" noValidate onFocus={()=>setTimeout(()=>{setIsFocused(true);},150)} >
          <input type="text" name="userMessage" placeholder={CurrentLang.userMessagePlaceholder} disabled={!props.inputAllowed} onBlur={()=>setTimeout(()=>{setIsFocused(false);},150)} value={input} onChange={handleInputChange} required autoComplete="off" />
          {isFocused
          ? 
          <button type="submit" id="snd-btn">
            <img src={send_icon} alt="send" id="send-icon" />
          </button>
          :
          null
          }
        </form>
        {!isFocused && <VoiceBot />}
      </div>
    </>
  );
}
